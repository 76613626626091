<template>
  <div
    v-on-clickaway="closeSmallScreenNav"
    class="small-screen-primary-navigation"
  >
    <SmallScreenNavigationHead
      v-if="actualDepth === 0"
      navigation-title="SmartHome"
      :actual-depth="actualDepth"
      :show-back-button="false"
      :show-active-state="smallScreenNavActive"
      @smallScreenNavToggled="toggleSmallScreenNav"
      @closeSmallScreenNav="closeSmallScreenNav"
    />

    <div
      v-show="smallScreenNavActive"
      class="mainnav__small-screen"
    >
      <ul
        class="mainnav__rootlevel mainnav__rootlevel--is-visible"
        :class="`mainnav__depth-level--${actualDepth}`"
      >
        <SmallScreenNavigationItem
          label="Smart Home"
          to="/"
          :item-index="0"
          :class="checkHiddenClass(0)"
          exact
          @closeSmallScreenNav="closeSmallScreenNav"
        />

        <SmallScreenNavigationItem
          label="Shop"
          to="/shop"
          :expandable="true"
          :depth="1"
          :actual-depth="actualDepth"
          :item-index="2"
          :class="checkHiddenClass(2)"
          exact
          @openSubNavigation="onOpenSubNavigation"
          @closeSmallScreenNav="closeSmallScreenNav"
          @itemIndexChanged="onItemIndexChanged"
          @collapseParent="collapse"
        >
          <ShopNavbar is-mobile />
        </SmallScreenNavigationItem>


        <SmallScreenNavigationItem
          label="MagentaZuhause App"
          to="/magentazuhause-app"
          :item-index="0"
          :class="checkHiddenClass(0)"
          exact
        />

        <!-- Hilfe -->
        <SmallScreenNavigationItem
          label="Hilfe"
          to="/hilfe"
          :item-index="0"
          :class="checkHiddenClass(0)"
          exact
        />
      </ul>
    </div>
  </div>
</template>

<script>
import { mixin as Clickaway } from 'vue-clickaway2';
import arrowLeftMenu from '@/assets/images/arrow-left-menu.svg';
import menu from '@/assets/images/menu.svg';
import menuClose from '@/assets/images/menu-close.svg';
import SmallScreenNavigationItem from '@/components/small-screen-navigation-item';
import SmallScreenNavigationHead from '@/components/small-screen-navigation-head';
import { mapMutations } from 'vuex';
import ShopNavbar from '@/components/shop-navbar';

export default {
  expose: ['openSubNav', 'toggleSmallScreenNav'],
  name: 'SmallScreenPrimaryNavigation',
  components: {
    SmallScreenNavigationItem,
    SmallScreenNavigationHead,
    ShopNavbar,
  },
  mixins: [Clickaway],
  data() {
    return {
      arrowLeftMenu,
      menu,
      menuClose,
      smallScreenNavActive: false,
      actualDepth: 0,
      actualItemIndex: null,
      hasLastExpanded: false,
    };
  },
  watch: {
    actualDepth(value) {
      this.hasLastExpanded = (value === 1);
    },
    smallScreenNavActive(visible) {
      if (visible) {
        this.showBackdrop();
      } else {
        this.hideBackdrop();
      }
    },
  },
  methods: {
    ...mapMutations({
      showBackdrop: 'backdrop/SHOW',
      hideBackdrop: 'backdrop/HIDE',
    }),
    closeSmallScreenNav() {
      this.smallScreenNavActive = false;
      this.resetLevelInformation();
    },
    toggleSmallScreenNav() {
      // Close Nexus Search
      document.querySelector('.nexus-pk-coin__clear-button')?.click();

      this.smallScreenNavActive = !this.smallScreenNavActive;
      this.resetLevelInformation();
    },
    resetLevelInformation() {
      this.actualDepth = 0;
      this.actualItemIndex = null;
    },
    onOpenSubNavigation() {
      this.actualDepth += 1;
    },
    onItemIndexChanged(id) {
      if (id) this.actualItemIndex = id;
    },
    collapse() {
      this.decrementDepth();
      this.actualItemIndex = null;
    },
    decrementDepth() {
      this.actualDepth -= 1;
    },
    checkHiddenClass(itemIndex) {
      if (this.actualItemIndex && this.actualItemIndex !== itemIndex) {
        return 'nav__item--is-hidden';
      }
      return null;
    },
    openSubNav(subCategory) {
      const navItem = this.$children.find((nav) => nav.label === subCategory);
      if (navItem) {
        navItem.onClickLabel();
      }
    },
  },
};

</script>

// From https://gitlab.i22.de/projekte/td-smarthome/component-library/-/blob/master/src/elements/get-app.vue#L420

// Use Client-Side only
const isIOS = () => Array.isArray(navigator.platform.match(/(iphone|ipad|ipod)/gi)) || (Array.isArray(navigator.platform.match(/MacIntel/gi)) && navigator.maxTouchPoints > 0);
const isDesktopOS = () =>
  Array.isArray(
    navigator.platform.match(/(Windows|Win32|WinCE|Macintosh|MacPPC|Mac68k)/gi),
  ) || (Array.isArray(navigator.platform.match(/MacIntel/gi)) && navigator.maxTouchPoints < 1);

export default {
  isIOS,
  isDesktopOS,
};

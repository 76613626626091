import { isUndefined } from 'lodash';

const BREAKPOINTS = {
  mobile: { min: 0, max: 639 },
  tablet: { min: 640, max: 1023 },
  desktop: { min: 1024, max: 1463 },
  largeDesktop: { min: 1464 },
};

export const state = () => ({
  scrollY: 0,
  width: 0,
  height: 0,
  docWidth: 0,
});

export const mutations = {
  SET_SCROLL(state, scrollY) {
    state.scrollY = scrollY;
  },
  SET_SIZE(state, { width, height, docWidth }) {
    state.width = width;
    state.height = height;
    state.docWidth = docWidth;
  },
};

export const actions = {
  setScroll(context, values) {
    context.commit('SET_SCROLL', values);
  },
  setSize(context, values) {
    context.commit('SET_SIZE', values);
  },
};

export const getters = {
  size({ width, height }) {
    return { width, height };
  },
  screenSize(state) {
    const sizes = Object.keys(BREAKPOINTS);
    return (
      sizes.find((size) => {
        const { min, max } = BREAKPOINTS[size];
        return state.width >= min && (isUndefined(max) || state.width <= max);
      }) || sizes[0]
    );
  },
  isMobileScreen(state, getters) {
    return getters.screenSize === 'mobile';
  },
  isTabletScreen(state, getters) {
    return getters.screenSize === 'tablet';
  },
  isDesktopScreen(state, getters) {
    return getters.screenSize === 'desktop';
  },
  isLargeDesktopScreen(state, getters) {
    return getters.screenSize === 'largeDesktop';
  },
  isAtLeastTabletScreen(state, getters) {
    return (
      getters.isTabletScreen ||
      getters.isDesktopScreen ||
      getters.isLargeDesktopScreen
    );
  },
  isAtLeastDesktopScreen(state, getters) {
    return getters.isDesktopScreen || getters.isLargeDesktopScreen;
  },
  hasVerticalScrollbar(state) {
    return state.width > state.docWidth;
  },
};

<template>
  <div class="footer-payment">
    <Paragraph class="footer-payment__subtitle">
      Express-Payment
    </Paragraph>
    <ul class="footer-payment-list">
      <li v-for="item in expressLogos" :key="item.name" class="footer-payment-list__list-item">
        <svg-icon
          :image="item.logo"
          :class="['footer-payment-list__icon', `footer-payment-list__icon--${item.name}`]"
        />
      </li>
    </ul>
    <Paragraph class="footer-payment__subtitle">
      Weitere Zahlungsmöglichkeiten
    </Paragraph>
    <ul class="footer-payment-list">
      <li v-for="item in otherLogos" :key="item.name" class="footer-payment-list__list-item">
        <svg-icon
          :image="item.logo"
          :class="['footer-payment-list__icon', `footer-payment-list__icon--${item.name}`]"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import mastercard from '@/assets/images/payment-logos/mastercard-footer.svg';
import paypalExpress from '@/assets/images/payment-logos/paypal-white.svg';
import paypal from '@/assets/images/payment-logos/paypal-white-vertical.svg';
import visa from '@/assets/images/payment-logos/visa-white.svg';
import applePay from '@/assets/images/payment-logos/apple-pay.svg';
import googlePay from '@/assets/images/payment-logos/google-pay-white.svg';
import giropay from '@/assets/images/payment-logos/giropay-footer.svg';
import klarna from '@/assets/images/payment-logos/klarna-marke.svg';
import ratenkauf from '@/assets/images/payment-logos/ratenkauf-white.svg';
import { Paragraph } from '@i22-td-smarthome/component-library';

export default {
  name: 'FooterPayment',
  components: { Paragraph },
  data() {
    return {
      expressLogos: [
        {
          name: 'paypal-express',
          logo: paypalExpress,
        },
        {
          name: 'apple-pay',
          logo: applePay,
        },
        {
          name: 'google-pay',
          logo: googlePay,
        },
      ],
      otherLogos: [
        {
          name: 'mastercard',
          logo: mastercard,
        },
        {
          name: 'visa',
          logo: visa,
        },
        {
          name: 'giropay',
          logo: giropay,
        },
        {
          name: 'paypal',
          logo: paypal,
        },
        {
          name: 'klarna',
          logo: klarna,
        },
        {
          name: 'ratenkauf',
          logo: ratenkauf,
        },
      ],
    };
  },
};

</script>

<style lang="scss">
  @import '~@/assets/base';

  .footer-payment {
    &-list {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 1.5 * $grid-base;
      margin-top: 2.5 * $grid-base;
      flex-wrap: wrap;
      &__list-item {
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-right: $grid-base;
        padding-bottom: $grid-base*2;
        width: 50%;
        @include mq(490px) {
          padding-bottom: $grid-base;
          width: auto;
          padding-right: $grid-base*3;
          &:last-child {
            padding-right: 0;
          }
        }

        @include mq($mq-large) {
          padding-bottom: $grid-base*2;
        }
      }
      &__icon {
        color: color(grey, 18500);
        fill: color(grey, 18500);
        width: auto;
        height: 30px;

        &--paypal-express {
          height: 24px;
        }
        &--amazon-pay {
          margin-top: 0.5 * $grid-base;
        }
        &--amazon-pay,
        &--visa,
        &--ratenkauf {
          height: 22px;
        }
        &--giropay {
          height: 43px;
        }
        &--paypal {
          height: 40px;
        }
        &--klarna {
          height: 15px;
        }
      }
    }

    &__subtitle {
      @include mq(false, $mq-md - 1) {
        @include font-size(14px, 18px);
      }
    }
  }
</style>

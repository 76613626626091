<template>
  <span class="legal-modal-button">
    <button
      ref="button"
      class="legal-modal-button__button btn footnote"
      :class="{ 'footnote-dark': theme === 'dark' }"
      @click.stop.prevent="$refs.modal.open('legal-modal-button')"
    >
      *
    </button>

    <Modal ref="modal" :size="modalSize" @close="$refs.button.focus()">
      <div v-if="hasTexts">
        <div v-for="(text, index) in texts" :key="index" class="legal-modal-button__text">
          <div v-html="text"></div>
        </div>
      </div>
      <slot name="modal" />
    </Modal>
  </span>
</template>

<script>
import Modal from '@/components/modal';

export default {
  name: 'LegalModalButton',
  components: {
    Modal,
  },
  props: {
    texts: {
      type: Array,
      default: () => [],
    },
    modalSize: {
      type: String,
      default: null,
    },
    theme: {
      type: String,
      default: 'light',
      required: false,
    },
  },
  computed: {
    hasTexts() {
      return this.texts && this.texts.length > 0;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../assets/base';

.legal-modal-button {
  display: inline-block;
  border: 1px $color-border solid;
  width: $grid-base;
  min-width: $grid-base;
  height: $grid-base;
  margin: 0 3px;
  position: relative;
  text-align: center;
  vertical-align: text-top;
  box-sizing: content-box;

  &__button {
    color: inherit;
    position: absolute;
    font-size: 22px;
    height: $grid-base;
    width: $grid-base;
    left: 0;
    top: 0;
    line-height: 22px;
    border: none;
    border-radius: unset;
    background: transparent;
  }

  &__button.footnote-dark {
    &:hover, &:focus {
      color: $color-font;
    }
  }
}
</style>

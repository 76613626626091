import axios from '@/lib/axios-client/content';
import { currentTardisCoordinates } from '@/lib/tardis';

// Deprecated!
async function contentJsonData(path, context = {}) {
  const tardisCoordinates = currentTardisCoordinates(context.req);
  const url = `api/content/data/${path}`;
  const params = {};
  if (tardisCoordinates) params.date = tardisCoordinates;
  const options = params.date ? { params } : undefined;
  const response = await axios.get(url, options);
  return response.data;
}

async function hookContent(hookName, selector) {
  return contentJsonData(`hooks/${hookName}/${selector}`);
}

async function itemAddedToCartContent(item) {
  if (!item.articleNumber) return undefined;
  return hookContent('item-added-to-cart', item.articleNumber);
}

function asyncPageContentFrom(path) {
  return async function asyncPageContentFromPath(context) {
    const pageContent = await contentJsonData(path, context);
    return { pageContent };
  };
}

export {
  hookContent,
  itemAddedToCartContent,
  contentJsonData,
  asyncPageContentFrom,
};

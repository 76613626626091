// When the context is a Browser, use the client sentry, in node use the node version.
// the configuration is used from `nuxt.config.js`

const getSentry = () => {
  if (process.sentry) {
    return process.sentry;
  }
  if (process.client) {
    return window.$nuxt.$sentry;
  }
  // this should not happen.
  return {
    captureMessage: (e) => { console.error(e); },
    captureException: (e) => { console.error(e); },
  };
};

/**
 * @typedef ScopeContext
 * @type {object}
 * @property {object} user
 * @property {'fatal' | 'error' | 'warning' | 'log' | 'info' | 'debug'} level
 * @property {Record<string, unknown>} extra
 * @property {Record<string, Record<string, unknown>>} contexts
 * @property {Record<string, Primitive>} tags
 * @property {string[]} fingerprint
 * @property {object} requestSession
 */
/**
 * @typedef {import("@sentry/types").Scope} Scope
 * @typedef { Scope | Partial<ScopeContext> | ((scope: Scope) => Scope) } CaptureContext
 */

/**
 * @typedef Sentry
 * @type {object}
 * @property {function(Error | any, CaptureContext)} captureException
 * @property {function(string | any, CaptureContext)} captureMessage
 */


/**
 * @param {Error | any} error
 * @param {CaptureContext=} captureContext
 */
const captureException = (error, captureContext) => {
  /** @property {Sentry} sentry */
  getSentry().captureException(error, captureContext);
};

/**
 * @param {string} error
 * @param {CaptureContext=} captureContext
 */
const captureMessage = (error, captureContext) => {
  /** @property {Sentry} sentry */
  getSentry().captureMessage(error, captureContext);
};

module.exports = {
  captureException,
  captureMessage,
  getSentry,
};


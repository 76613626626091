import ShopApi from '@/lib/shop-api';

// const ONE_HOUR_IN_MILLISECONDS = 60 * 60 * 1000;
const TEN_MINUTES_IN_MILLISECONDS = 10 * 60 * 1000; // updateInterval

function downtimeNow(downtime) {
  const convertedStartDate = new Date(downtime.startDate);
  const convertedEndDate = new Date(downtime.endDate);
  const timestamp = new Date(downtime.startDate).getTime() / 1000;
  const isDate = timestamp
    && timestamp > 0
    && !Number.isNaN(downtime.startDate);
  if (!isDate) return undefined;

  const dateNow = new Date(Date.now());

  return convertedStartDate < dateNow
        && convertedEndDate > dateNow;
}

export default {
  namespaced: true,
  state() {
    return {
      currentDate: null,
      downtimes: [],
      lastUpdated: null,
    };
  },
  actions: {
    setCurrentDate(context) {
      context.commit('SET_CURRENT_DATE', new Date());
    },
    async fetchDowntimes({ commit, getters }) {
      if (!getters.downtimesIntervalTimeUp) return;

      try {
        const { data } = await ShopApi.downtimes();
        commit('SET_DOWNTIMES', data?.downtimes || []);
        commit('SET_LAST_UPDATED', Date.now());
      } catch (error) {
        console.error(error);
      }
    },
  },
  getters: {
    downtimes(state) {
      return state.downtimes;
    },
    downtimesIntervalTimeUp(state) {
      return (state.lastUpdated + TEN_MINUTES_IN_MILLISECONDS) < Date.now();
    },
    tvppDowntime(state) {
      return state.downtimes.find(
        (dt) => dt.systems.find((s) => s === 'tvpp')
        && downtimeNow(dt),
      );
    },
    qiviconDowntime(state) {
      return state.downtimes.find(
        (dt) => dt.systems.find((s) => s === 'qivicon')
        && downtimeNow(dt),
      );
    },
    maintenanceDowntime(state) {
      if (state.currentDate) {
        // use "currentDate" to make this getter reactive, cause downtimes won't change.
      }
      return state.downtimes.find(
        (dt) => dt.systems.find((s) => s === 'maintenance')
        && downtimeNow(dt),
      );
    },
  },
  mutations: {
    SET_CURRENT_DATE(state, date) {
      state.currentDate = date;
    },
    SET_DOWNTIMES(state, downtimes) {
      state.downtimes = downtimes;
    },
    SET_LAST_UPDATED(state, timestamp) {
      state.lastUpdated = timestamp;
    },
  },
};

import { mapGetters, mapState } from 'vuex';

/**
 * Do NOT use the getter direct.
 * It will cause "missmatching childNodes vs. VNodes" error.
 * Y: Nuxt has no `window`, so it uses always the default "mobile"-states.
 *    The CSR has to start on the mobile size as well, to avoid rendering issues.
 * Use the data-properties instead - they'll be used for the SSR and be updated on `mounted` with watchers.
 */
export default {
  computed: {
    ...mapGetters({
      screenSizeGetter: 'viewport/screenSize',
      isMobileScreenGetter: 'viewport/isMobileScreen',
      isTabletScreenGetter: 'viewport/isTabletScreen',
      isDesktopScreenGetter: 'viewport/isDesktopScreen',
      isLargeDesktopScreenGetter: 'viewport/isLargeDesktopScreen',
      isAtLeastTabletScreenGetter: 'viewport/isAtLeastTabletScreen',
      isAtLeastDesktopScreenGetter: 'viewport/isAtLeastDesktopScreen',
    }),
    ...mapState({
      viewportWidth: (state) => state.viewport.width,
      viewportScrollY: (state) => state.viewport.scrollY,
    }),
  },
  data() {
    return {
      screenSize: 'mobile',
      isMobileScreen: true,
      isTabletScreen: false,
      isDesktopScreen: false,
      isLargeDesktopScreen: false,
      isAtLeastTabletScreen: false,
      isAtLeastDesktopScreen: false,
    };
  },
  mounted() {
    this.createWatchers();
  },
  methods: {
    createWatchers() {
      [
        'screenSize',
        'isMobileScreen',
        'isTabletScreen',
        'isDesktopScreen',
        'isLargeDesktopScreen',
        'isAtLeastTabletScreen',
        'isAtLeastDesktopScreen',
      ].forEach((name) => {
        try {
          if (typeof (this[`${name}Getter`]) === 'undefined') return;
          this.$watch(`${name}Getter`, (value) => { this[name] = value; }, { immediate: true });
        } catch (e) {
          // when the store is not mocked, to not throw an error!
        }
      });
    },
  },
};

<template>
  <div
    :class="textImageClass"
    class="text-image"
  >
    <CdnImageElement
      v-if="textImageData.srcSmall"
      class="text-image__image"
      :style="imageStyle"
      :class="imageHeight"
      :src-md="textImageData.srcLarge"
      :src-sm="textImageData.srcMedium"
      :src="textImageData.srcSmall"
      :alt="textImageData.altText"
      :image-class="'text-image__img'"
    />
    <div
      v-if="hasPlainContent || hasStructuredContent || hasSlotContent"
      :class="textClass"
      class="text-image__content"
    >
      <div
        v-if="hasPlainContent"
        v-html="textImageData.content"
      />
      <template
        v-if="hasStructuredContent"
      >
        <StructuredContent
          v-for="item in textImageData.structuredContent"
          :key="item.id"
          :items="item"
        />
      </template>
      <slot />
    </div>
  </div>
</template>

<script>
// Please remove, or rename and refactor this component!

import CdnImageElement from '@/components/cdn-image-element';
import StructuredContent from '@/components/structured-content';

export default {
  name: 'TextImage',
  components: {
    CdnImageElement,
    StructuredContent,
  },

  props: {
    textImageData: {
      type: Object,
      default: null,
    },
    noImage: {
      type: Boolean,
      default: false,
    },
    article: {
      type: Boolean,
      default: false,
    },
    hasMargin: {
      type: Boolean,
      default: true,
    },
    fixHeight: {
      type: Boolean,
      default: false,
    },
    mobileAlign: {
      type: String,
      default: '',
    },
    theme: {
      type: String,
      default: null,
    },
  },
  computed: {
    imageHeight() {
      return {
        'text-image__image-fix-height': this.fixHeight,
      };
    },
    hasStructuredContent() {
      return !!this.textImageData.structuredContent;
    },
    hasPlainContent() {
      return !!this.textImageData.content;
    },
    hasSlotContent() {
      return !!this.$slots.default || !!this.$slots.content;
    },
    contentViaSlot() {
      return !this.hasPlainContent && !this.hasStructuredContent;
    },
    textImageClass() {
      const modifier = [];
      if (this.textImageData.theme) modifier.push(`text-image--theme-${this.textImageData.theme}`);
      if (this.textImageData.textSize) modifier.push(`text-image--text-${this.textImageData.textSize}`);
      if (this.textImageData.imgPosition) modifier.push(`text-image--img-${this.textImageData.imgPosition}`);
      if (this.textImageData.align) modifier.push(`text-image--align-${this.textImageData.align}`);
      if (this.textImageData.invertTheme) modifier.push('theme-inverted');
      if (this.noImage) modifier.push('text-image--no-image');
      if (this.article) modifier.push('text-image--article');
      if (!this.hasMargin) modifier.push('text-image--no-margin');
      return modifier;
    },
    imageStyle() {
      const style = {};
      if (this.textImageData.imageMarginTop) { style['margin-top'] = this.textImageData.imageMarginTop; }
      return style;
    },
    textClass() {
      const modifier = [];
      if (this.mobileAlign.length > 0) {
        modifier.push(` text-image__content-m-${this.mobileAlign}`);
      }
      if (this.theme === 'light') {
        modifier.push('text-image__content--light');
      }
      return modifier;
    },
  },
};

</script>

<style lang="scss">
@import '../assets/base';

.text-image {
  display: flex;
  flex-direction: column;
  margin: 1.5 * $grid-base auto 2 * $grid-base;
  @include mq($mq-large) {
    margin-bottom: 3 * $grid-base;
  }

  //If article place image beneath text in mobile
  &--article {
    flex-direction: column-reverse;
    .text-image__image {
      margin-bottom: 0;
    }

    .text--h2 {
      margin-bottom: 36px;
    }
  }

  // Text Image style
  &--img-left,
  &--img-right {
    .text-image__content {
      @include mq($mq-large) {
        width: 48.5%;
      }
    }

    .text-image__image {
      @include mq($mq-large) {
        width: 51.5%;
      }
    }
  }

  &--img-left {
    .text-image__content {
      @include mq($mq-large) {
        padding-left: 5 * $grid-base;
      }
      @include mq($mq-extra-large) {
        padding-left: 10 * $grid-base;
      }
    }

    .text-image__image {
      @include mq($mq-large) {
        padding-right: 1.5 * $grid-base;
        padding-bottom: 0;
      }
      text-align: center;
      @include mq($mq-large) {
        text-align: left;
      }
    }
    //general Alignment
    @include mq($mq-large) {
      flex-direction: row;
    }
  }

  &--img-right {
    .text-image__content {
      @include mq($mq-large) {
        padding-right: 5 * $grid-base;
      }
      @include mq($mq-extra-large) {
        padding-right: 10 * $grid-base;
      }
    }

    .text-image__image {
      @include mq($mq-large) {
        padding-left: 1.5 * $grid-base;
        padding-bottom: 0;
      }
      text-align: center;
      @include mq($mq-large) {
        text-align: right;
      }
    }
    //general Alignment
    @include mq($mq-large) {
      flex-direction: row-reverse;
    }
  }

  // Image bottom
  &--img-bottom {
    flex-direction: column-reverse;
  }

  // Image Top
  &--img-top {
    .text-image__content {
      @include mq($mq-medium) {
        padding-top: 2 * $grid-base;
      }
    }
  }

  // Text align content
  &--align-center {
    text-align: center;
  }

  &--align-right {
    .text-image__content {
      text-align: right;
    }
  }
  &--align-left {
    .text-image__content {
      text-align: left;
    }
    @include mq(false, $mq-medium - 1) {
      .text-image__content-m {
        &-center {
          text-align: center;
        }
      }
    }
  }

  // Theme color
  &--theme-light {
    color: $color-secondary;
  }
  &--theme-dark {
    color: $color-font;
    @include mq(false, $mq-medium - 1) {
      &.theme-inverted {
        color: $color-secondary;
      }
    }
  }

  // Text Size
  &--text-large {
    // RTE only for this component
    p {
      @include font-size(18px, 27px);
      @include mq($mq-medium) {
        @include font-size(21px, 27px);
      }
    }
  }

  // Text Content - title, copy, link, icon
  &__title {
    &--italic {
      font-style: italic;
    }
    &--tour {
      margin-bottom: 3 * $grid-base;
    }
  }
  &__content {
    width: 100%;
    @include mq($mq-large) {
      padding-top: 2 * $grid-base;
    }

    &--light {
      color: color(white);
    }
  }

  &__image {
    width: 100%;
    flex-shrink: 0; //IE Fix
    margin-bottom: 1.5 * $grid-base;
    @include mq($mq-large) {
      margin-bottom: 0;
    }
    &-fix-height {
      min-height: 105px;
    }
  }

  &__icon-content {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  &--no-image {
    .text-image__content {
      padding: 0;
    }
  }

  &--no-margin {
    margin: 0;
    @include mq($mq-large) {
      margin: 0;
    }
  }
  &.text-image {
    &--no-margin {
      &--bottom {
        margin-bottom: 0; // necessary override for tab image
      }
    }
  }
}
</style>

<template>
  <div>
    <ShippingCostsInfo :text-variant="textVariant" :preffered-day="prefferedDay">
      <slot />
      <LegalModalButton :theme="currentTheme">
        <template #modal>
          <ShippingCostsInfo />
        </template>
      </LegalModalButton>
    </ShippingCostsInfo>
    <slot name="button" />
  </div>
</template>

<script>
import ShippingCostsInfo from '@/components/shipping-costs/shipping-costs-info';
import LegalModalButton from '@/components/legal-modal-button';

export default {
  name: 'ShippingCostsLegal',
  components: {
    LegalModalButton,
    ShippingCostsInfo,
  },
  props: {
    textVariant: {
      type: String,
      default: 'generic',
      require: false,
    },
    prefferedDay: {
      type: Boolean,
      default: false,
      require: false,
    },
  },
  computed: {
    currentTheme() {
      return this.textVariant === 'footer' ? 'dark' : 'light';
    },
  },
};
</script>

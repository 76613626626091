<template>
  <nav class="mainnav"><!-- Nexus Search Elements will spawn here at .mainnav -->
    <div class="mainnav__wrapper">
      <LargeScreenPrimaryNavigation
        ref="largeNavigation"
        class="mainnav__desktop"
      />
      <SmallScreenPrimaryNavigation
        ref="mobileNavigation"
        class="mainnav__mobile"
        @small-screen-nav-toggled="toggleSecondaryNav"
      />
      <SecondaryNavigation
        v-show="!smallScreenNavActive"
        @search="search"
      />
    </div>
  </nav>
</template>

<script>
import Inflections from '@/mixins/inflections';
import LargeScreenPrimaryNavigation from '@/components/large-screen-primary-navigation';
import SecondaryNavigation from '@/components/secondary-navigation';
import SmallScreenPrimaryNavigation from '@/components/small-screen-primary-navigation';
import TrackAction from '@/mixins/track-action';
import { urlifyTermAndSection } from '@/lib/search-helper';

export default {
  name: 'MainNavigation',
  components: {
    LargeScreenPrimaryNavigation,
    SecondaryNavigation,
    SmallScreenPrimaryNavigation,
  },
  mixins: [
    Inflections,
    TrackAction,
  ],
  data() {
    return {
      smallScreenNavActive: false,
    };
  },
  computed: {
    getDay() {
      const date = new Date();
      return date.getDate();
    },
  },
  mounted() {
    this.$nuxt.$on('open-menu', (subCategory) => {
      if (subCategory) {
        // if we call it instantly or use $nextTick it will run in the same tick as
        // click on button, which is handled as click outside menu and collapse it
        setTimeout(() => {
          if (this.$refs.mobileNavigation.$el.clientHeight) {
            this.$refs.mobileNavigation.toggleSmallScreenNav();
            this.$refs.mobileNavigation.openSubNav(subCategory);
          } else {
            this.$refs.largeNavigation.openSubNav(subCategory);
          }
        }, 0);
      }
    });
  },
  methods: {
    search(term) {
      if (!term) return;
      const route = urlifyTermAndSection(term, 'alle');
      this.$router.push(route);
    },
    toggleSecondaryNav() {
      this.smallScreenNavActive = !this.smallScreenNavActive;
    },
  },
};

</script>

<style lang="scss">
@import 'assets/base';

$mainnav-height: 56px;

.mainnav {
  backface-visibility: hidden;
  background-color: color(white);
  border-bottom: 1px solid color(grey, 25000);
  border-top: 4px solid color(magenta, 1000);
  user-select: none;

  .app--has-vertical-scrollbar.app--has-global-backdrop & {
    margin-right: $scrollbar-size;
  }

  &__wrapper {
    @include mq($mq-large) {
      display: flex;
      max-width: $grid-max + (2 * $grid-medium-gutter);
      margin: 0 auto;
      padding: {
        left: $grid-base;
        top: $grid-base;
        bottom: $grid-base;
        right: 0;
      }
    }

    @include mq($mq-extra-large) {
      padding: $grid-base;
    }

    &--is-hidden {
      top: calc(-1 * var(--branding-height));
      position: absolute;
    }
  }

  &__desktop {
    @include mq(false, $mq-md - 1) {
      display: none;
    }
  }

  &__mobile {
    @include mq($mq-md) {
      display: none;
    }
  }

  &__fadeout {
    background-color: rgba(color(grey, 12000), 0.75);
    position: fixed;
    width: 100%;
    min-height: 100%;
    z-index: -1;
    display: block;
  }

  &__button {
    border: 0;
    background: none;
    cursor: pointer;
    font-weight: 400;
    color: currentColor; // safari is using blue, when its not explicit set.

    &--is-hidden {
      display: none;
    }

    &--toggle {
      display: flex;
      @include font-size(18px, 18px);
      height: $mainnav-height;
      padding: 0;
      padding-left: $grid-base;
      align-items: center;

      @include mq($mq-large) {
        display: none;
      }
    }

    &--close {
      position: absolute;
      top: 14px;
      right: 12px;
    }

    &--back {
      margin-right: auto;
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__info {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid color(grey, 25000);
    height: 57px;

    @include mq($mq-large) {
      display: none;
    }

    &--menu {
      display: flex;
      flex-basis: 25%;
      align-items: center;
      background-color: white;
      z-index: 1;
      position: relative;
    }

    &--title {
      display: flex;
      flex-basis: 50%;
      align-items: center;
      justify-content: center;
      background-color: white;
      z-index: 1;
      position: relative;
    }

    &--close {
      display: flex;
      flex-basis: 25%;
      align-items: center;
      background-color: white;
      z-index: 1;
      position: relative;
    }

    &.mainnav--goback {
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  &__rootlevel {
    list-style: none;
    display: none;
    margin: 0;
    position: relative;

    &--is-visible {
      display: block;
    }

    @include mq($mq-large) {
      position: static;
      display: inline-flex;
    }
  }

  &__rootelement {
    @include font-size(21px, 24px);
    font-weight: bold;
    margin: 0;

    &--is-hidden {
      @include mq(false, $mq-large - 1) {
        display: none;
      }
    }

    @include mq($mq-large) {
      margin-right: $grid-base;
    }
  }

  &__link {
    border-bottom: 1px solid color(grey, 31000);
    color: $color-font;
    display: flex;
    justify-content: space-between;
    text-decoration: none;
    padding: $grid-medium-gutter;
    align-items: center;
    cursor: pointer;

    &:active,
    &:visited,
    &:hover,
    &:focus {
      color: $color-font;
    }

    @include mq($mq-large) {
      border-bottom: 0;
      border-radius: 5px;
      margin: 8px 0;
      padding: 4px $grid-base;
      justify-content: unset;

      &:hover,
      &:focus,
      &--is-expanded {
        background: $color-link-bg;
      }
    }

    &--is-active {
      color: $color-primary;

      &:active,
      &:visited,
      &:hover,
      &:focus {
        color: $color-primary;
      }
    }

    &--subnav-is-active {
      background: $color-link-bg;
    }
  }

  &__icon {
    &--open {
      width: 24px;
      height: 18px;
      margin-right: 12px;
    }

    &--link {
      margin-left: auto;
      height: 27px;
    }

    &--subnav {
      height: 10px;
      width: 10px;
      margin-top: 4px;
      margin-left: 10px;
    }

    &--back {
      height: 27px;
    }

    &--close {
      width: 19px;
      height: 25px;
    }

    &--external {
      width: 14px;
      height: 12px;
      margin-top: 2px;
      margin-left: 6px;
    }

    &--mobile {
      @include mq($mq-large) {
        display: none;
      }
    }

    &--desktop {
      @include mq(false, $mq-large - 1) {
        display: none;
      }
    }

    &--is-hidden {
      display: none;
    }
  }

  &__depth-level {
    &--0 {
      max-height: calc(100vh - var(--branding-height) - var(--navbar-height));
      overflow: auto;
      -webkit-overflow-scrolling: touch;
    }
  }
}

// Search Result
// .nexus-pk-coin {
//   // Search Field
//   @include mq(false, $mq-sm) {
//     &__search-wrapper {
//       padding-inline: $grid-base;
//     }
//   }

//   .vue-portal-target &__gray-out,
//   .vue-portal-target &__content-bg {
//     z-index: 3;
//   }
// }
</style>

import { plainClient as axios } from '@/lib/axios-client/goliath';

function getErrorMessage(response) {
  if (typeof response.data === 'string') {
    return response.data;
  }
  return response.data?.message || 'Could not load product';
}

function validateGoliathResponse(response) {
  if (response?.data?.data?.offerBySlugs) return response.data.data.offerBySlugs;

  const error = new Error(getErrorMessage(response));
  error.response = response;
  throw error;
}

async function fetchOfferBySlugs({ productSlug, contractSlug }) {
  const response = await axios.post('/api/goliath/offer', { productSlug, contractSlug });
  return validateGoliathResponse(response);
}

export { fetchOfferBySlugs }; // eslint-disable-line import/prefer-default-export

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"fadeLayer"}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.isActive),expression:"isActive"}],ref:"tooltipLayer",staticClass:"tooltip-layer",class:[
      {
        'tooltip-layer--dark': _vm.currentTheme === 'dark',
        'tooltip-layer--light': _vm.currentTheme === 'light',
        'tooltip-layer--bottom': _vm.position === 'bottom',
      },
      _vm.customStyling && `tooltip-layer--${_vm.customStyling}`,
    ],style:(_vm.layerStyle)},[(_vm.productImageSrc)?_c('CdnImageElement',{style:(_vm.imageStyle),attrs:{"alt":_vm.productHeading,"src":_vm.productImageSrc,"image-class":"tooltip-layer__product-image","render-without-figure":""}}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"tooltip-layer__text-container"},[(_vm.productHeading)?_c('Heading',{staticClass:"tooltip-layer__text tooltip-layer__text--heading",attrs:{"text":_vm.productHeading,"align":"center","tag":"h3"}}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"tooltip-layer__text"},[_vm._v("\n        "+_vm._s(_vm.tooltipText)+"\n        "),_vm._t("default")],2),_vm._v(" "),(_vm.showTouchLink)?_c('a',{staticClass:"tooltip-layer__text",attrs:{"data-tealium-rel":_vm.tealiumRel,"href":`/geraete/${_vm.productSlug}`,"tabindex":"0"}},[_vm._v("\n        mehr Details\n      ")]):_vm._e()],1),_vm._v(" "),_c('span',{staticClass:"tooltip-layer__arrow",style:(_vm.arrowStyle)})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="multiple-contracts-dialog">
    <div class="dialog-content__header">
      Ein anderer SmartHome Tarif ist bereits im Warenkorb enthalten. Was möchtest du tun?
    </div>

    <div class="dialog-content__buttons multiple-contracts-dialog__buttons">
      <button
        ref="continueLink"
        class="btn btn--transparent multiple-contracts-dialog__btn"
        @click.prevent="close"
      >
        Vorherige Auswahl beibehalten
      </button>
      <button
        class="btn btn--transparent multiple-contracts-dialog__btn"
        @click.prevent="changeContract"
      >
        Neue Auswahl übernehmen
      </button>
    </div>
  </div>
</template>

<script>
import DialogContent from '@/mixins/dialog-content';
import Dialog from '@/helpers/dialog';
import { mapGetters, mapActions } from 'vuex';
import Cookies from 'js-cookie';

export default {
  name: 'MultipleContractsInCartDialogContent',
  mixins: [
    DialogContent({
      newContract: null,
      redeemedCoupon: null,
    }),
  ],
  data() {
    return {};
  },
  computed: {
    ...mapGetters('cart', ['cartItems']),
    contractId() {
      const contract = this.cartItems.find((item) => item.contractNumber !== null);
      if (!contract) return null;
      return contract.id;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.continueLink?.focus();
    });
  },
  methods: {
    ...mapActions('cart', ['deleteCartItem', 'addCartItem', 'cartAddCouponAndItem', 'fetchCart']),
    async changeContract() {
      const salesOrganizationID = Cookies.get('TK_VO');
      try {
        await this.deleteCartItem({
          id: this.contractId,
        });
        // in case of activation, we have to send data to another url
        if (this.redeemedCoupon) {
          await this.cartAddCouponAndItem({
            contractNumber: this.newContract,
            redeemedCoupon: this.redeemedCoupon,
            salesOrganizationID,
          });
        } else {
          await this.addCartItem({
            contractNumber: this.newContract,
            quantity: 1,
            salesOrganizationID,
          });
        }
        const item = this.cartItems.find((i) => i.contractNumber === this.newContract);
        await Dialog.show('AddToCartDialogContent', {
          type: 'success',
          componentOptions: { item },
        });
      } catch (e) {
        await Dialog.info(
          'Ein kritischer Fehler ist aufgetreten. Bitte versuche es erneut.',
          { type: 'error' },
        );
      }
      // fetchCart so current page has fresh cartData
      await this.fetchCart();
    },
  },
};

</script>

<style lang="scss">
  @import '../assets/base';

  .multiple-contracts-dialog {
    &__buttons {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
    }

    &__btn {
      margin: 6px;
    }
  }

</style>

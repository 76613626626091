<template>
  <div
    class="structured-content"
  >
    <template
      v-for="(item, index) in items"
    >
      <template v-if="isItemComponent(item, '')">
        <span
          :key="`content-${index}`"
          class="content__inline-block"
          v-html="item"
        />
      </template>

      <Heading
        v-else-if="isItemComponent(item, 'Heading')"
        :key="`heading-${index}`"
        :align="item.align"
        :size="item.size"
        :spaces-bottom="item.spacesBottom"
        :tag="item.tag"
        :text="item.text"
      />

      <Paragraph
        v-else-if="isItemComponent(item, 'Paragraph')"
        :key="`paragraph-${index}`"
        :align="item.align"
        :text="item.text"
      />

      <BlockLink
        v-else-if="isItemComponent(item, 'BlockLink')"
        :key="`block__link-${index}`"
        class="content__inline-block"
        v-bind="item"
      />

      <TooltipLink
        v-else-if="isItemComponent(item, 'TooltipLink')"
        :key="`tooltip__link-${index}`"
        class="content__inline-block"
        theme="dark"
        :label="item.label"
        :article-number="item.articleNumber"
      />

      <div
        v-else-if="showErrors"
        :key="index"
      >
        Could not parse content data, {{ item }}
      </div>
    </template>
  </div>
</template>

<script>
import { Heading, Paragraph } from '@i22-td-smarthome/component-library';

export default {
  name: 'StructuredContent',
  components: {
    BlockLink: () => import('@/components/block-link'),
    TooltipLink: () => import('@/components/tooltip-link'),
    Heading,
    Paragraph,
  },
  props: {
    items: {
      type: [Object, Array],
      required: true,
    },
  },
  data() {
    return {
      showErrors: true, // should be false on production and staging, read mode
    };
  },
  methods: {
    isItemComponent(item, componentName = '') {
      if (!componentName) {
        return typeof item === 'string';
      }
      return typeof item === 'object' && item.type === componentName;
    },
  },
};
</script>
<style lang="scss">
.structured-content {
  display: block;
  margin-bottom: 24px;
}

.content__inline-block {
  display: inline;
  // Override and fix large gap
  &.tooltip {
    display: inline; // Firefox is not able to wrap properly
  }
}
</style>

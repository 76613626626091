<template>
  <header
    :class="containerClass"
    class="page-header no-print"
  >
    <Branding
      v-if="hasNav"
      ref="branding"
    />
    <MainNavigation v-if="hasNav" />
    <DialogContainer
      :with-nav="hasNav"
    />
  </header>
</template>

<script>
import Branding from '@/components/branding';
import DialogContainer from '@/components/dialog-container';
import MainNavigation from '@/components/main-navigation';

export default {
  name: 'PageHeader',
  components: {
    Branding,
    DialogContainer,
    MainNavigation,
  },
  props: {
    theme: {
      type: String,
      default: null,
    },
  },
  computed: {
    containerClass() {
      return {
        [`page-header--${this.theme}`]: !!this.theme,
      };
    },
    hasNav() {
      return !this.theme;
    },
  },
};

</script>

<style lang="scss">
  @import '../assets/base';

  .page-header {
    --branding-height: 56px;
    --navbar-height: 57px;
    @include mq($mq-md) {
      --branding-height: 80px;
      --navbar-height: 72px;
    }

    position: sticky;
    top: calc(-1 * var(--branding-height));
    z-index: 99;

    &--small {
      // Checkout header style small
      position: relative;
      top: 0;
      border-top: 4px solid $color-primary;
    }
  }
</style>

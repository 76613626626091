<template>
  <div v-if="hasBadge" class="product-tile-badge__wrapper">
    <Badge
      v-if="showNew"
      class="product-tile-badge product-tile-badge--new text--bold"
      disable-min-content
      style="--badge-xs-size: 1.4em; --badge-xs-padding: .4em; --badge-sm-size: 1.6em; --badge-sm-padding: .4em;"
    >
      NEU
    </Badge>
    <Badge
      v-if="showDiscount"
      class="product-tile-badge product-tile-badge--discount text--bold"
      aria-label="Rabattiert"
      disable-min-content
      style="--badge-xs-size: 2em; --badge-xs-padding: .5em; --badge-sm-size: 3em;"
    >
      %
    </Badge>
    <Badge
      v-if="showCustom"
      class="product-tile-badge"
      :class="customBadgeClass"
      disable-min-content
      style="--badge-sm-size: .8em;"
    >
      <slot>
        <span v-html="content" />
      </slot>

      <slot name="footnoteButton">
        <button
          v-if="footnoteContent"
          ref="footnoteButton"
          class="badge__footnote btn"
          type="button"
          @click.prevent.stop="$refs.modal.open()"
        >
          *
        </button>
      </slot>
    </Badge>
    <Modal ref="modal">
      <slot name="footnoteContent">
        <div v-html="footnoteContent" />
      </slot>
    </Modal>
  </div>
</template>

<script>
import { Badge, slugify } from '@i22-td-smarthome/component-library';
import Modal from '@/components/modal';

export default {
  name: 'ProductTileBadge',
  components: {
    Badge,
    Modal,
  },
  props: {
    isNew: { type: Boolean },
    isDiscounted: { type: Boolean },
    content: { type: String, default: '' },
    footnoteContent: { type: String, default: '' },
    customBadgeName: { type: String, default: '' },
  },
  computed: {
    showNew() {
      return this.isNew;
    },
    showDiscount() {
      return this.isDiscounted && !this.isNew && !this.showCustom;
    },
    showCustom() {
      return !!this.content;
    },
    hasBadge() {
      return this.showNew || this.showDiscount || this.showCustom;
    },
    customBadgeClass() {
      return this.customBadgeName ? `product-tile-badge--${slugify(this.customBadgeName)}` : '';
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'assets/base';

$badge-dimension: 64px;
$badge-dimension-lg: 84px;

.product-tile-badge {
  left: 0;
  right: auto;
  white-space: normal;
  width: $badge-dimension;
  height: $badge-dimension;
  @include mq($mq-sm) {
    width: $badge-dimension-lg;
    height: $badge-dimension-lg;
  }

  :deep(.badge__content) {
    padding: 0;
    width: $badge-dimension;
    @include mq($mq-sm) {
      width: $badge-dimension-lg;
    }
    .badge__footnote.btn {
      border-radius: 0px;
    }
  }

  &--garagen-aktion {
    width: 84px;
    height: 84px;
    font-size: 0.6em;

    @include mq($mq-md) {
      width: 108px;
      height: 108px;
      font-size: 0.8em;
    }

    :deep(.badge__content) {
      width: 84px;

      @include mq($mq-md) {
        width: 108px;
      }
    }
  }
}
</style>

import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _ce4d292a = () => interopDefault(import('../pages/aktionsangebote.vue' /* webpackChunkName: "pages/aktionsangebote" */))
const _77c3aa8b = () => interopDefault(import('../pages/aktivieren.vue' /* webpackChunkName: "pages/aktivieren" */))
const _d731bb3a = () => interopDefault(import('../pages/angebot-beendet.vue' /* webpackChunkName: "pages/angebot-beendet" */))
const _198e6057 = () => interopDefault(import('../pages/app-download.vue' /* webpackChunkName: "pages/app-download" */))
const _280cdf0b = () => interopDefault(import('../pages/applogin.vue' /* webpackChunkName: "pages/applogin" */))
const _479ac48c = () => interopDefault(import('../pages/hilfe/index.vue' /* webpackChunkName: "pages/hilfe/index" */))
const _7de5fdef = () => interopDefault(import('../pages/infomail-abmelden.vue' /* webpackChunkName: "pages/infomail-abmelden" */))
const _c67f48ea = () => interopDefault(import('../pages/infos-zum-online-kauf.vue' /* webpackChunkName: "pages/infos-zum-online-kauf" */))
const _afa90caa = () => interopDefault(import('../pages/magenta-smarthome-alexa-skill.vue' /* webpackChunkName: "pages/magenta-smarthome-alexa-skill" */))
const _08544926 = () => interopDefault(import('../pages/magenta-smarthome-app-und-funktionen/index.vue' /* webpackChunkName: "pages/magenta-smarthome-app-und-funktionen/index" */))
const _11931645 = () => interopDefault(import('../pages/magenta-smarthome-app-updates/index.vue' /* webpackChunkName: "pages/magenta-smarthome-app-updates/index" */))
const _33f54432 = () => interopDefault(import('../pages/magentazuhause-alexa-skill.vue' /* webpackChunkName: "pages/magentazuhause-alexa-skill" */))
const _0e44e901 = () => interopDefault(import('../pages/magentazuhause-app.vue' /* webpackChunkName: "pages/magentazuhause-app" */))
const _35820ac8 = () => interopDefault(import('../pages/newsletter.vue' /* webpackChunkName: "pages/newsletter" */))
const _696403db = () => interopDefault(import('../pages/newsletter-abmelden.vue' /* webpackChunkName: "pages/newsletter-abmelden" */))
const _15e7af75 = () => interopDefault(import('../pages/partner.vue' /* webpackChunkName: "pages/partner" */))
const _131bf078 = () => interopDefault(import('../pages/ratenzahlung.vue' /* webpackChunkName: "pages/ratenzahlung" */))
const _ca6f7d0c = () => interopDefault(import('../pages/schnell-starten.vue' /* webpackChunkName: "pages/schnell-starten" */))
const _44c1bfae = () => interopDefault(import('../pages/shop-start.vue' /* webpackChunkName: "pages/shop-start" */))
const _f13495d0 = () => interopDefault(import('../pages/so-geht-smart-home.vue' /* webpackChunkName: "pages/so-geht-smart-home" */))
const _0b1309b7 = () => interopDefault(import('../pages/sticky-banner-alexa-skill.vue' /* webpackChunkName: "pages/sticky-banner-alexa-skill" */))
const _a593ed36 = () => interopDefault(import('../pages/url-generator/index.vue' /* webpackChunkName: "pages/url-generator/index" */))
const _5de109c6 = () => interopDefault(import('../pages/geraete/async-data-functions.js' /* webpackChunkName: "pages/geraete/async-data-functions" */))
const _32865c04 = () => interopDefault(import('../pages/hilfe/faq.vue' /* webpackChunkName: "pages/hilfe/faq" */))
const _4866ef3e = () => interopDefault(import('../pages/hilfe/home-base-wechsel.vue' /* webpackChunkName: "pages/hilfe/home-base-wechsel" */))
const _4ba12c9a = () => interopDefault(import('../pages/hilfe/kompatible-geraete/index.vue' /* webpackChunkName: "pages/hilfe/kompatible-geraete/index" */))
const _401f0908 = () => interopDefault(import('../pages/hilfe/kontakt.vue' /* webpackChunkName: "pages/hilfe/kontakt" */))
const _1a880948 = () => interopDefault(import('../pages/hilfe/retoure-und-kuendigung.vue' /* webpackChunkName: "pages/hilfe/retoure-und-kuendigung" */))
const _ed5dca90 = () => interopDefault(import('../pages/hilfe/zentralen-und-funkstandards.vue' /* webpackChunkName: "pages/hilfe/zentralen-und-funkstandards" */))
const _e04a72ee = () => interopDefault(import('../pages/ideen/alle-ideen.vue' /* webpackChunkName: "pages/ideen/alle-ideen" */))
const _03573936 = () => interopDefault(import('../pages/ideen/energie-sparen.vue' /* webpackChunkName: "pages/ideen/energie-sparen" */))
const _91528042 = () => interopDefault(import('../pages/ideen/idea-async-data-functions.js' /* webpackChunkName: "pages/ideen/idea-async-data-functions" */))
const _3abd3cf6 = () => interopDefault(import('../pages/ideen/komfort.vue' /* webpackChunkName: "pages/ideen/komfort" */))
const _00e3eb03 = () => interopDefault(import('../pages/ideen/licht.vue' /* webpackChunkName: "pages/ideen/licht" */))
const _6c7e70ef = () => interopDefault(import('../pages/ideen/sicherheit.vue' /* webpackChunkName: "pages/ideen/sicherheit" */))
const _2d16f2b0 = () => interopDefault(import('../pages/ideen/smarte-tuerschloesser-mit-nuki.vue' /* webpackChunkName: "pages/ideen/smarte-tuerschloesser-mit-nuki" */))
const _2272972e = () => interopDefault(import('../pages/ideen/smarter-sonnenschutz-von-eq-3.vue' /* webpackChunkName: "pages/ideen/smarter-sonnenschutz-von-eq-3" */))
const _0a0d63c4 = () => interopDefault(import('../pages/ideen/unterhaltung.vue' /* webpackChunkName: "pages/ideen/unterhaltung" */))
const _4bcd5cd2 = () => interopDefault(import('../pages/ideen/wetter-und-klima-mit-netatmo.vue' /* webpackChunkName: "pages/ideen/wetter-und-klima-mit-netatmo" */))
const _f95ff37a = () => interopDefault(import('../pages/magenta-smarthome-app-und-funktionen/wiz-integration.vue' /* webpackChunkName: "pages/magenta-smarthome-app-und-funktionen/wiz-integration" */))
const _f095ef8a = () => interopDefault(import('../pages/magenta-smarthome-app-updates/version-6.5.vue' /* webpackChunkName: "pages/magenta-smarthome-app-updates/version-6.5" */))
const _2d6bea54 = () => interopDefault(import('../pages/shop/fehler.vue' /* webpackChunkName: "pages/shop/fehler" */))
const _f33e10a6 = () => interopDefault(import('../pages/shop/home-base-2-aktion.vue' /* webpackChunkName: "pages/shop/home-base-2-aktion" */))
const _17a0147b = () => interopDefault(import('../pages/shop/kundenformular.vue' /* webpackChunkName: "pages/shop/kundenformular" */))
const _5aac12be = () => interopDefault(import('../pages/shop/kundenformular/index.vue' /* webpackChunkName: "pages/shop/kundenformular/index" */))
const _d3d4407c = () => interopDefault(import('../pages/shop/kundenformular/identifizierung.vue' /* webpackChunkName: "pages/shop/kundenformular/identifizierung" */))
const _2453b5d5 = () => interopDefault(import('../pages/shop/kundenformular/login.vue' /* webpackChunkName: "pages/shop/kundenformular/login" */))
const _779da07b = () => interopDefault(import('../pages/shop/kundenformular/upgrade-identifikation.vue' /* webpackChunkName: "pages/shop/kundenformular/upgrade-identifikation" */))
const _ce38d68c = () => interopDefault(import('../pages/shop/zuhause-im-blick-paket.vue' /* webpackChunkName: "pages/shop/zuhause-im-blick-paket" */))
const _40c80325 = () => interopDefault(import('../pages/weitere-informationen/agb-brodos.vue' /* webpackChunkName: "pages/weitere-informationen/agb-brodos" */))
const _53e324da = () => interopDefault(import('../pages/weitere-informationen/sitemap.vue' /* webpackChunkName: "pages/weitere-informationen/sitemap" */))
const _ac6f00fc = () => interopDefault(import('../pages/weitere-informationen/ueber-brodos.vue' /* webpackChunkName: "pages/weitere-informationen/ueber-brodos" */))
const _4924db6e = () => interopDefault(import('../pages/weitere-informationen/ueber-qivicon.vue' /* webpackChunkName: "pages/weitere-informationen/ueber-qivicon" */))
const _7616b6c4 = () => interopDefault(import('../pages/hilfe/kompatible-geraete/kompa-metadata.js' /* webpackChunkName: "pages/hilfe/kompatible-geraete/kompa-metadata" */))
const _4a43a568 = () => interopDefault(import('../pages/shop/checkout/adresse.vue' /* webpackChunkName: "pages/shop/checkout/adresse" */))
const _6e9ea857 = () => interopDefault(import('../pages/shop/checkout/bestaetigung.vue' /* webpackChunkName: "pages/shop/checkout/bestaetigung" */))
const _a90ac394 = () => interopDefault(import('../pages/shop/checkout/bestaetigung-teaser.vue' /* webpackChunkName: "pages/shop/checkout/bestaetigung-teaser" */))
const _63a42402 = () => interopDefault(import('../pages/shop/checkout/warenkorb.vue' /* webpackChunkName: "pages/shop/checkout/warenkorb" */))
const _1d867b60 = () => interopDefault(import('../pages/shop/checkout/zahlungsart.vue' /* webpackChunkName: "pages/shop/checkout/zahlungsart" */))
const _25f07f04 = () => interopDefault(import('../pages/shop/checkout/zusammenfassung.vue' /* webpackChunkName: "pages/shop/checkout/zusammenfassung" */))
const _ecf9c66e = () => interopDefault(import('../pages/shop/dienst/mza-upgrade.vue' /* webpackChunkName: "pages/shop/dienst/mza-upgrade" */))
const _703477c2 = () => interopDefault(import('../pages/shop/dienst/upgrade.vue' /* webpackChunkName: "pages/shop/dienst/upgrade" */))
const _49bbc10d = () => interopDefault(import('../pages/shop/verfuegbarkeitsbenachrichtigung/_token.vue' /* webpackChunkName: "pages/shop/verfuegbarkeitsbenachrichtigung/_token" */))
const _46ff045e = () => interopDefault(import('../pages/geraete/_slug.vue' /* webpackChunkName: "pages/geraete/_slug" */))
const _afb38a72 = () => interopDefault(import('../pages/ideen/_idea.vue' /* webpackChunkName: "pages/ideen/_idea" */))
const _600d9c9a = () => interopDefault(import('../pages/service/_service.vue' /* webpackChunkName: "pages/service/_service" */))
const _a8ee3082 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _287f8280 = () => interopDefault(import('../special-pages/shop-list' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aktionsangebote",
    component: _ce4d292a,
    name: "aktionsangebote"
  }, {
    path: "/aktivieren",
    component: _77c3aa8b,
    name: "aktivieren"
  }, {
    path: "/angebot-beendet",
    component: _d731bb3a,
    name: "angebot-beendet"
  }, {
    path: "/app-download",
    component: _198e6057,
    name: "app-download"
  }, {
    path: "/applogin",
    component: _280cdf0b,
    name: "applogin"
  }, {
    path: "/hilfe",
    component: _479ac48c,
    name: "hilfe"
  }, {
    path: "/infomail-abmelden",
    component: _7de5fdef,
    name: "infomail-abmelden"
  }, {
    path: "/infos-zum-online-kauf",
    component: _c67f48ea,
    name: "infos-zum-online-kauf"
  }, {
    path: "/magenta-smarthome-alexa-skill",
    component: _afa90caa,
    name: "magenta-smarthome-alexa-skill"
  }, {
    path: "/magenta-smarthome-app-und-funktionen",
    component: _08544926,
    name: "magenta-smarthome-app-und-funktionen"
  }, {
    path: "/magenta-smarthome-app-updates",
    component: _11931645,
    name: "magenta-smarthome-app-updates"
  }, {
    path: "/magentazuhause-alexa-skill",
    component: _33f54432,
    name: "magentazuhause-alexa-skill"
  }, {
    path: "/magentazuhause-app",
    component: _0e44e901,
    name: "magentazuhause-app"
  }, {
    path: "/newsletter",
    component: _35820ac8,
    name: "newsletter"
  }, {
    path: "/newsletter-abmelden",
    component: _696403db,
    name: "newsletter-abmelden"
  }, {
    path: "/partner",
    component: _15e7af75,
    name: "partner"
  }, {
    path: "/ratenzahlung",
    component: _131bf078,
    name: "ratenzahlung"
  }, {
    path: "/schnell-starten",
    component: _ca6f7d0c,
    name: "schnell-starten"
  }, {
    path: "/shop-start",
    component: _44c1bfae,
    name: "shop-start"
  }, {
    path: "/so-geht-smart-home",
    component: _f13495d0,
    name: "so-geht-smart-home"
  }, {
    path: "/sticky-banner-alexa-skill",
    component: _0b1309b7,
    name: "sticky-banner-alexa-skill"
  }, {
    path: "/url-generator",
    component: _a593ed36,
    name: "url-generator"
  }, {
    path: "/geraete/async-data-functions",
    component: _5de109c6,
    name: "geraete-async-data-functions"
  }, {
    path: "/hilfe/faq",
    component: _32865c04,
    name: "hilfe-faq"
  }, {
    path: "/hilfe/home-base-wechsel",
    component: _4866ef3e,
    name: "hilfe-home-base-wechsel"
  }, {
    path: "/hilfe/kompatible-geraete",
    component: _4ba12c9a,
    name: "hilfe-kompatible-geraete"
  }, {
    path: "/hilfe/kontakt",
    component: _401f0908,
    name: "hilfe-kontakt"
  }, {
    path: "/hilfe/retoure-und-kuendigung",
    component: _1a880948,
    name: "hilfe-retoure-und-kuendigung"
  }, {
    path: "/hilfe/zentralen-und-funkstandards",
    component: _ed5dca90,
    name: "hilfe-zentralen-und-funkstandards"
  }, {
    path: "/ideen/alle-ideen",
    component: _e04a72ee,
    name: "ideen-alle-ideen"
  }, {
    path: "/ideen/energie-sparen",
    component: _03573936,
    name: "ideen-energie-sparen"
  }, {
    path: "/ideen/idea-async-data-functions",
    component: _91528042,
    name: "ideen-idea-async-data-functions"
  }, {
    path: "/ideen/komfort",
    component: _3abd3cf6,
    name: "ideen-komfort"
  }, {
    path: "/ideen/licht",
    component: _00e3eb03,
    name: "ideen-licht"
  }, {
    path: "/ideen/sicherheit",
    component: _6c7e70ef,
    name: "ideen-sicherheit"
  }, {
    path: "/ideen/smarte-tuerschloesser-mit-nuki",
    component: _2d16f2b0,
    name: "ideen-smarte-tuerschloesser-mit-nuki"
  }, {
    path: "/ideen/smarter-sonnenschutz-von-eq-3",
    component: _2272972e,
    name: "ideen-smarter-sonnenschutz-von-eq-3"
  }, {
    path: "/ideen/unterhaltung",
    component: _0a0d63c4,
    name: "ideen-unterhaltung"
  }, {
    path: "/ideen/wetter-und-klima-mit-netatmo",
    component: _4bcd5cd2,
    name: "ideen-wetter-und-klima-mit-netatmo"
  }, {
    path: "/magenta-smarthome-app-und-funktionen/wiz-integration",
    component: _f95ff37a,
    name: "magenta-smarthome-app-und-funktionen-wiz-integration"
  }, {
    path: "/magenta-smarthome-app-updates/version-6.5",
    component: _f095ef8a,
    name: "magenta-smarthome-app-updates-version-6.5"
  }, {
    path: "/shop/fehler",
    component: _2d6bea54,
    name: "shop-fehler"
  }, {
    path: "/shop/home-base-2-aktion",
    component: _f33e10a6,
    name: "shop-home-base-2-aktion"
  }, {
    path: "/shop/kundenformular",
    component: _17a0147b,
    children: [{
      path: "",
      component: _5aac12be,
      name: "shop-kundenformular"
    }, {
      path: "identifizierung",
      component: _d3d4407c,
      name: "shop-kundenformular-identifizierung"
    }, {
      path: "login",
      component: _2453b5d5,
      name: "shop-kundenformular-login"
    }, {
      path: "upgrade-identifikation",
      component: _779da07b,
      name: "shop-kundenformular-upgrade-identifikation"
    }]
  }, {
    path: "/shop/zuhause-im-blick-paket",
    component: _ce38d68c,
    name: "shop-zuhause-im-blick-paket"
  }, {
    path: "/weitere-informationen/agb-brodos",
    component: _40c80325,
    name: "weitere-informationen-agb-brodos"
  }, {
    path: "/weitere-informationen/sitemap",
    component: _53e324da,
    name: "weitere-informationen-sitemap"
  }, {
    path: "/weitere-informationen/ueber-brodos",
    component: _ac6f00fc,
    name: "weitere-informationen-ueber-brodos"
  }, {
    path: "/weitere-informationen/ueber-qivicon",
    component: _4924db6e,
    name: "weitere-informationen-ueber-qivicon"
  }, {
    path: "/hilfe/kompatible-geraete/kompa-metadata",
    component: _7616b6c4,
    name: "hilfe-kompatible-geraete-kompa-metadata"
  }, {
    path: "/shop/checkout/adresse",
    component: _4a43a568,
    name: "shop-checkout-adresse"
  }, {
    path: "/shop/checkout/bestaetigung",
    component: _6e9ea857,
    name: "shop-checkout-bestaetigung"
  }, {
    path: "/shop/checkout/bestaetigung-teaser",
    component: _a90ac394,
    name: "shop-checkout-bestaetigung-teaser"
  }, {
    path: "/shop/checkout/warenkorb",
    component: _63a42402,
    name: "shop-checkout-warenkorb"
  }, {
    path: "/shop/checkout/zahlungsart",
    component: _1d867b60,
    name: "shop-checkout-zahlungsart"
  }, {
    path: "/shop/checkout/zusammenfassung",
    component: _25f07f04,
    name: "shop-checkout-zusammenfassung"
  }, {
    path: "/shop/dienst/mza-upgrade",
    component: _ecf9c66e,
    name: "shop-dienst-mza-upgrade"
  }, {
    path: "/shop/dienst/upgrade",
    component: _703477c2,
    name: "shop-dienst-upgrade"
  }, {
    path: "/shop/verfuegbarkeitsbenachrichtigung/:token?",
    component: _49bbc10d,
    name: "shop-verfuegbarkeitsbenachrichtigung-token"
  }, {
    path: "/geraete/:slug?",
    component: _46ff045e,
    name: "geraete-slug"
  }, {
    path: "/ideen/:idea?",
    component: _afb38a72,
    name: "ideen-idea"
  }, {
    path: "/service/:service?",
    component: _600d9c9a,
    name: "service-service"
  }, {
    path: "/",
    component: _a8ee3082,
    name: "index"
  }, {
    path: "/shop/:category?/:filter?/:page?",
    component: _287f8280,
    name: "shop-list"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}

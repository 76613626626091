import { updateAutomaticLinkTracking } from './update-automatic-link-tracking';
import { track } from './track';

export default {
  view(data) {
    return track('view', data);
  },
  link(data) {
    return track('link', data);
  },
  updateAutomaticLinkTracking,
};

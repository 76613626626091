<template>
  <button
    ref="button"
    type="button"
    class="btn btn--transparent btn--borderless search__btn"
    @click.prevent="onClick"
  >
    <SvgIcon
      class="icon search__icon"
      :class="iconClasses"
      :image="icon"
    />
  </button>
</template>

<script>
import searchIcon from '@/assets/images/search-2021.svg';
import loadingIcon from '@/assets/images/search-loading.svg';

export default {
  name: 'SearchButton',
  props: {
    iconClass: {
      type: String,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchIcon,
      loadingIcon,
    };
  },
  computed: {
    icon() {
      return this.isLoading ? this.loadingIcon : this.searchIcon;
    },
    iconClasses() {
      const classes = [this.iconClass];
      if (this.isLoading) {
        classes.push('search__icon--loading');
      }
      return classes;
    },
  },
  methods: {
    focus() {
      this.$refs.button.focus();
    },
    onClick() {
      this.$emit('click');
    },
  },
};

</script>

<style lang="scss">
  @import '../assets/base';

  .search {
    @keyframes rotate {
      from {
        transform: rotate(0);
      }
      to {
        transform: rotate(359deg);
      }
    }
    &__form-wrapper {
      display: none;
      background-color: color(white);
      position: absolute;
      left: 0;
      right: 0;
      top: 0;

      &--open {
        display: block;
        z-index: 2;
      }
    }

    &__icon {
      &--loading {
        animation: rotate 1.5s linear infinite;
      }
    }
  }
</style>

<template>
  <div class="download-app">
    <CdnImageElement
      v-if="isDesktop"
      :width="qrCodeSize"
      :height="qrCodeSize"
      alt="QR-Code zur MagentaZuhause App"
      :src="qrCodeSrc"
    />
    <GetAppContainer v-else :tealium-suffix="tealiumSuffix" />
  </div>
</template>

<script>
import CdnImageElement from '@/components/cdn-image-element';
import GetAppContainer from '@/components/get-app-container';
import platform from '@/helpers/platform';

export default {
  name: 'DownloadApp',
  components: {
    CdnImageElement,
    GetAppContainer,
  },
  props: {
    qrCodeSize: {
      type: Number,
      default: 100,
    },
    tealiumSuffix: {
      type: String,
      default: '',
    },
    qrCodeSrc: {
      type: String,
      default: '/api/content/images/8f8cb953252a75dacba550f08f0b24da/qr-code-magenta-zuhause.png',
    },
  },
  data() {
    return {
      isDesktop: false,
    };
  },
  mounted() {
    this.isDesktop = platform?.isDesktopOS();
  },
};
</script>

<template>
  <OfferTile
    :class="['offer-tile-container', { 'offer-tile-container--highlight-tile': highlightTile }]"
    :offer="offer"
    :tag-title="tagTitle"
    :highlight-tile="highlightTile"
    :highlight-flag-hex="highlightColor"
    link-tag="nuxt-link"
    no-text-brand
  >
    <template #image>
      <GoliathImageElement
        v-if="imageSources"
        :alt="title"
        :sm-max-width="500"
        :sources="imageSources"
        :lazy="lazy"
      />
      <ProductTileBadge
        :is-new="offer.isNew"
        :is-discounted="!!discount"
        :content="rebateDescription"
        :custom-badge-name="rebateName"
        :footnote-content="footnoteText"
        class="offer-tile-container__badge"
      />
    </template>
    <template #brandLogo>
      <CdnImageElement
        v-if="brand.logo"
        :class="[
          'offer-tile-container__logo-image',
          `offer-tile-container__logo-image--${brand.slug}`,
        ]"
        render-without-figure
        :src="brand.logo"
        :alt="brand.name"
      />
      <span v-else>{{ brand.name }}</span>
    </template>

    <template v-if="hasOfferFlag" #highlightFlag>
      Aktion
    </template>
    <template v-else-if="hasHighlightFlag" #highlightFlag>
      Bestseller
    </template>
    <template v-if="hasEnergyEfficiency" #energyLabel>
      <EnergyLabelContainer
        class="offer-tile-container__energy-label"
        :product="offer.product"
        datasheet-alignment="justify"
        no-data-sheet
      />
    </template>
    <template v-if="highlightTile" #headerAfter>
      <ul class="offer-tile-container__usps-list">
        <li v-for="item in shortUsp" :key="item">
          <SvgIcon :image="checkIcon" />
          <span>{{ item }}</span>
        </li>
      </ul>
    </template>
    <template #alternatives>
      <div v-if="isBundle" class="offer-tile-container__variations">
        <AlternativeOffersCount :product-alternatives="offer.productAlternatives" />
      </div>
      <FinancingInfoContainer
        v-if="hasFinancing"
        :price="price"
        class="offer-tile-container__financing-info"
      />
    </template>
    <template #contentAfter>
      <div class="offer-tile-container__app-icons">
        <span
          v-if="isMzaCompatible"
          class="offer-tile-container__svg-icon-wrapper"
          title="MagentaZuhause App"
        >
          <SvgIcon :image="mzaIconAsset" class="offer-tile-container__mza-icon" />
        </span>
        <span
          v-if="isMshCompatible"
          class="offer-tile-container__svg-icon-wrapper"
          title="Magenta SmartHome App"
        >
          <SvgIcon :image="mshIcon" />
        </span>
        <span v-for="app in compatibleApps" :key="app.image.alt" :title="app.image.alt">
          <CdnImageElement render-without-figure :src="app.image.src" :alt="app.image.alt" />
        </span>
      </div>
    </template>
  </OfferTile>
</template>

<script>
import { get } from 'lodash';
import { OfferTile, GoliathImageElement } from '@i22-td-smarthome/component-library';
import { getDiscount, getPrice } from '@i22/rocket';
import { getBrand } from '@/lib/brand';

import AlternativeOffersCount from '@/components/alternative-offers-count';
import EnergyLabelContainer from '@/components/energy-label-container';
import FinancingInfoContainer from '@/components/add-to-cart-prompt/financing-info-container';
import ProductTileBadge from '@/components/product-detail/product-tile-badge';
import mshIcon from '@/assets/images/msh-logo.svg';
import mzaIconAsset from '@/assets/images/mza-relaunch/mza-logo-magenta-solid.svg';

import checkIcon from '@/assets/images/check.svg';

const MIN_FINANCING_PRICE = 99;

// Temporarily until Goliath provides the data via graphql
// Products with bestseller flag
const HIGHLIGHTS_OFFER = [
  'DCS8302LH-SPK',
  '4251281645534',
  '8718699787059',
  '4251281641420',
  '4251281645138',
];

// Products with aktion flag
const OFFER_FLAG = ['4251281664115', '4251281647415', '4251281645138'];

export default {
  name: 'OfferTileContainer',
  components: {
    OfferTile,
    ProductTileBadge,
    AlternativeOffersCount,
    EnergyLabelContainer,
    FinancingInfoContainer,
    GoliathImageElement,
  },
  props: {
    tagTitle: { type: String, default: 'h2' },
    offer: { type: Object, required: true },
    lazy: { type: Boolean },
    highlightTile: { type: Boolean },
  },
  data() {
    return {
      mshIcon,
      mzaIconAsset,
      possibleCompatibilities: [
        {
          tag: 'apple',
          image: {
            src:
              '/api/content/images/shop/compatibility-icons/15cbbfbf5c10bfcd10681a95d840c7b6/apple@2x.png',
            alt: 'Apple Home',
          },
        },
        {
          tag: 'amazon',
          image: {
            src:
              '/api/content/images/shop/compatibility-icons/56f63765f31495e485a8544e4659cc04/alexa@2x.png',
            alt: 'Amazon Alexa',
          },
        },
        {
          tag: 'google',
          image: {
            src:
              '/api/content/images/shop/compatibility-icons/8ad510a7383a1e4f025481f549dd293d/google@2x.png',
            alt: 'Hey Google',
          },
        },
        {
          tag: 'hersteller',
          image: {
            src:
              '/api/content/images/shop/compatibility-icons/8ca3f2d04e3a2d93b1cc4be349aae50f/herstellerapp@2x.png',
            alt: 'App des Herstellers',
          },
        },
      ],
      checkIcon,
    };
  },
  computed: {
    hasFinancing() {
      return this.offer.financing && this.price > MIN_FINANCING_PRICE;
    },
    price() {
      return getPrice(this.offer.prices, 'DEFAULT', 'ONETIME')?.value;
    },
    wasPrice() {
      return getPrice(this.offer.prices, 'WAS', 'ONETIME')?.value;
    },
    hasHighlightFlag() {
      return this.articleNumbersWithAlternatives.some((articleNumber) =>
        HIGHLIGHTS_OFFER.includes(articleNumber));
    },
    hasOfferFlag() {
      return this.articleNumbersWithAlternatives.some((articleNumber) =>
        OFFER_FLAG.includes(articleNumber));
    },
    articleNumbersWithAlternatives() {
      const articleNumbers = [this.offer?.product?.articleNumber];
      const properties = this.offer.productAlternatives?.[0]?.properties || [];

      properties.forEach(({ alternatives }) => {
        articleNumbers.push(
          ...alternatives
            .map(({ offer }) => offer?.product?.articleNumber)
            .filter((articleNumber) => articleNumber && !articleNumbers.includes(articleNumber)),
        );
      });

      return articleNumbers.filter(Boolean);
    },
    title() {
      if (this.offer.contract) {
        return this.offer.contract.name;
      }
      return this.offer.product.family.name;
    },
    hasEnergyEfficiency() {
      // Check if the main product has energy efficiency data
      if (this.offer?.product?.energyEfficiency) {
        return true;
      }
      // Check if there are any bundled products with energy efficiency data
      if (this.offer?.product?.bundledProducts?.find((product) => product.energyEfficiency)) {
        return true;
      }
      return false;
    },
    isBundle() {
      return !!this.offer.product?.bundle;
    },
    rebateDescription() {
      const description = get(this.offer, 'rebate.description');
      if (description === 'no description') return null;
      return description;
    },
    rebateName() {
      return this.offer.rebate?.name || '';
    },
    deliveryLeadTime() {
      if (this.offer.contract) return '';
      return this.offer.product.deliveryLeadTime;
    },
    footnoteText() {
      const footnote = get(this.offer, 'rebate.footnote');
      if (!footnote) return null;
      return footnote;
    },
    inStock() {
      const { product } = this.offer;
      // An offer must always contain at least a product or contract (or both).
      // When no product is present in the offer we can be certain to have a
      // contract. As contracts are intangible they may not have stock and are
      // considered always available.
      if (!product) {
        return true;
      }
      return product.stock.amount > 0;
    },
    discount() {
      return getDiscount(this.price, this.wasPrice);
    },
    imageSources() {
      return this.offer.teaserImage?.sources;
    },
    brand() {
      return getBrand(this.offer);
    },
    productTags() {
      if (!this.offer?.product) return [];
      let tags = [];
      if (this.offer.product.bundledProducts?.length > 0) {
        const { bundledProducts } = this.offer.product;

        tags = bundledProducts[0].tags.filter((tag) =>
          bundledProducts.every((product) => product.tags.includes(tag)));
      } else if (this.offer.product.tags) {
        tags = this.offer.product.tags;
      }
      return tags.map((str) => str.toLowerCase());
    },
    isMzaCompatible() {
      return ['mzabasic', 'mzapro'].some((tag) => this.productTags.includes(tag));
    },
    isMshCompatible() {
      return ['mshfree', 'mshpro'].some((tag) => this.productTags.includes(tag));
    },
    compatibleApps() {
      return this.possibleCompatibilities.filter((app) => this.productTags.includes(app.tag));
    },
    shortUsp() {
      return this.offer?.product?.shortUsp || [];
    },
    highlightColor() {
      const blue = '53BAF2';
      const magenta = 'e20074';
      return this.hasOfferFlag ? blue : magenta;
    },
  },
  methods: {
    variationLink(link) {
      this.$router.replace(link);
    },
  },
};
</script>

<style lang="scss">
@import 'assets/base';

.offer-tile-container {
  --max-list-width: 90vw;

  height: 100%;

  &__unavailable {
    color: $color-error;
  }

  &__badge {
    position: absolute;
    top: 2 * $grid-base;
    right: calc($grid-base + 64px);

    @include mq($mq-md) {
      top: 4 * $grid-base;
      right: calc($grid-base + 90px);
    }
  }

  &__financing-info {
    color: #262626;
    min-height: 36px;
  }

  &__logo-image {
    height: 20px;
    align-self: flex-start;

    &--paul-neuhaus,
    &--xiaomi {
      height: 3 * $grid-base;
    }

    &--tint {
      height: 4 * $grid-base;
    }
  }

  &__svg-icon-wrapper {
    svg {
      height: 2 * $grid-base;
      width: auto;
    }
  }

  &__app-icons {
    display: flex;
    justify-content: flex-end;
    align-self: flex-end;
    gap: 0.5 * $grid-base;
    height: 2 * $grid-base;

    img {
      height: 2 * $grid-base;
      width: auto;
    }

    .mza {
      @include mq($mq-sm, $mq-lg - 1) {
        display: none;
      }
    }
  }

  .offer-tile__title {
    word-break: auto-phrase;
    hyphens: auto;
  }

  &--highlight-tile {
    .offer-tile__image-wrapper {
      @include mq($mq-md) {
        position: relative;
      }
    }

    .offer-tile__content {
      @include mq($mq-md) {
        min-height: 235px;
        justify-content: flex-end;
      }
    }

    .offer-tile-container {
      &__badge {
        @include mq($mq-md) {
          right: initial;
          left: $grid-base;
          top: $grid-base;
        }
      }

      &__usps-list {
        list-style: none;

        li {
          color: $color-font;
          margin-bottom: 0.5 * $grid-base;
          display: flex; // for the text alignment when the line breaks
          align-items: flex-start;
          gap: 0.5 * $grid-base;

          svg {
            color: color(magenta, 1000);
            min-width: 18px;
            height: auto;
          }
        }

        @include mq($mq-md) {
          margin-top: 2 * $grid-base;
          min-height: 90px;
        }
      }
    }

    .offer-tile__title {
      word-break: normal;
    }
  }
}
</style>

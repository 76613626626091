<template>
  <li
    class="nav__item"
    :class="containerClassesLink"
  >
    <!-- Expandable -->
    <template v-if="expandable">
      <a
        v-if="to"
        ref="link"
        :title="label"
        :href="to"
        class="mainnav__link"
        :class="containerClasses"
        :data-tealium-rel="trackingKey"
        @click.prevent="onClickLabel"
      >
        {{ label }}
        <SvgIcon
          v-show="isAtLeastDesktopScreen"
          :image="arrowDownIcon"
          class="icon mainnav__icon mainnav__icon--subnav"
        />
        <SvgIcon
          v-show="!isAtLeastDesktopScreen"
          :image="arrowRightMenu"
          class="icon mainnav__icon mainnav__icon--link mainnav__icon--mobile"
        />
      </a>
      <a
        v-else
        class="mainnav__link"
        :href="to"
        :data-tealium-rel="trackingKey"
        @click.prevent="onClickLabel"
      >
        {{ label }}
        <SvgIcon
          v-show="isAtLeastDesktopScreen"
          :image="arrowDownIcon"
          class="icon mainnav__icon mainnav__icon--subnav"
        />
        <SvgIcon
          v-show="!isAtLeastDesktopScreen"
          :image="arrowRightMenu"
          class="icon mainnav__icon mainnav__icon--link mainnav__icon--mobile"
        />
      </a>

      <div
        v-show="isExpanded"
        class="subnav-wrapper"
      >
        <SmallScreenNavigationHead
          v-if="actualDepth === depth"
          :actual-depth="actualDepth"
          :show-back-button="actualDepth === depth"
          :navigation-title="label"
          :show-active-state="true"
          @collapseSubNavigation="collapse"
          @closeSmallScreenNav="onCloseSmallScreenNav"
        />
        <slot />
      </div>
    </template>

    <!-- External Link -->
    <a
      v-else-if="isExternal"
      class="mainnav__link mainnav__link--external"
      rel="noopener"
      target="_blank"
      :title="label"
      :href="to"
      :class="{ 'mainnav__link--is-active': isActive }"
      :data-tealium-rel="trackingKey"
    >
      {{ label }}
    </a>

    <!-- Internal Link -->
    <nuxt-link
      v-else
      class="mainnav__link"
      active-class="mainnav__link--is-active"
      :title="label"
      :to="to"
      :class="{ 'mainnav__link--is-active': isActive }"
      :data-tealium-rel="trackingKey"
      exact
    >
      {{ label }}
    </nuxt-link>
  </li>
</template>

<script>
import { isEqual, isUndefined, startsWith } from 'lodash';
import { mixin as Clickaway } from 'vue-clickaway2';
import arrowDownIcon from '@/assets/images/arrow-down-main-nav.svg';
import arrowRightMenu from '@/assets/images/arrow-right-menu.svg';
import arrowLeftMenu from '@/assets/images/arrow-left-menu.svg';
import menuClose from '@/assets/images/menu-close.svg';
import dasherize from '@/lib/dasherize';
import Escapable from '@/mixins/escapable';
import externalLinkIcon from '@/assets/images/external-link.svg';
import Responsive from '@/mixins/responsive';
import SmallScreenNavigationHead from '@/components/small-screen-navigation-head';

const EXTERNAL_URL_PREFIXES = ['http://', 'https://', '//'];

export default {
  name: 'SmallScreenNavigationItem',
  expose: ['onClickLabel'],
  components: {
    SmallScreenNavigationHead,
  },
  mixins: [Clickaway, Escapable, Responsive],
  props: {
    label: {
      type: String,
      required: true,
    },
    to: {
      type: [String, Object],
      default: null,
    },
    exact: {
      type: Boolean,
      default: false,
    },
    external: {
      type: Boolean,
      default: undefined,
    },
    active: {
      type: Boolean,
      default: undefined,
    },
    expandable: {
      type: Boolean,
      default: false,
    },
    depth: {
      type: Number,
      default: 1,
    },
    actualDepth: {
      type: Number,
      default: null,
    },
    itemIndex: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isExpanded: false,
      arrowDownIcon,
      arrowRightMenu,
      arrowLeftMenu,
      menuClose,
      externalLinkIcon,
      isLastExpanded: this.$parent.hasLastExpanded && this.isExpandable,
      isExpandable: this.expandable,
    };
  },
  computed: {
    trackingKey() {
      return `navi-top.text.${dasherize(this.label)}`;
    },
    isExternal() {
      if (!isUndefined(this.external)) return this.external;
      if (!this.to) return false;
      return EXTERNAL_URL_PREFIXES.some((prefix) => startsWith(this.to, prefix));
    },
    targetRoute() {
      if (this.isExternal || !this.$router || !this.$route) return undefined;
      const result = this.$router.resolve(this.to);
      if (!result) return undefined;
      return result.route;
    },
    isActive() {
      if (!isUndefined(this.active)) return this.active;
      if (!this.targetRoute) return false;
      if (this.exact) {
        return this.$route.fullPath === this.targetRoute.fullPath;
      }
      return startsWith(this.$route.path, this.targetRoute.path);
    },
    containerClasses() {
      return {
        'mainnav__link--is-active': this.isActive,
        'mainnav__link--is-expanded': this.isExpanded,
        'mainnav__link--is-hidden': this.isExpanded,
      };
    },
    containerClassesLink() {
      return {
        'nav__item--is-last-expanded': this.$parent.hasLastExpanded && this.isExpandable,
      };
    },
  },
  watch: {
    actualDepth(value) {
      // if zero force isExapanded to be false
      if (value === 0) {
        this.isExpanded = false;
      }
      this.isLastExpanded = (value === this.depth);
    },
    $route(route, oldRoute) {
      // Collapse the navigation item when changing the route.
      if (!isEqual(route, oldRoute)) {
        this.onCloseSmallScreenNav();
      }
    },
  },
  methods: {
    onClickLabel() {
      if (this.isExpandable && !this.isExpanded && !this.isLastExpanded) {
        this.isExpanded = true;
        this.isLastExpanded = true;
        if (this.actualDepth === 0) {
          this.$emit('itemIndexChanged', this.itemIndex);
          return this.$emit('openSubNavigation', this.label);
        }
        this.$emit('subnavItemIndexChanged', this.itemIndex);
        return this.$emit('openSubNavigation', this.label);
      }
      return null;
    },
    collapse() {
      if (!this.isExpandable || !this.isExpanded || !this.isLastExpanded) return;
      this.isExpanded = false;
      this.isLastExpanded = false;
      this.$emit('subnavItemIndexChanged', null);
      this.$emit('collapseParent');
    },
    onEsc() {
      this.collapse();
    },
    onCloseSmallScreenNav() {
      this.isExpanded = false;
      this.isLastExpanded = false;
      this.$emit('closeSmallScreenNav');
    },
  },
};

</script>

<style lang="scss" scoped>
@import 'assets/base';
.nav__item {
  @include font-size(21px, 24px);
  font-weight: bold;
}
</style>

<template>
  <div
    ref="tooltipContainer"
    v-click-outside="hideTooltipLayer"
    data-testid="cart-tooltip-container"
    @mouseover.prevent="showTooltipLayer"
    @mouseout.prevent="hideTooltipLayer"
  >
    <nuxt-link
      to="/shop/checkout/warenkorb"
      data-tealium-rel="header.button.warenkorb"
      class="cart-indicator btn btn--transparent btn--borderless"
      active-class="cart-indicator--active"
    >
      <TooltipLayer
        v-if="!isTariffUpgradeFlow"
        ref="tooltipLayer"
        theme="light"
        position="bottom"
        class="cart-indicator__tooltip"
      >
        <ShippingCostsInfo text-variant="tooltip" />
      </TooltipLayer>
      <SvgIcon class="icon cart-indicator__icon" :class="iconClass" :image="iconShoppingCard" />
      <CountBadge v-if="cartQuantity" :value="cartQuantity" class="cart-indicator__count" />
    </nuxt-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import iconShoppingCard from '@/assets/images/shopping-card.svg';
import CountBadge from '@/components/count-badge';
import TooltipLayer from '@/components/tooltip-layer';
import ShippingCostsInfo from '@/components/shipping-costs/shipping-costs-info';

export default {
  name: 'CartIndicator',
  components: {
    CountBadge,
    TooltipLayer,
    ShippingCostsInfo,
  },
  props: {
    iconClass: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      iconShoppingCard,
    };
  },
  computed: mapGetters('cart', ['cartQuantity', 'isTariffUpgradeFlow']),
  methods: {
    showTooltipLayer() {
      // show tooltip only if there is a product(s) in the cart
      if (!this.cartQuantity) return;
      if (this.$refs.tooltipLayer?.isActive) return;
      this.$refs.tooltipLayer?.show(this.$refs.tooltipContainer.getBoundingClientRect());
    },
    hideTooltipLayer() {
      if (!this.$refs.tooltipLayer || !this.cartQuantity) return;
      this.$refs.tooltipLayer.hide();
    },
  },
};
</script>

<style lang="scss">
@import 'assets/base';

$component: '.cart-indicator';

#{$component} {
  position: relative;
  text-decoration: none;
  height: 100%;
  border-radius: 0;
  display: flex;
  align-items: center;

  &.btn--transparent {
    border-color: transparent;
  }

  &:active,
  &:visited,
  &:hover,
  &:focus {
    color: $color-font;
    @media screen and (min-width: 1024px) {
      border-radius: 4px;
    }
  }

  &__count {
    font-size: 15px;
    position: absolute;
    top: 8px;
    right: 4px;

    @include mq($mq-large) {
      top: -4px;
      right: 9px;
    }
  }

  &__tooltip {
    color: $color-font;
  }

  .secondary-navigation &--active {
    color: $color-primary;

    #{$component}__count {
      background-color: $color-primary;
      color: white;
    }
  }
}
</style>

<template>
  <div class="footer-advantage">
    <GridContainer class="footer-advantage__container">
      <ul class="footer-advantage__list">
        <li v-for="(item, index) in list" :key="index" class="footer-advantage__item">
          <span class="footer-advantage__icon-bg">
            <svg-icon :image="IconCheck" class="icon footer-advantage__icon-check" />
          </span>
          <span v-if="index === 0">
            <ShippingCostsContainer text-variant="footer" />
          </span>
          <span v-else>
            {{ item }}
          </span>
        </li>
      </ul>
      <div class="footer-advantage__apps">
        <div class="footer-advantage__apps-title">
          Magenta Zuhause App herunterladen
        </div>
        <DownloadApp tealium-suffix="footer" qr-code-src="/api/content/images/qr-codes/27c495ccccdab12684889e878c5f05c1/qr-code-smarthome-de-footer.png" />
      </div>
    </GridContainer>
  </div>
</template>

<script>
import { GridContainer } from '@i22-td-smarthome/component-library';
import IconCheck from '@/assets/images/footer/check.svg';
import ShippingCostsContainer from '@/components/shipping-costs/shipping-costs-container';
import DownloadApp from '@/components/download-app';

export default {
  name: 'FooterAdvantage',
  components: {
    GridContainer,
    ShippingCostsContainer,
    DownloadApp,
  },
  data() {
    return {
      IconCheck,
      list: ['Versandkosten', 'Klimaneutraler Versand'],
    };
  },
};
</script>

<style lang="scss">
@import '~@/assets/base';

.footer-advantage {
  color: color(white);

  @include mq($mq-large) {
    padding: 0 $grid-base;
  }

  &__container {
    display: flex;
    justify-content: space-around;
    padding: $grid-base * 4 $grid-base * 2 $grid-base $grid-base * 2;
    flex-direction: column;

    @include mq($mq-medium-plus) {
      flex-direction: row;
    }

    @include mq($mq-large) {
      border-top: 1px solid color(grey, 17000);
    }
  }

  &__list {
    list-style: none;
    padding-right: $grid-base * 2;

    @include mq($mq-medium) {
      margin: 0;
      max-width: inherit;
    }
  }

  &__icon-bg {
    background-color: $color-primary;
    padding: 3px;
    border-radius: 50%;
    flex: 0 0 auto;
    width: 22px;
    height: 22px;
    position: relative;
    display: block;
    margin-right: $grid-base;

    @include mq($mq-large) {
      margin-right: $grid-base * 3;
    }
  }

  &__icon-check {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__item {
    display: flex;
    padding-bottom: $grid-base * 2;
    text-transform: uppercase;
    font-weight: 700;
  }

  // Apps
  &__apps {
    padding-top: $grid-base * 4;
    padding-bottom: $grid-base * 2;

    @include mq($mq-medium-plus) {
      padding-top: 0;
    }
  }

  &__apps-title {
    text-align: left;
    text-transform: uppercase;
    font-weight: 700;
    padding-bottom: $grid-base * 2;
  }

  &__app-icon {
    width: 176px;
  }

  &__app-icons {
    display: flex;
    text-align: left;

    &--ios {
      margin-right: $grid-base;
      margin-bottom: $grid-base;
    }
  }

  .get-app {
    justify-content: flex-start;

    &__link {
      margin-top: 0;
    }
  }
}
</style>

<template>
  <div class="grid__item grid__item--12 footer-corporate__wrapper">
    <Branding theme="footer" />
    <div class="grid grid--gutter-none footer-corporate">
      <div class="grid__item grid__item--12 grid__item--m-4 grid__item--l-3">
        <p class="footer-corporate__copyright">
          &copy; Telekom Deutschland GmbH
        </p>
      </div>
      <div class="grid__item grid__item-12 grid__item--m-8 grid__item--l-9">
        <ul class="footer-corporate__list">
          <li
            v-for="(item, key) in footerLinks"
            :key="key"
            class="footer-corporate__list-item"
          >
            <a
              v-if="item.isExternal"
              :href="item.url"
              target="_blank"
              rel="noopener"
              class="footer-corporate__link"
            >{{ item.label }}</a>
            <nuxt-link
              v-else
              class="footer-corporate__link"
              :to="item.url"
            >
              {{ item.label }}
            </nuxt-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Branding from '@/components/branding';

export default {
  name: 'FooterCorporate',
  components: {
    Branding,
  },
  data() {
    return {
      footerLinks: [
        {
          isExternal: true,
          label: 'Impressum',
          url: 'https://www.telekom.de/impressum',
        },
        {
          isExternal: true,
          label: 'AGB',
          url: 'https://www.telekom.de/agb',
        },
        {
          isExternal: true,
          label: 'Datenschutz',
          url: 'https://www.telekom.de/ueber-das-unternehmen/datenschutz#fragen-und-antworten',
        },
        {
          isExternal: false,
          label: 'Verbraucherinformationen',
          url: '/infos-zum-online-kauf',
        },
        {
          isExternal: true,
          label: 'Verträge hier kündigen',
          url: 'https://www.telekom.de/kontakt/kuendigung',
        },
        {
          isExternal: false,
          label: 'Sitemap',
          url: '/weitere-informationen/sitemap',
        },
      ],
    };
  },
};
</script>

<style lang="scss">
  @import '~@/assets/base';

  .footer-corporate {
    color: color(white);
    @include font-size(16px, 27px);
    text-align: center;
    padding-bottom: 3rem;
    display: flex;
    justify-content: space-between;

    @include mq($mq-medium) {
      @include font-size(16px, 16px);
      text-align: left;
    }

    &__list {
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      &-item {
        list-style: none;
        width: 100%;
        text-align: center;

        @include mq($mq-medium) {
          width: auto;
          margin-left: $grid-medium-gutter;
          padding-bottom: 12px;
        }
      }
    }
    &__copyright {
      font-size: inherit;
      line-height: inherit;
      margin-top: 2rem;
      margin-bottom: 2rem;
      white-space: nowrap;

      @include mq($mq-medium) {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
    &__link {
      &:link,
      &:visited,
      &:hover,
      &:active {
        color: inherit;
        text-decoration: none;
      }
    }
  }

</style>

import { render, staticRenderFns } from "./large-screen-primary-navigation.vue?vue&type=template&id=4fcae672&scoped=true&"
import script from "./large-screen-primary-navigation.vue?vue&type=script&lang=js&"
export * from "./large-screen-primary-navigation.vue?vue&type=script&lang=js&"
import style0 from "./large-screen-primary-navigation.vue?vue&type=style&index=0&id=4fcae672&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fcae672",
  null
  
)

export default component.exports
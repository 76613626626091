<template>
  <transition name="fadeLayer">
    <span
      v-show="isActive"
      ref="tooltipLayer"
      :style="layerStyle"
      class="tooltip-layer"
      :class="[
        {
          'tooltip-layer--dark': currentTheme === 'dark',
          'tooltip-layer--light': currentTheme === 'light',
          'tooltip-layer--bottom': position === 'bottom',
        },
        customStyling && `tooltip-layer--${customStyling}`,
      ]"
    >
      <CdnImageElement
        v-if="productImageSrc"
        :alt="productHeading"
        :src="productImageSrc"
        :style="imageStyle"
        image-class="tooltip-layer__product-image"
        render-without-figure
      />
      <span class="tooltip-layer__text-container">
        <Heading
          v-if="productHeading"
          :text="productHeading"
          align="center"
          class="tooltip-layer__text tooltip-layer__text--heading"
          tag="h3"
        />
        <span class="tooltip-layer__text">
          {{ tooltipText }}
          <slot></slot>
        </span>
        <a
          v-if="showTouchLink"
          :data-tealium-rel="tealiumRel"
          :href="`/geraete/${productSlug}`"
          class="tooltip-layer__text"
          tabindex="0"
        >
          mehr Details
        </a>
      </span>
      <span :style="arrowStyle" class="tooltip-layer__arrow" />
    </span>
  </transition>
</template>

<script>
import { Heading } from '@i22-td-smarthome/component-library';
import CdnImageElement from '@/components/cdn-image-element';
import { debounce } from 'lodash';

export default {
  name: 'TooltipLayer',
  components: {
    Heading,
    CdnImageElement,
  },
  props: {
    productSlug: {
      type: String,
      default: '',
    },
    tooltipText: {
      type: String,
      default: '',
    },
    productHeading: {
      type: String,
      default: '',
    },
    productImageSrc: {
      type: String,
      default: '',
    },
    tealiumRel: {
      type: String,
      default: '',
    },
    showTouchLink: {
      type: Boolean,
    },
    theme: {
      type: String,
      default: 'dark',
    },
    position: {
      type: String,
      default: 'top',
      required: false,
    },
    customStyling: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      positionSet: false,
      isActive: false,
      layerStyle: '',
      arrowStyle: '',
      imageStyle: '',
      currentTheme: '',
    };
  },
  mounted() {
    window.addEventListener('resize', debounce(this.resetPositionSet, 200));
    this.currentTheme = this.theme === 'dark' ? 'dark' : 'light';
  },
  beforeDestroy() {
    window.removeEventListener('resize', debounce(this.resetPositionSet, 200));
  },
  methods: {
    routeToDetailPage() {
      this.$emit('routeToDetailPage');
    },
    show(linkBoundings) {
      this.isActive = true;
      this.checkBoundings(linkBoundings);
    },
    hide() {
      this.isActive = false;
    },
    getThemeSettings() {
      const themeSettings = {
        dark: {
          tooltipLayerWidth: 320,
          minGap: 30,
        },
        light: {
          tooltipLayerWidth: 320,
          minGap: 30,
        },
      };

      return themeSettings[this.currentTheme];
    },
    /**
     * TODO:
     * - move tooltip layer & wrapper into component library
     * - use flexible width for tooltip layer
     * - add props to define position of tooltip layer (top, bottom, left, right, default)
     *
     */
    checkBoundings(linkBoundings) {
      // position already set
      if (this.positionSet) return;
      // tooltipLayerWidth
      const tooltipLayerWidth = this.getThemeSettings()?.tooltipLayerWidth;
      const minGap = this.getThemeSettings()?.minGap;
      const tooltipLinkMid = linkBoundings.left + linkBoundings.width / 2;
      const tooltipLayerLeft = tooltipLinkMid - tooltipLayerWidth / 2;
      const tooltipLayerRight = tooltipLinkMid + tooltipLayerWidth / 2;
      let diff;

      if (tooltipLayerLeft - minGap <= 0) {
        // tooltipLayerWidth going into the left browserend
        diff = tooltipLayerLeft - minGap;
      } else if (tooltipLayerRight + minGap >= window.innerWidth) {
        // tooltipLayerWidth going into the right browserend
        diff = tooltipLayerRight - window.innerWidth + minGap;
      }
      this.setPosition(linkBoundings.width, tooltipLayerWidth, diff);
      this.positionSet = true;
    },
    setPosition(linkWidth, layerWidth, diff) {
      if (this.currentTheme !== 'dark') {
        this.imageStyle = `transform: translateX(-50%) translateX(${layerWidth /
          2}px) translateY(-50%)`;
      }

      if (this.position === 'bottom') {
        this.arrowStyle = 'transform: rotate(135deg); top: 3px; right: -13px;';
        this.layerStyle = 'transform: var(--tooltip-bottom-transform, translateY(160%)); right: 0';
      } else if (!diff) {
        this.arrowStyle = `transform: translateX(50%) translateX(${(layerWidth / 2) *
          -1}px) rotate(-45deg)`;
        this.layerStyle = `transform: translateY(-100%) translateX(50%) translateX(${(linkWidth /
          2) *
          -1}px)`;
      } else {
        this.arrowStyle = `transform: translateX(50%) translateX(${(layerWidth / 2) *
          -1}px) translateX(${diff}px) rotate(-45deg)`;
        this.layerStyle = `transform: translateY(-100%) translateX(50%) translateX(${(linkWidth /
          2) *
          -1}px) translateX(${diff * -1}px)`;
      }
    },
    resetPositionSet() {
      this.positionSet = false;
      this.isActive = false;
    },
    clickAwayLayer() {
      if (!this.isActive) return;
      this.hide();
    },
  },
};
</script>
<style lang="scss">
@import 'assets/base';

.fadeLayer-enter-active,
.fadeLayer-leave-active {
  transition: all 500ms;
}

.fadeLayer-enter,
.fadeLayer-leave-to {
  opacity: 0;
}

.tooltip-layer {
  position: absolute;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  z-index: 1;
  overflow: visible;
  display: flex;
  right: 0;
  bottom: auto;
  top: -18px;

  &__arrow {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    transform-origin: 0 0;
    box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.12);
    margin-left: -0.35em;
    bottom: -1.4em;
    right: 0;
    box-sizing: border-box;
  }

  &__text {
    display: inline-block;
    font-size: 15px;
    line-height: 18px;

    &--heading {
      font-size: 18px;
      line-height: 21px;
      font-weight: 700;

      // falls Heading zu lang -> siehe Bitron Video
      // Temperatur Luftfeuchtigkeitssensor
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &__product-image {
    max-width: 90px;
  }

  &--dark {
    padding: 2rem;
    background-color: #383838;
    flex-direction: row;
    align-items: center;
    width: 320px;
    .tooltip-layer__arrow {
      border: 0.74em solid #383838;
    }
    .tooltip-layer__text-container {
      margin-left: 1rem;
    }
    .tooltip-layer__text {
      text-align: left;
      color: #ffffff;
      &--heading {
        margin-bottom: 6px;
      }
    }
  }

  &--light {
    padding: 12px;
    background-color: #f5f5f5;
    flex-direction: column;
    width: max-content;
    max-width: 320px;
    .tooltip-layer__arrow {
      border: 0.7em solid #f5f5f5;
    }
    .tooltip-layer__text {
      text-align: center;
      margin-bottom: 12px;
      &--heading {
        margin-bottom: 12px;
      }
    }
    .tooltip_layer__product-image {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &--bottom {
    .tooltip-layer__text {
      margin-bottom: 0;
      &--heading {
        margin-bottom: 0;
      }
    }
  }

  &--kompaliste {
    padding: $grid-base $grid-base * 2;

    .tooltip-layer__text-container {
      margin-left: 0;
    }

    .tooltip-layer__text {
      @include font-size(18px, 24px);
      text-align: center;
    }

    .tooltip-layer__arrow {
      z-index: -1;
    }

    @include mq(395px, 494px) {
      right: -15px;

      .tooltip-layer__arrow {
        right: 20px;
      }
    }

    &-hub,
    &-connection,
    &-star-info,
    &-warning-info,
    &-ikea-extension,
    &-ikea-connection-info {
      @include mq(false, $mq-lg - 1) {
        padding: 1.4rem;

        .tooltip-layer__text-container {
          margin-left: 0;
        }
      }
    }

    &-hub {
      left: -23.5 * $grid-base;

      @include mq(350px) {
        left: -21 * $grid-base;
      }
      @include mq($mq-lg) {
        left: initial;
      }

      .tooltip-layer__arrow {
        left: 15 * $grid-base;

        @include mq($mq-lg) {
          left: initial;
        }
      }
    }

    &-connection {
      left: -21 * $grid-base;

      @include mq(350px) {
        left: -18 * $grid-base;
      }

      @include mq($mq-lg) {
        left: initial;
      }
      .tooltip-layer__arrow {
        left: 12 * $grid-base;

        @include mq($mq-lg) {
          left: initial;
        }
      }
    }

    &-star-info {
      left: -27.5 * $grid-base;

      .tooltip-layer__arrow {
        right: -1.5 * $grid-base;
      }

      @include mq($mq-lg) {
        left: initial;

        .tooltip-layer__text-container {
          margin-left: 0;
        }

        .tooltip-layer__text {
          @include font-size(18px, 24px);
          text-align: center;
        }

        .tooltip-layer__arrow {
          right: 5px;
        }
      }
    }

    &-warning-info {
      left: -19 * $grid-base;

      @include mq(340px) {
        left: -17 * $grid-base;
      }

      @include mq($mq-sm) {
        left: -15 * $grid-base;
      }

      @include mq($mq-lg) {
        left: initial;
        padding: 1.4rem;

        .tooltip-layer__text-container {
          margin-left: 0;
        }
      }
    }

    &-ikea-extension {
      left: -17 * $grid-base;

      .tooltip-layer__arrow {
        right: 5 * $grid-base;
      }

      @include mq($mq-sm) {
        left: -21 * $grid-base;

        .tooltip-layer__arrow {
          right: 0;
        }
      }

      @include mq($mq-lg) {
        left: initial;
        padding: 1.4rem;

        .tooltip-layer__text-container {
          margin-left: 0;
        }
      }
    }
  }
}
</style>

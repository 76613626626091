<template>
  <div class="layout layout--default" :class="headerThemeClass">
    <PageHeader :theme="headerTheme" />
    <client-only v-if="showMaintenanceDialog">
      <Maintenance />
    </client-only>
    <nuxt v-else />
    <ScrollToTopButton />
    <PageFooter theme="standard" />
    <DialogBackdrop />
    <LoadingSpinner />
    <portal-target name="globals" multiple />
    <template v-if="isDev">
      <client-only>
        <TardisControls />
        <YggdrasilControls />
        <StatusToolbarWrapper />
      </client-only>
    </template>
  </div>
</template>

<script>
import { mode } from '@/lib/client-env';
import DialogBackdrop from '@/components/dialog-backdrop';
import Layout from '@/mixins/layout';
import InitCart from '@/mixins/init-cart';
import LoadingSpinner from '@/components/loading-spinner';
import PageFooter from '@/components/page-footer';
import PageHeader from '@/components/page-header';
import ScrollToTopButton from '@/components/scroll-to-top-button';
import Maintenance from '@/components/maintenance';

const components = {
  DialogBackdrop,
  LoadingSpinner,
  PageFooter,
  PageHeader,
  ScrollToTopButton,
  Maintenance,
};

if (!mode.isProduction) {
  components.TardisControls = () => import('@/components/tardis-controls');
  components.YggdrasilControls = () => import('@/components/yggdrasil-controls');
  components.StatusToolbarWrapper = () => import('@/components/status-toolbar-wrapper');
}

export default {
  name: 'DefaultLayout',
  components,
  mixins: [Layout, InitCart],
  provide() {
    return {
      setSmallHeader: () => {
        this.useSmallHeaderInTemplate = true;
      },
    };
  },
  data() {
    return {
      useSmallHeaderInTemplate: false,
      isDev: !mode.isProduction,
    };
  },
  computed: {
    headerTheme() {
      return this.useSmallHeaderInTemplate ? 'small' : undefined;
    },
    headerThemeClass() {
      return this.useSmallHeaderInTemplate ? 'header-theme-small' : undefined;
    },
  },
  created() {
    this.useSmallHeaderInTemplate = undefined;
  },
};
</script>

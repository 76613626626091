<template>
  <li
    v-on-clickaway="collapse"
    class="mainnav__rootelement"
  >
    <!-- Expandable -->
    <template v-if="expandable">
      <a
        v-if="to"
        ref="link"
        :title="label"
        :href="to"
        :data-tealium-rel="trackingKey"
        class="mainnav__link"
        :class="{
          'mainnav__link--is-active': isActive,
          'mainnav__link--is-expanded': isExpanded,
        }"
        @click="onClickLabel"
        @click.prevent="toggle"
      >
        {{ label }}
        <SvgIcon
          v-show="isAtLeastDesktopScreen"
          :image="arrowDownIcon"
          class="icon mainnav__icon mainnav__icon--subnav"
        />
        <SvgIcon
          v-show="!isAtLeastDesktopScreen"
          :image="arrowRightMenu"
          class="icon mainnav__icon mainnav__icon--link mainnav__icon--mobile"
        />
      </a>
      <span
        v-else
        :data-tealium-rel="trackingKey"
        class="mainnav__link"
        :class="{
          'mainnav__link--is-expanded': isExpanded,
        }"
        @click="onClickLabel"
        @click.prevent="toggle"
      >
        {{ label }}
        <SvgIcon
          v-show="isAtLeastDesktopScreen"
          :image="arrowDownIcon"
          class="icon mainnav__icon mainnav__icon--subnav"
        />
        <SvgIcon
          v-show="!isAtLeastDesktopScreen"
          :image="arrowRightMenu"
          class="icon mainnav__icon mainnav__icon--link mainnav__icon--mobile"
        />
      </span>

      <div
        v-show="isExpanded"
        class="subnav"
      >
        <div
          :class="modifierClass"
          class="subnav__wrapper"
        >
          <slot />
        </div>
      </div>
    </template>

    <!-- External Link -->
    <a
      v-else-if="isExternal"
      :title="label"
      :href="to"
      class="mainnav__link"
      target="_blank"
      rel="noopener"
      :class="{ 'mainnav__link--is-active': isActive }"
      :data-tealium-rel="trackingKey"
    >
      {{ label }}
    </a>

    <!-- Internal Link -->
    <nuxt-link
      v-else
      :title="label"
      :to="to"
      class="mainnav__link"
      active-class="mainnav__link--is-active"
      :class="{ 'mainnav__link--is-active': isActive }"
      :data-tealium-rel="trackingKey"
      exact
      @click.native="closeNexusSearch"
    >
      {{ label }}
    </nuxt-link>
  </li>
</template>

<script>
import { isEqual, isUndefined, startsWith } from 'lodash';
import { mapMutations } from 'vuex';
import { mixin as Clickaway } from 'vue-clickaway2';
import arrowDownIcon from '@/assets/images/arrow-down-main-nav.svg';
import arrowRightMenu from '@/assets/images/arrow-right-menu.svg';
import dasherize from '@/lib/dasherize';
import Escapable from '@/mixins/escapable';
import externalLinkIcon from '@/assets/images/external-link.svg';
import Responsive from '@/mixins/responsive';

const EXTERNAL_URL_PREFIXES = ['http://', 'https://', '//'];

export default {
  name: 'MainNavigationItem',
  expose: ['expand'],
  mixins: [Clickaway, Escapable, Responsive],
  props: {
    label: {
      type: String,
      required: true,
    },
    to: {
      type: [String, Object],
      default: null,
    },
    exact: {
      type: Boolean,
      default: null,
    },
    external: {
      type: Boolean,
      default: undefined,
      required: false,
    },
    active: {
      type: Boolean,
      default: undefined,
    },
    additionalClass: {
      type: String,
      default: '',
      required: false,
    },
    expandable: {
      type: Boolean,
      default: false,
    },
    hasOnlyIcon: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isExpanded: false,
      arrowDownIcon,
      arrowRightMenu,
      externalLinkIcon,
    };
  },
  computed: {
    trackingKey() {
      return `navi-top.text.${dasherize(this.label)}`;
    },
    isExternal() {
      if (!isUndefined(this.external)) return this.external;
      if (!this.to) return false;
      return EXTERNAL_URL_PREFIXES.some((prefix) => startsWith(this.to, prefix));
    },
    targetRoute() {
      if (this.isExternal || !this.$router || !this.$route) return undefined;
      const result = this.$router.resolve(this.to);
      if (!result) return undefined;
      return result.route;
    },
    isActive() {
      if (!isUndefined(this.active)) return this.active;
      if (!this.targetRoute) return false;
      if (this.exact) {
        return this.$route.fullPath === this.targetRoute.fullPath;
      }
      return startsWith(this.$route.path, this.targetRoute.path);
    },
    modifierClass() {
      return `${this.additionalClass}`;
    },
  },
  watch: {
    $route(route, oldRoute) {
      // Collapse the navigation item when changing the route.
      if (!isEqual(route, oldRoute) && this.isExpanded) {
        this.collapse();
      }
    },
  },
  methods: {
    ...mapMutations({
      showBackdrop: 'backdrop/SHOW',
      hideBackdrop: 'backdrop/HIDE',
    }),
    expand() {
      if (!this.expandable) {
        throw new Error('The navigation item is not expandable');
      }
      if (this.isExpanded) return;
      this.isExpanded = true;
      this.$emit('expand');
      this.showBackdrop();
    },
    collapse() {
      if (!this.expandable || !this.isExpanded) return;
      this.isExpanded = false;
      this.$emit('collapse');
      this.hideBackdrop();
    },
    toggle() {
      this.closeNexusSearch();
      if (!this.expandable) return;
      if (this.isExpanded) {
        this.collapse();
      } else {
        this.expand();
      }
      this.$emit('toggle', this.isExpanded);
    },
    collapseAndFocus() {
      if (!this.isExpanded) return;
      this.$refs.link.focus();
      this.collapse();
    },
    onEsc() {
      this.collapseAndFocus();
    },
    onClickLabel() {
      this.$emit('labelClick', this.label);
    },
    closeNexusSearch() {
      if (document.querySelector('.nexus-pk-coin') && getComputedStyle(document.querySelector('.nexus-pk-coin')).display !== 'none') {
        document.querySelector('.nexus-pk-coin__clear-button')?.click();
      }
    },
  },
};

</script>

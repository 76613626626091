<template>
  <div class="large-screen-primary-navigation">
    <ul class="large-screen-primary-navigation__rootlevel mainnav__rootlevel mainnav__rootlevel--is-visible">
      <MainNavigationItem
        label="Smart Home"
        to="/"
        exact
        class="large-screen-primary-navigation__item--home"
      />

      <MainNavigationItem
        label="Shop"
        to="/shop"
        :expandable="true"
        :additional-class="'subnav__shop'"
      >
        <ShopNavbar />
      </MainNavigationItem>

      <MainNavigationItem
        label="MagentaZuhause App"
        to="/magentazuhause-app"
        exact
      />

      <MainNavigationItem
        label="Hilfe"
        to="/hilfe"
        exact
      />
    </ul>
  </div>
</template>

<script>
import MainNavigationItem from '@/components/main-navigation-item';
import ShopNavbar from '@/components/shop-navbar';

export default {
  name: 'LargeScreenPrimaryNavigation',
  expose: ['openSubNav'],
  components: {
    MainNavigationItem,
    ShopNavbar,
  },
  methods: {
    openSubNav(subCategory) {
      const navItem = this.$children.find((nav) => nav.label === subCategory);
      if (navItem) {
        navItem.expand();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'assets/base';

.large-screen-primary-navigation {
  &__rootlevel {
    @include mq($mq-md) {
      align-items: center;
    }
  }
}
</style>

import Cookies from 'js-cookie';
import { captureException } from '@/plugins/sentry';

const DEFAULT_PAYMENT_TYPE = 'creditcard';
const ALLOWED_EXPRESS_PAYMENT_TYPES = ['paypal_express'];
const WHITELISTED_EXPRESS_ROUTES = ['/shop/checkout/warenkorb', '/shop/checkout/zusammenfassung'];

export default async ({ app, store }) => {
  app.router.afterEach(async (to, from, failure) => {
    if (failure) {
      return;
    }
    // Only execute guard when we are not on the defined express routes
    if (WHITELISTED_EXPRESS_ROUTES.includes(to.path)) {
      return;
    }
    const cartData = store.getters['cart/cartData'];
    // Only execute guard when payment_type of order matches express payment types
    if (!cartData || !ALLOWED_EXPRESS_PAYMENT_TYPES.includes(cartData?.paymentType)) {
      return;
    }

    const cartItems = [...store.getters['cart/cartItems']];
    try {
      // Try to reset payment type - will only work with valid customer data
      await store.dispatch('cart/updateCustomerData', {
        ...cartData,
        emailConfirmation: cartData.email,
        paymentPageUrl: null,
        paymentShopFailureUrl: null,
        paymentShopSuccessUrl: null,
        paymentType: DEFAULT_PAYMENT_TYPE,
      });

      return;
      // eslint-disable-next-line no-empty
    } catch (e) {}

    try {
      // Delete previous express order and create new one with cart items only
      await store.dispatch('cart/clearCart');
      await store.dispatch('cart/addItemsToCart', {
        items: cartItems,
        salesOrganizationID: Cookies.get('TK_VO'),
      });
    } catch (e) {
      captureException(e);
    }
  });
};

import fetchProductCategories from '@/lib/goliath/product-categories';
import { captureMessage } from '@/plugins/sentry';

export const state = () => ({
  productCategories: [],
});

export const mutations = {
  SET_PRODUCT_CATEGORIES(state, productCategories) {
    state.productCategories = productCategories;
  },
};

export const getters = {
  getCategories(state) {
    return state.productCategories;
  },
};

export const actions = {
  fetchCategories({ commit }) {
    // used in `nuxtServerInit` and has to be a promise (https://nuxtjs.org/docs/directory-structure/store/);
    return fetchProductCategories().then(({ entries }) => {
      if (!entries) return;
      commit('SET_PRODUCT_CATEGORIES', entries.map((e) => e.productCategory));
    }).catch((error) => {
      captureMessage('product categories could not be loaded', {
        code: error.code, message: error.message,
      });
    });
  },
};

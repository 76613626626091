<template>
  <image-element
    :id="id"
    :src="finalSrc.base"
    :src-sm="finalSrc.sm"
    :src-md="finalSrc.md"
    :src-lg="finalSrc.lg"
    :src-xl="finalSrc.xl"
    :image-class="imageClass"
    :picture-class="pictureClass"
    :alt="alt"
    :height="height"
    :width="width"
    :render-without-figure="renderWithoutFigure"
    :align="align"
    :spaces="spaces"
    :on-error="onError"
    :lazy="lazy"
  >
    <slot />
  </image-element>
</template>

<script>
import assetUrl from '@/lib/cdn-helper';
import { ImageElement } from '@i22-td-smarthome/component-library';

export default {
  name: 'CdnImageElement',
  components: {
    ImageElement,
  },
  props: {
    src: { type: [String, Array], required: true },
    srcSm: { type: [String, Array], default: null },
    srcMd: { type: [String, Array], default: null },
    srcLg: { type: [String, Array], default: null },
    srcXl: { type: [String, Array], default: null },
    imageClass: { type: String, default: '' },
    pictureClass: { type: String, default: '' },
    alt: { type: String, required: true },
    id: { type: String, default: null },
    height: { type: Number, default: null },
    width: { type: Number, default: null },
    renderWithoutFigure: { type: Boolean },
    noCdn: { type: Boolean },
    align: {
      type: String,
      default: null,
      validator(value) {
        return ['center', 'right', 'left'].includes(value);
      },
    },
    spaces: { type: Boolean },
    onError: { type: Function, default: undefined },
    lazy: {
      type: Boolean,
    },
  },
  computed: {
    finalSrc() {
      const [base, sm, md, lg, xl] = [
        this.src,
        this.srcSm,
        this.srcMd,
        this.srcLg,
        this.srcXl,
      ].map((path) => (this.noCdn ? path : assetUrl(path)));
      return { base, sm, md, lg, xl };
    },
  },
};
</script>

<style lang="scss" scoped>
</style>

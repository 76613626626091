<template>
  <div
    :class="containerClass"
  >
    <nuxt-link
      :data-tealium-rel="dataTealium"
      :to="pageTo"
      :class="classes"
    >
      {{ label }}
    </nuxt-link>
  </div>
</template>

<script>
import Inflections from '@/mixins/inflections';
import dasherize from '@/lib/dasherize';

export default {
  name: 'BlockLink',
  mixins: [
    Inflections,
  ],
  props: {
    buttonStyle: {
      type: String,
      default: '',
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    pageTo: {
      type: String,
      default: '',
    },
    align: {
      type: String,
      default: '',
    },
  },
  computed: {
    classes() {
      const cls = [];
      if (this.buttonStyle) {
        cls.push('btn');
        cls.push(this.fullWidth ? 'btn--block' : 'btn--inline');
        cls.push(`btn-${this.buttonStyle}`);
      } else {
        cls.push('link');
      }
      return cls;
    },
    linkType() {
      const type = [];
      if (this.buttonStyle) {
        type.push('button');
      } else {
        type.push('text');
      }
      return type;
    },
    containerClass() {
      const modifier = [];
      if (this.align) {
        modifier.push(`text--${this.align}`);
      }
      return modifier;
    },
    dataTealium() {
      const dasherizedText = dasherize(this.label);
      return `content.${this.linkType}.${dasherizedText}`;
    },
  },
};

</script>

<template>
  <FinancingInfo
    :price="price"
    :duration-in-month="durationInMonth"
  />
</template>

<script>
import { FinancingInfo } from '@i22-td-smarthome/component-library';

export default {
  name: 'FinancingInfoContainer',
  components: {
    FinancingInfo,
  },
  props: {
    price: { type: Number, required: true },
    durationInMonth: { type: Number, default: 12 },
  },
};
</script>

<style>
</style>

<template>
  <div
    class="grid__item grid__item--12 grid__item--l-4 footer-accordion"
    :class="[toggleOpen, accordionModifier]"
  >
    <p
      class="footer-accordion__headline pb--1"
      :data-tealium-rel="`footer.accordion.${headline}` | dasherize"
      @click="toggleContent"
    >
      {{ headline }}
      <svg-icon
        :image="arrowDown"
        class="icon footer-accordion__arrow-icon"
      />
    </p>
    <div
      class="footer-accordion__content"
      :class="{'footer-accordion__content--is-hidden': isHidden}"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
import arrowDown from '@/assets/images/arrow-down-dropdown.svg';
import Inflections from '@/mixins/inflections';
import TrackAction from '@/mixins/track-action';

export default {
  name: 'FooterAccordion',
  mixins: [
    Inflections,
    TrackAction,
  ],
  props: {
    headline: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      arrowDown,
      isHidden: true,
    };
  },
  computed: {
    toggleOpen() {
      return { 'foorter-accordion--open': !this.isHidden };
    },
    accordionModifier() {
      return `footer-accordion--${this.headline.toLowerCase()}`;
    },
  },
  methods: {
    toggleContent(event) {
      this.trackAction(event.target.dataset.tealiumRel);
      this.isHidden = !this.isHidden;
    },
  },
};

</script>

<style lang="scss">
  @import 'assets/base';

  .footer-accordion {
    border-bottom: 1px solid rgba(color(grey, 18000), .3);
    &:first-child {
      border-top: 1px solid rgba(color(grey, 18000), .3);
      @include mq($mq-large) {
        border-top: none;
      }
    }
    color: $color-secondary;

    @include mq($mq-large) {
      border: 0;
      margin-bottom: 20px;
    }
    &--open {
      .footer-accordion__arrow-icon {
        transform: rotate(180deg) translateY(50%);
      }
    }
    &__headline {
      @include font-size(18px, 24px);
      font-weight: 700;
      color: $color-secondary;
      margin: 0;
      position: relative;
      text-transform: uppercase;
      @include mq($mq-large) {
        @include font-size(16px, 18px);
      }
      @include mq(false, $mq-large - 1) {
        cursor: pointer;
        font-weight: 400;
        text-align: left;
        text-transform: none;
        padding: $grid-base*1.5 0;
      }
    }

    &__arrow-icon {
      position: absolute;
      right: 5px;
      top: 50%;
      color: $color-primary;
      transform: translateY(-50%);
      transition: all ease 500ms;
      @include mq($mq-large) {
        display: none;
      }
    }

    &__content {
      margin-bottom: calculate-rem(20px);
      margin-top: calculate-rem(20px);

      &--is-hidden {
        display: none;

        @include mq($mq-large) {
          display: block;
        }
      }
    }

    &__text {
      color: $color-secondary;
      @include font-size(16px, 27px);
      &--underline,
      &--underline{
        text-decoration: underline;
        color: color(white);
        &:visited {
          color: color(white);
        }
      }
    }

    &__list {
      @include font-size(16px, 27px);
      display: inline-block;
      margin-bottom: $grid-medium-gutter;
      // Override
      .list__element {
        padding-bottom: 10px;
      }
    }

    &__image {
      &--left {
        float: left;
        margin-right: $grid-medium-gutter;
        margin-bottom: calculate-rem(10px);
      }
    }

    &__icon {
      height: 4.5em;
      margin-bottom: calculate-rem(10px);
      margin-right: calculate-rem(20px);
      width: 4.5em;
      float: left;
    }

    &--zahlungsarten {
      @include mq($mq-md) {
        max-width: 66%;
        flex-basis: 66%;
      }
    }
  }

</style>

import { clientWithLoadingIndicator as axios } from '@/lib/axios-client/goliath';

export default async function fetchProductCategories() {
  const { data } = await axios.post('/api/goliath/product-categories');
  if (data.errors && data.errors.length) {
    const message = data.errors.map((error) => error.message).join(', ');
    throw new Error(message);
  }
  return data.data.productCategories;
}

<template>
  <div v-if="options.lines && options.lines.length > 0" class="generic-dialog">
    <div
      v-for="(line, index) in options.lines"
      :key="line.text || index"
      class="generic-dialog__line"
      :style="{
        'padding-bottom': line.paddingBottom,
      }"
    >
      <span
        class="generic-dialog__text"
        :class="{
          'generic-dialog__text--hint': line.type === 'hint',
        }"
        :style="{
          'text-align': line.textAlign || 'start',
        }"
      >
        <SvgIcon
          v-if="line.icon && line.icon.component"
          :image="line.icon.component"
          :style="[
            iconSlotStyle[line.icon.slot || 'inline'],
            {
              width: line.icon.size,
              height: line.icon.size,
            },
          ]"
          class="icon generic-dialog__icon"
          :class="{
            'dialog-container__icon--border': line.icon.border,
          }"
        />
        <template v-if="line.isHtml"><span v-html="line.text"></span></template>
        <template v-else>{{ line.text }}</template>
      </span>
      <button
        v-if="line.button"
        :key="line.button.ref || index"
        :ref="line.button.ref"
        class="btn generic-dialog__btn"
        :class="line.button.class"
        @click.prevent="handleButtonClick(line.button)"
      >
        {{ line.button.label }}
      </button>
    </div>
  </div>
</template>

<script>
import DialogContent from '@/mixins/dialog-content';

/**
 * This Component allows to build simple generic dialogs containing text, icons and buttons
 * @property {object} options                         - The DialogContent options object.
 * @property {DialogContentLine[]} options.lines      - Array of lines to be shown in the dialog content.
 *
 * @typedef {Object} DialogContentLine
 * @property {string=} text                 - Text to be rendered for this line.
 * @property {string=} type                 - Can be set to 'hint' to render a smaller text with grey color.
 * @property {string=start} textAlign       - Textalignment: 'start', 'center', 'end'.
 * @property {string=} paddingBottom        - Padding Bottom style as string, e.g. '12px'
 * @property {DialogContentIcon=} icon      - An icon to be rendered.
 * @property {DialogContentButton=} button  - A button to be rendered for this line. Will be rendered after the text if set.
 *
 * @typedef {Object} DialogContentIcon
 * @property {string} name        - Name of an Icon to be rendered. The Icon will be resolved by `@/assets/images/{{icon}}.svg`.
 * @property {string=inline} slot - Controls where the icon will be rendered. 'start' will place the icon absolute before the text.
 * @property {string=} size       - The size of the icon, e.g. 12px.
 * @property {boolean=} border    - Wether the icon should have a border.
 *
 * @typedef {Object} DialogContentButton
 * @property {string} label       - The button label.
 * @property {string=} class      - A html class string to define additional class names added to the button, e.g. `btn--primary`.
 * @property {string=} ref        - A ref to be used.
 * @property {boolean=} focus     - Wether the button should be on focus when the dialog is shown. Required ref to be set.
 * @property {boolean=} close     - Wether the button should close the dialog on interaction.
 * @property {function=} handler  - A callback function to be called on interaction.
 */
export default {
  name: 'GenericDialogContent',
  mixins: [
    DialogContent({
      contractNumber: null,
    }),
  ],
  data() {
    return {
      iconSlotStyle: {
        inline: {
          /* Default Style */
        },
        start: { position: 'absolute', transform: 'translateX(-22px) translateY(4px)' },
        end: {
          /* ToDo */
        },
      },
    };
  },
  async beforeMount() {
    await this.loadIcons();
  },
  mounted() {
    this.$nextTick(() => {
      const { button } = this.options.lines.find((line) => line.button?.ref && line.button?.focus) || {};
      if (this.$refs[button?.ref]?.length > 0) this.$refs[button?.ref][0].focus();
    });
  },
  methods: {
    async handleButtonClick(button) {
      if (button.handler) {
        await button.handler();
      }
      if (button.close) {
        this.close();
      }
    },
    /**
     * Looks for lines with an icon name defined.
     * Imports SVG icons and registers them as a component.
     * Forces an update to load newly added components.
     */
    async loadIcons() {
      try {
        if (this.options.lines?.length > 0) {
          // eslint-disable-next-line no-restricted-syntax
          for (const line of this.options.lines) {
            if (line.icon?.name) {
              // eslint-disable-next-line no-await-in-loop
              const component = await import(`@/assets/images/${line.icon.name}.svg`);

              if (this.components) {
                this.components.push(component.default);
              } else {
                this.components = [component.default];
              }
              line.icon.component = component.default;
              this.$forceUpdate();
            }
          }
        }
      } catch (err) {
        this.$sentry.withScope((sentry) => {
          sentry.setLevel('warning');
          sentry.addBreadcrumb('Trying to load icon in <GenericDialogContent>');
          sentry.captureException(err);
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import '../assets/base';

.generic-dialog {
  &__line:first-of-type > &__text {
    // line break for close button
    padding-right: 30px;
    padding-left: 20px;
  }

  &__text b {
    white-space: nowrap;
  }

  &__text > .nowrap {
    white-space: nowrap;
  }

  &__text--hint {
    font-size: 15px;
    color: color(grey, 16000);
  }

  &__line {
    display: flex;
    flex-direction: column;
  }

  &__icon {
    padding: 2px;
    margin-right: 5px;
    width: 16px;
    height: 16px;
    transform: translateY(2px);

    &--border {
      border: 1px solid #000;
    }
  }

  &__btn {
    margin: 12px;
  }
}
</style>

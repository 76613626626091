<template>
  <div
    :class="{'mainnav--goback': showBackButton}"
    class="mainnav__info"
  >
    <div
      v-if="!showActiveState"
      class="mainnav__info--menu"
    >
      <button
        class="mainnav__button mainnav__button--toggle"
        data-tealium-rel="navi-top.button.zeige-navigation"
        @click="toggleSmallScreenNav"
      >
        <SvgIcon
          class="icon mainnav__icon--open"
          :image="menu"
        />
        Menü
      </button>
    </div>

    <div
      v-if="showActiveState"
      class="mainnav__info--menu"
    >
      <button
        v-if="showBackButton"
        class="mainnav__button mainnav__button--toggle"
        data-tealium-rel="navi-top.button.small-screen-navigation-back"
        @click="collapse"
      >
        <SvgIcon
          class="icon mainnav__icon--close"
          :image="arrowLeftMenu"
        />
      </button>
    </div>

    <template v-if="showActiveState">
      <div class="mainnav__info--title">
        <span>{{ navigationTitle }}</span>
      </div>

      <div class="mainnav__info--close">
        <button
          class="mainnav__button mainnav__button--close"
          data-tealium-rel="navi-top.button-schliesse-navigation"
          @click="closeSmallScreenNav"
        >
          <SvgIcon
            class="icon mainnav__icon--close"
            :image="menuClose"
          />
        </button>
      </div>
    </template>
  </div>
</template>

<script>
import arrowLeftMenu from '@/assets/images/arrow-left-menu.svg';
import menu from '@/assets/images/menu.svg';
import menuClose from '@/assets/images/menu-close.svg';

export default {
  name: 'SmallScreenNavigationHead',
  props: {
    actualDepth: {
      type: Number,
      required: true,
    },
    showBackButton: {
      type: Boolean,
      required: true,
    },
    navigationTitle: {
      type: String,
      required: true,
    },
    showActiveState: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      arrowLeftMenu,
      menu,
      menuClose,
      subNavListVisible: true,
    };
  },
  methods: {
    closeSmallScreenNav() {
      this.$emit('closeSmallScreenNav');
    },
    toggleSmallScreenNav() {
      this.$emit('smallScreenNavToggled');
    },
    collapse() {
      this.$emit('collapseSubNavigation');
    },
  },
};

</script>

import { mode } from '@/lib/client-env';

import Vuex from 'vuex';
import cart from './cart';
import serverDate from './server-date';
import tardis from './tardis';
import downtimes from './downtimes';

import * as backdrop from './backdrop';
import * as loader from './loader';
import * as lastSeenOffers from './last-seen-offers';
import * as navigation from './navigation';
import * as viewport from './viewport';
import * as order from './order';

export const modules = {
  cart,
  downtimes,
  serverDate,
  // When all stores are namespaced, this file becomes obsolete. Until then, support new syntax in old format.
  backdrop: {
    namespaced: true,
    ...backdrop,
  },
  lastSeenOffers: {
    namespaced: true,
    ...lastSeenOffers,
  },
  loader: {
    namespaced: true,
    ...loader,
  },
  navigation: {
    namespaced: true,
    ...navigation,
  },
  viewport: {
    namespaced: true,
    ...viewport,
  },
  order: {
    namespaced: true,
    ...order,
  },
};

if (!mode.isProduction) {
  modules.tardis = tardis;
}

export default () =>
  new Vuex.Store({
    modules,
    actions: {
      async nuxtServerInit({ commit, dispatch }, { query }) {
        if (query.token) {
          commit('cart/SET_ORDER_TOKEN', query.token);
        }
        commit('serverDate/SET_SERVER_DATE');
        await dispatch('navigation/fetchCategories');
      },
    },
  });

<template>
  <div class="shop-navbar" :class="isMobileClass">
    <div class="shop-navbar__content-wrapper">
      <div class="shop-navbar__main-categories-wrapper">
        <NavCategory v-for="(category, index) in mainCategories" :key="`mainCategories-${index}`" :category="category" :main-category="true" />
      </div>
      <NavCategory v-for="(category, index) in categories" :key="`smallCategories-${index}`" :category="category" />
    </div>
  </div>
</template>

<script>
import alleGeraeteIcon from '@/assets/images/alle-geraete.svg';
import topThemenAngeboteIcon from '@/assets/images/top-themen-angebote.svg';
import NavCategory from '@/components/nav-category';
import { mapGetters } from 'vuex';

export default {
  name: 'ShopNavbar',
  components: { NavCategory },
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mainCategories: [{ icon: alleGeraeteIcon, name: 'Alle Geräte anschauen', path: 'shop' }, { icon: topThemenAngeboteIcon, name: 'Angebote & Top-Themen', path: 'shop-start' }],
      categories: null,
    };
  },
  computed: {
    ...mapGetters('navigation', ['getCategories']),
    isMobileClass() {
      return {
        'shop-navbar--mobile': this.isMobile,
      };
    },
  },
  mounted() {
    this.categories = [...this.getCategories.sort((a, b) => a.name.localeCompare(b.name, 'de', { sensitivity: 'base' }))];

    this.categories.forEach((category) => {
      import(`@/assets/images/${category.path}.svg`).then((icon) => {
        this.$set(category, 'icon', icon.default);
      }).catch(() => {
        console.error(`Couldn't import icon for ${category.path}`);
      });
    });
  },
};
</script>

<style lang="scss" scoped>
  @import '../assets/base';

  .shop-navbar {
    width: 100%;

    &__content-wrapper {
      display: flex;
      row-gap: 10px;
      flex-wrap: wrap;
      justify-content: space-between;

      @include mq($mq-sm) {
        flex-direction: column;
        align-items: center;
        column-gap: 10px;
        height: 510px;
        justify-content: flex-start;
      }

      @include mq($mq-md) {
      height: 420px;
      gap: 0;
    }

      @include mq($mq-xl) {
        height: 315px;
      }
    }

    &__main-categories-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;

      @include mq($mq-sm) {
        width: 32%;
      }

      @include mq($mq-md) {
        height: 100%;
        width: 25%;
      }
    }

    &--mobile {
      overflow: auto;
      max-height: calc(100vh - var(--branding-height) - var(--navbar-height));
      -webkit-overflow-scrolling: touch;
      position: absolute;
      top: var(--navbar-height);
      background: color(white);
      padding: $grid-base;
    }
  }
</style>

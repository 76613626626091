<template>
  <span
    :class="`count-badge--${theme}`"
    class="count-badge"
  >
    {{ value }}
  </span>
</template>

<script>
export default {
  name: 'CountBadge',
  props: {
    value: {
      type: Number,
      required: true,
    },
    theme: {
      type: String,
      default: 'primary',
      validator(value) {
        return ['primary', 'light', 'default'].includes(value);
      },
    },
  },
};

</script>

<style lang="scss" scoped>
@import '../assets/base';

.count-badge {
  font-size: 15px;
  font-size: calculate-rem(15px);
  background-color: color(blue, 3000);
  color: color(white);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  border-radius: 3px;
  font-weight: 900;
  &--light {
    background-color:  color(white);
    color: $color-primary;
  }
}
</style>

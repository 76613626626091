import { track } from './track';

/**
 * @typedef {object} PartialTealiumTag
 * @property {string} linkTrack Sets which type of link tracking is used. Known values: 'standard', 'link'
 * @property {RegExp} linkTrackIgnorePattern Regular expression that is used to ignore certain links
 * @property {string} linkTrackAttribute HTML Element attribute that contains the content id (e.g. 'data-tealium-rel')
 */

/**
 * Time in milliseconds to wait all changes to the DOM to be applied before registering event listeners.
 * This is necessary because some Vue Child Components are not in their final state when `mounted` is called in ./mixins/page-tracking.js
 */
const DOM_SETTLE_TIME = 300;

/**
 * Registers an tealium event listener on the given element, if it has not been registered before.
 *
 * @param {HTMLElement} element The element to register the event listener on
 * @param {String} contentId The content id that is used for the event
 */
const registerTealiumEventListener = (element, contentId) => {
  const alreadyRegisredFlag = 'isAddedToTealium';
  if (element[alreadyRegisredFlag]) return;

  element.addEventListener('click', (event) => {
    const isMainMouseButton = event.button === 0;
    if (!isMainMouseButton) return;
    track('link', { wt_link_id: contentId });
  });

  // eslint-disable-next-line no-param-reassign
  element[alreadyRegisredFlag] = true;
};

/**
 * Checks if the given link should be tracked.
 *
 * @param {RegExp} linkTrackIgnorePattern Regular expression that is used to ignore certain links
 * @param {String} href The href attribute of the link
 * @return {Boolean} True if the link should be tracked, false otherwise
 */
function shouldTrackLink(linkTrackIgnorePattern, href, contentId, linkTrack) {
  return (
    (!linkTrackIgnorePattern || !href?.match(linkTrackIgnorePattern)) &&
    (contentId || linkTrack === 'link')
  );
}



/**
 * Looks for all link elements and registers event listeners for them.
 *
 * This function is called on every page load and on every route change, because Tealium's native link tracking does not register event listeners on Client Side Navigation.
 */
// eslint-disable-next-line import/prefer-default-export
export const updateAutomaticLinkTracking = () => {
  window.setTimeout(() => {
    const tealiumTag = window.wtm;
    if (!tealiumTag) return;

    /** @type {PartialTealiumTag} */
    const { linkTrack, linkTrackIgnorePattern, linkTrackAttribute } = tealiumTag;

    const isTrackingEnabled = linkTrack && ['standard', 'link'].includes(linkTrack);
    if (!isTrackingEnabled) return;

    document.querySelectorAll('a, button').forEach((element) => {
      const href = element.getAttribute('href');
      const contentId = element.getAttribute(linkTrackAttribute);
      if (shouldTrackLink(linkTrackIgnorePattern, href, contentId, linkTrack)) {
        registerTealiumEventListener(element, contentId);
      }
    });
  }, DOM_SETTLE_TIME);
};

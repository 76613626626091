import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      initCartTimeout: undefined,
    };
  },
  computed: {
    ...mapGetters('cart', ['orderToken', 'cartInitialized']),
  },
  async mounted() {
    if (this.orderToken) {
      await this.fetchCartWithOrderToken();
    } else {
      await this.initCart(); // has to be called everytime -> shipping costs are in footer
    }
  },
  destroyed() {
    if (typeof window === 'undefined') return;
    window.removeEventListener('consentChanged', this.fetchCurrentCart);
  },
  methods: {
    ...mapActions('cart', ['initCart', 'fetchCart']),
    /**
     * fetchCartWithOrderToken
     * If an orderToken is set, we would fetch the cart and set the session for this order.
     * But when we don't yet have a cookie consent, wen cannot activate the session.
     * For this reason, we wait until the cookie consent has been set and then fetch the cart to activate the session.
     */
    async fetchCartWithOrderToken() {
      if (typeof window === 'undefined') {
        return;
      }

      const isConsentPending = window.utag?.gdpr?.getConsentState() === 0; // -1 = only required cookies allowed, 0 = pending, 1 = all cookies allowed
      if (isConsentPending) {
        // We set a timeout here for the case the user does not see the consent banner because of an ad blocker
        this.initCartTimeout = setTimeout(this.fetchCurrentCart, 5000);
        window.addEventListener('consentChanged', this.fetchCurrentCart);
      } else {
        // Consent has been set, fetch cart with order token
        await this.fetchCurrentCart();
      }
    },
    async fetchCurrentCart() {
      if (this.initCartTimeout) {
        clearTimeout(this.initCartTimeout);
      }

      if (this.cartInitialized) {
        await this.fetchCart();
      } else {
        await this.initCart();
      }
    },
  },
};

import { buildPlainClient } from './factory';

function buildShopApiClient() {
  return buildPlainClient({
    headers: {
      Accept: 'application/vnd.td.smarthome.v1+json',
      'Content-Type': 'application/json',
    },
  });
}

export const clientWithLoadingIndicator = buildShopApiClient();
export const plainClient = buildShopApiClient();

export default clientWithLoadingIndicator;

module.exports = function dasherize(str) {
  // @see https://github.com/vikramcse/string-dasherize/blob/master/index.js
  return str.trim()
    .replace(/([A-Z])/g, '-$1')
    .replace(/[-_\s]+/g, '-')
    .toLowerCase()
    .replace(/ä/g, 'ae')
    .replace(/ö/g, 'oe')
    .replace(/ü/g, 'ue')
    .replace(/ß/g, 'ss')
    .replace('&', 'und')
    .replace('+', 'plus')
    .replace(/^-/g, '') // remove leading `-`
    .replace(/\.-/g, '.'); // replace `.-` with `.`
};


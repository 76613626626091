var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.options.lines && _vm.options.lines.length > 0)?_c('div',{staticClass:"generic-dialog"},_vm._l((_vm.options.lines),function(line,index){return _c('div',{key:line.text || index,staticClass:"generic-dialog__line",style:({
      'padding-bottom': line.paddingBottom,
    })},[_c('span',{staticClass:"generic-dialog__text",class:{
        'generic-dialog__text--hint': line.type === 'hint',
      },style:({
        'text-align': line.textAlign || 'start',
      })},[(line.icon && line.icon.component)?_c('SvgIcon',{staticClass:"icon generic-dialog__icon",class:{
          'dialog-container__icon--border': line.icon.border,
        },style:([
          _vm.iconSlotStyle[line.icon.slot || 'inline'],
          {
            width: line.icon.size,
            height: line.icon.size,
          },
        ]),attrs:{"image":line.icon.component}}):_vm._e(),_vm._v(" "),(line.isHtml)?[_c('span',{domProps:{"innerHTML":_vm._s(line.text)}})]:[_vm._v(_vm._s(line.text))]],2),_vm._v(" "),(line.button)?_c('button',{key:line.button.ref || index,ref:line.button.ref,refInFor:true,staticClass:"btn generic-dialog__btn",class:line.button.class,on:{"click":function($event){$event.preventDefault();return _vm.handleButtonClick(line.button)}}},[_vm._v("\n      "+_vm._s(line.button.label)+"\n    ")]):_vm._e()])}),0):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <portal to="globals">
    <div
      v-if="isOpened"
      :class="[containerClass, customPosition ? 'modal__custom-position': '']"
      class="modal"
    >
      <div
        v-on-clickaway="onClickaway"
        class="modal__inner"
        :class="[innerClass, customPosition ? 'modal__inner__custom-position': '']"
        :style="{
          ...customPosition,
          'animation-name': animation
        }"
      >
        <button
          ref="closeButton"
          type="button"
          class="modal__close"
          @click.stop="close"
        >
          <SvgIcon
            :image="closeIcon"
            class="modal__close-icon"
          />
        </button>

        <slot />
        <LinkButton
          v-if="extraButton"
          tag="button"
          type="button"
          class="modal__extra-button"
          transparent
          @click.native="close"
        >
          {{ buttonText }}
        </LinkButton>
      </div>
    </div>
  </portal>
</template>

<script>
import { mapMutations } from 'vuex';
import closeIcon from '@/assets/images/menu-close.svg';
import Dismissable from '@/mixins/dismissable';
import Tealium from '@/lib/tealium';
import {
  LinkButton,
} from '@i22-td-smarthome/component-library';


export default {
  name: 'Modal',
  components: {
    LinkButton,
  },
  mixins: [
    Dismissable,
  ],
  props: {
    size: {
      type: String,
      default: null,
    },
    customClass: {
      type: String,
      default: null,
    },
    tealiumClose: {
      type: String,
      default: 'modal.button.close',
    },
    extraButton: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      default: '',
    },
    innerClass: {
      type: String,
      default: null,
    },
    backdrop: {
      type: Boolean,
      default: true,
    },
    customPosition: {
      type: Object,
      default: null,
    },
    animation: {
      type: String,
      default: 'modal-open',
    },
  },
  data() {
    return {
      closeIcon,
    };
  },
  computed: {
    containerClass() {
      return {
        [`modal--${this.size}`]: !!this.size,
        [`${this.customClass}`]: !!this.customClass,
      };
    },
  },
  destroyed() {
    if (this.isOpened) {
      this.hideBackdrop({ global: true });
    }
  },
  methods: {
    ...mapMutations({
      showBackdrop: 'backdrop/SHOW',
      hideBackdrop: 'backdrop/HIDE',
    }),
    async onOpen(dataTealiumRel) {
      // look into openable mixin
      // @click.prevent="$refs.legalTextModal.open(contract.slug)"
      if (this.backdrop) {
        this.showBackdrop({ global: true });
      }
      await this.$nextTick();
      const { closeButton } = this.$refs;
      if (closeButton) closeButton.focus();

      // for adding tracking to modal in this case:
      // dataTealiumRel = contract.slug = for example "magenta-smarthome"
      // for the magenta-smarthome tariffmodal
      if (dataTealiumRel && typeof dataTealiumRel === 'string') {
        await Tealium.link({
          wt_link_id: `content.modal.${dataTealiumRel}`,
        });
      }
    },
    onClose() {
      Tealium.link({
        wt_link_id: this.tealiumClose,
      });
      if (this.backdrop) {
        this.hideBackdrop({ global: true });
      }
    },
  },
};
</script>

<style lang="scss">
  @import '../assets/base';

  @keyframes modal-open {
    from {
      opacity: 0;
      transform: translateY(-5%) scale(0.75);
    }
    to {
      opacity: 1;
      transform: translateY(0) scale(1);
    }
  }

  @keyframes reveal-right {
    from {
      clip-path: inset(0 0 0 100%);
    }
    to {
      clip-path: inset(0 0 0 0);
    }
  }

  @keyframes reveal-left {
    from {
      clip-path: inset(0 100% 0 0);
    }
    to {
      clip-path: inset(0 0 0 0);
    }
  }

  .modal {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    @include mq($mq-large) {
      padding: $grid-medium-gutter 0;
    }

    &__custom-position {
      display: unset;
      align-items: unset;
    }

    &__inner {
      position: relative;
      min-width: 300px;
      max-width: 700px;
      width: 100%;
      margin: auto;
      padding: 35px 24px 24px;
      background-color: color(white);
      box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
      animation: {
        name: modal-open;
        duration: 200ms;
        timing-function: ease-out;
        iteration-count: 1;
      }
      transform-origin: top center;
      overflow: hidden;
      @include border-radius;

      &__custom-position {
        align-items: unset;
        margin: 0;
      }
    }

    &--large .modal__inner {
      max-width: 1145px;
      min-height: 720px;
      padding: 40px 40px 35px;
    }

    &--wide .modal__inner, &--awardModal .modal__inner {
      padding: 40px 40px 35px;
    }

    &--awardModal .modal__inner {
      max-width: 900px;
    }

    &__close {
      position: absolute;
      text-decoration: none;
      right: 7px;
      top: 7px;
      padding: 0;
      width: 40px;
      height: 40px;
      cursor: pointer;
      background: none;
      border: none;
      color: color(grey, 16000);
      outline: none;

      &:hover, &:focus {
        color: $color-font;
      }
    }

    &__close-icon {
      height: 24px;
      width: 18px;
    }

    .app--has-vertical-scrollbar & {
      overflow-y: scroll;
    }

    &__extra-button {
      display: block;
      margin: $grid-base auto 0;
    }
  }

</style>

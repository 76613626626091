<template>
  <main>
    <!-- 404 -->
    <ImageTextError
      v-if="isNotFoundError"
      :grid-size="6"
      :error-data="notFoundErrorData"
      class="error error--404"
    >
      <template #content>
        <h1>Entschuldigung</h1>
        <p>
          Hier haben wir uns wohl in den Einstellungen vertan – oder Sie
          haben eine Seite aufgerufen, die nicht mehr existiert. Während wir
          im Hintergrund nach einem möglichen Fehler suchen schauen Sie doch
          einfach auf der
          <nuxt-link
            to="/"
            class="link"
            data-tealium-rel="content.text.startseite"
          >Startseite</nuxt-link>
          vorbei und lassen Sie sich inspirieren – oder besuchen Sie direkt
          unseren
          <nuxt-link
            to="/shop"
            class="link"
            data-tealium-rel="content.text.shop"
          >Shop</nuxt-link>.
        </p>
      </template>
    </ImageTextError>

    <!-- 500 -->
    <ImageTextError
      v-else
      :grid-size="7"
      :error-data="genericErrorData"
      class="error error--500"
    >
      <template #content>
        <h1>smarthome.de ist nicht erreichbar</h1>
        <p>
          Wir wurden automatisch über den Fehler benachrichtigt. Bitte
          versuchen Sie, die Seite neu zu laden. Sollte dies nicht zum
          Erfolg führen, probieren Sie es bitte in wenigen Minuten erneut.
        </p>
      </template>

      <template v-if="mode.isDevelopment" #development>
        <pre>{{ error.message }}</pre>
        <pre>{{ error.stack }}</pre>
      </template>
    </ImageTextError>
  </main>
</template>

<script>
import { mode } from '@/lib/client-env';
import PageHeader from '@/mixins/page-header';
import ImageTextError from '@/components/errors/image-text-error';

// By the way, having a TextImage component is a bullshitty idea! Greetings.
const BASE_TEXT_IMAGE_DATA = {
  theme: 'dark',
  align: 'center',
  imgPosition: 'top',
};

export default {
  name: 'Error',
  components: {
    ImageTextError,
  },
  mixins: [
    PageHeader,
  ],
  props: {
    error: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      mode,
      notFoundErrorData: {
        ...BASE_TEXT_IMAGE_DATA,
        altText: 'Entschuldigung',
        srcSmall: '/api/content/images/error/2c71ef9aee89b85b64682f32c15b4615/icon-404.png',
      },
      genericErrorData: {
        ...BASE_TEXT_IMAGE_DATA,
        altText: 'smarthome.de ist nicht erreichbar',
        srcSmall: '/api/content/images/error/c8d9690adc45f0ed51753da7ea62569b/icon-500.png',
      },
    };
  },
  computed: {
    isNotFoundError() {
      return this.error.statusCode === 404;
    },
    title() {
      return 'Fehler';
    },
    isIndexed() {
      return false;
    },
  },
};
</script>

<style scoped lang="scss">
.error {
  &--500 {
    min-height: 100vh;
    border-top: 4px solid #E20074; // FIXME: Get color from variables
  }
}
</style>

<template>
  <GridContainer class="error">
    <GridRow align-h="center">
      <GridColumn :xs="12" :md="gridSize">
        <TextImage :text-image-data="errorData">
          <slot name="content" />
        </TextImage>

        <slot name="development" />
      </GridColumn>
    </GridRow>
  </GridContainer>
</template>

<script>
import { GridColumn, GridRow, GridContainer } from '@i22-td-smarthome/component-library';
import TextImage from '@/components/text-image';

export default {
  name: 'ImageTextError',
  components: {
    TextImage,
    GridColumn,
    GridRow,
    GridContainer,
  },
  props: {
    gridSize: {
      type: Number,
      default: 6,
    },
    errorData: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
@import 'assets/base';

.error {
  padding: 48px 0;

  @include mq($mq-md) {
    padding: 72px 0;
  }
}
</style>

export const state = () => ({
  active: 0,
  global: 0,
});

export const mutations = {
  SHOW(state, options = {}) {
    state.active += 1;
    if (options.global) {
      state.global += 1;
    }
  },
  HIDE(state, options = {}) {
    if (state.active > 0) {
      state.active -= 1;
    }
    if (options.global && state.global > 0) {
      state.global -= 1;
    }
  },
};

export const actions = {};

export const getters = {
  isActive(state) {
    return state.active > 0;
  },
  isGloballyActive(state) {
    return state.global > 0;
  },
};

<template>
  <div
    class="dialog-backdrop"
    :class="containerClass"
  >
&nbsp;
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'DialogBackdrop',
  computed: {
    ...mapGetters('backdrop', [
      'isActive',
      'isGloballyActive',
    ]),
    containerClass() {
      return {
        'dialog-backdrop--is-active': this.isActive,
        'dialog-backdrop--is-inactive': !this.isActive,
        'dialog-backdrop--is-global': this.isGloballyActive,
      };
    },
  },
};

</script>

<style lang="scss">
  @import '../assets/base';

  .app {
    &--has-global-backdrop,
    &--has-backdrop {
      overflow-y: hidden;
    }
  }

  .dialog-backdrop {
    background-color: rgba(color(grey, 12000), 0.75);
    position: fixed;
    z-index: 7; // backdrop < flyout < nav
    user-select: none;
    opacity: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // width: 100vw;
    // height: 100vh;
    display: block;

    &--is-inactive,
    &--is-active {
      transition: opacity 200ms cubic-bezier(0.25,0.1,0.25,1);
    }

    &--is-inactive {
      opacity: 0;
      pointer-events: none;
    }

    &--is-active {
      opacity: 1;
      height: 100vh;
    }

    &--is-global {
      z-index: 100; // nav < flyout < backdrop < modal
    }
  }
</style>

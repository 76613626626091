import { compact, isUndefined, isNil } from 'lodash';
import Url from 'url';
import { baseUrl } from '@/lib/client-env';

export const DEFAULTS = {
  title: 'Telekom',
  description: `SmartHome: erfahren Sie hier alles über
    intelligente Haussteuerung per Smartphone und kaufen Sie SmartHome
    Produkte ganz einfach online.`,
  ogImagePath: '/api/content/images/og-images/245111fc7a009e1e5d5ecfa3d0d110a3/og-home.jpg',
};

function metaProp(name, value, defaultValue = undefined) {
  const content = value || defaultValue;
  if (isUndefined(content)) return undefined;
  return { name, hid: name, content };
}

export default {
  data() {
    return {
      pageMeta: {
        title: null,
        description: null,
        canonical: null,
      },
    };
  },
  computed: {
    isIndexed() {
      return true;
    },
    canonicalPath() {
      return this.$route.path;
    },
    canonicalUrl() {
      if (!this.isIndexed) {
        return undefined;
      }
      if (!isNil(this.selfCanonical || this.pageMeta.canonical)) {
        return this.selfCanonical || this.pageMeta.canonical;
      }
      return Url.resolve(baseUrl, this.canonicalPath);
    },
    fullTitle() {
      const title = this.pageMeta.title || this.title;
      if (!title) return DEFAULTS.title;
      return `${title} | ${DEFAULTS.title}`;
    },
    ogType() {
      return 'website';
    },
    ogImageUrl() {
      if (!this.ogImagePath) return undefined;
      return Url.resolve(baseUrl, this.ogImagePath);
    },
    ogDescription() {
      return this.pageMeta.description || this.description;
    },
    nexusDescription() {
      return this.pageMeta.description || this.description;
    },
    robots() {
      // When this.isIndexed is not explicitly defined we consider the page as
      // indexable. You need to explicitly opt-out indexing by defining a
      // data entry or computed property in your own page component.
      const isIndexed = isUndefined(this.isIndexed) || this.isIndexed;
      return [isIndexed ? 'index' : 'noindex', 'follow'].join(', ');
    },
    meta() {
      return compact([
        metaProp('description', this.pageMeta.description || this.description, DEFAULTS.description),
        metaProp(
          'nexus:description',
          this.nexusDescription,
          DEFAULTS.description,
        ),
        metaProp('nexus:title', this.pageMeta.title || this.title, DEFAULTS.title),
        metaProp('og:description', this.ogDescription, DEFAULTS.description),
        metaProp('og:image', this.ogImageUrl),
        metaProp('og:title', this.fullTitle),
        metaProp('og:type', this.ogType, 'website'),
        metaProp('og:url', this.canonicalUrl),
        metaProp('robots', this.robots),
        metaProp('sitemap:title', this.pageMeta.title || this.title, DEFAULTS.title),
      ]);
    },
    prevPageUrl() {
      if (!this.prevPagePath) {
        return undefined;
      }
      return Url.resolve(baseUrl, this.prevPagePath);
    },
    nextPageUrl() {
      if (!this.nextPagePath) {
        return undefined;
      }
      return Url.resolve(baseUrl, this.nextPagePath);
    },
    link() {
      const links = [];
      if (this.canonicalUrl) {
        links.push({ rel: 'canonical', href: this.canonicalUrl });
      }
      if (this.prevPageUrl) {
        links.push({ rel: 'prev', href: this.prevPageUrl });
      }
      if (this.nextPageUrl) {
        links.push({ rel: 'next', href: this.nextPageUrl });
      }
      return links;
    },
  },
  head() {
    return {
      title: this.fullTitle,
      meta: this.meta,
      link: this.link,
    };
  },
};

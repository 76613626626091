<template>
  <div class="layout">
    <Branding />
    <client-only>
      <div class="layout--tool">
        <Nuxt />
      </div>
    </client-only>
  </div>
</template>

<script>
import Branding from '@/components/branding';
import PageHeader from '@/mixins/page-header';

export default {
  name: 'ToolLayout',
  components: {
    Branding,
  },
  mixins: [PageHeader],
  computed: {
    robots() {
      return 'noindex, nofollow';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../assets/base';

.layout--tool {
  padding-top: 6rem;
  @include mq($mq-large) {
    padding-top: 7rem;
  }
}
</style>

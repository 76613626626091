<template>
  <svg
    :viewBox="image.viewBox"
    :class="{'icon' : !noIconClass}"
  >
    <use :xlink:href="`#${image.id}`" />
  </svg>
</template>

<script>
export default {
  name: 'SvgIcon',
  props: {
    image: {
      type: [Object, String],
      required: true,
    },
    noIconClass: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

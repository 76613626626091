<template>
  <main class="maintenance">
    <section class="maintenance__content">
      <GridContainer>
        <CdnImageElement
          alt="info-icon"
          class="content__icon"
          src="/api/content/images/bf76364b4d000822a45dd5c9fb92b275/maintenance.gif"
        />
        <Heading
          align="center"
          tag="h2"
          theme="light"
          :text="maintenanceHeading"
        />
        <p
          class="content__description"
          v-html="maintenanceDescription"
        />
      </GridContainer>
    </section>
    <section class="maintenance__alternatives">
      <GridContainer>
        <Heading
          align="center"
          tag="h5"
        >
          Alternativ können Sie die folgenden Bestellwege nutzen
        </Heading>
        <div class="alternatives maintenance__alternative-group">
          <div class="maintenance__alternative">
            <a
              class="maintenance__alternative-link"
              data-tealium-rel="content.button.festnetz-internet"
              href="https://www.telekom.de/zuhause/tarife-und-optionen/internet"
            >
              <span class="alternative__bullet">
                <SvgIcon
                  :image="iconFestnetzInternet"
                  class="alternative__icon"
                />
              </span>
              <span class="alternative__description">
                Festnetz & Internet
              </span>
            </a>
          </div>
          <div class="maintenance__alternative">
            <a
              class="maintenance__alternative-link"
              data-tealium-rel="content.button.tv"
              href="https://www.telekom.de/magenta-tv"
            >
              <span class="alternative__bullet">
                <SvgIcon
                  :image="iconTv"
                  class="alternative__icon"
                />
              </span>
              <span class="alternative__description">
                TV
              </span>
            </a>
          </div>
          <div class="maintenance__alternative">
            <a
              class="maintenance__alternative-link"
              data-tealium-rel="content.button.magenta-service"
              href="https://www.telekom.de/hilfe/magentaservice"
            >
              <span class="alternative__bullet">
                <SvgIcon
                  :image="iconMagentaService"
                  class="alternative__icon"
                />
              </span>
              <span class="alternative__description">
                MagentaSERVICE
              </span>
            </a>
          </div>
        </div>
      </GridContainer>
    </section>

  </main>
</template>

<script>
import { mapGetters } from 'vuex';
import { GridContainer, Heading } from '@i22-td-smarthome/component-library';

import CdnImageElement from '@/components/cdn-image-element';
import iconFestnetzInternet from '@/assets/images/festnetz-internet.svg';
import iconTv from '@/assets/images/tv.svg';
import iconMagentaService from '@/assets/images/magentaservice.svg';

export default {
  components: {
    CdnImageElement,
    GridContainer,
    Heading,
  },
  data() {
    return {
      stageHeading: 'Vielen Dank für Ihr Interesse',
      iconFestnetzInternet,
      iconTv,
      iconMagentaService,
    };
  },
  computed: {
    ...mapGetters('downtimes', ['maintenanceDowntime']),
    maintenanceHeading() {
      return this.maintenanceDowntime?.maintenanceHeading || 'Aktuell passen wir die Seite für dich an.';
    },
    maintenanceDescription() {
      const defaultDescription = `Aus diesem Grund ist diese Seite im
        Moment leider nicht erreichbar. Bitte versuche es später noch einmal.<br/><br/>
        Alternativ kann die <a rel="noopener" data-tealium-rel="content.text.meinmagenta-app" href="https://ccyq.adj.st/v2/start?adjust_t=42kf68_ghz29q&adjust_campaign=onsite_teaser&adjust_adgroup=got&adjust_creative=&adjust_deeplink=tsa%3A%2F%2Fv2%2Fstart&adjust_fallback=https%3A%2F%2Fwww.telekom.de%2Fhilfe%2Fmeinmagenta-app" target="_MMA" title="MeinMagenta App">MeinMagenta App</a>
        genutzt werde, um z. B. den Router einzurichten, die Rechnung einzusehen und vieles mehr.<br/><br/>
        Wir bitten um Verständnis.`;
      return this.maintenanceDowntime?.maintenanceDescription || defaultDescription;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'assets/base';

.maintenance {
  min-height: 100vh;

  &__content,
  &__alternatives {
    padding: $grid-base * 4 $grid-base * 2;
    text-align: center;
  }

  &__content {
    background-color: color(magenta, 1000);
    color: white;

    :deep(a) {
      text-decoration: underline;
      color: white;
    }

    :deep(a:visited) {
      color: white;
    }
  }

  &__alternatives {
    background-color: color(grey, 22000);
    color: color(grey, 12000);
  }

  &__alternative {
    &-group {
      margin-top: $grid-base * 3;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
    }

    .alternative {
      &__icon {
        height: 100%;
        width: 100%;
        color: white;
      }

      &__bullet {
        display: block;
        background-color: color(grey, 12000);
        border-radius: 50%;
        padding: $grid-base;
        margin-bottom: 6px;
        height: 75px;
        width: 75px;
        border: 4px solid color(grey, 12000);
        cursor: pointer;

        @include mq($mq-medium) {
          padding: 20px;
          height: 100px;
          width: 100px;
        }

        @include mq($mq-lg) {
          height: 180px;
          width: 180px;
          padding: $grid-base * 3 $grid-base * 3 $grid-base * 6;
        }
      }

      &__description {
        display: block;
        color: color(grey, 12000);
        @include font-size(14px, 18px);

        @include mq(460px) {
          @include font-size(18px, 24px);
        }

        @include mq($mq-lg) {
          color: white;
          transform: translateY(-66px);
        }
      }
    }

    &-link {
      &:hover {
        .alternative__bullet {
          border: 4px solid color(magenta, 1000);

          .alternative__icon {
            color: color(magenta, 1000);
          }
        }
      }
    }
  }
}
</style>

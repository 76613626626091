import ShopApi from '@/lib/shop-api';

const isBundleItem = (item) => item.articleNumber === 'INDIVIDUELLBONUS';

export const state = () => ({
  order: {
    items: [],
  },
});

export const mutations = {
  SET_ORDER(state, order) {
    state.order = order;
  },
};

export const getters = {
  confirmedBundleItem(state, getters) {
    return getters.confirmedItems.find(isBundleItem);
  },
  confirmedCartItemsWithoutBundle(state, getters) {
    return getters.confirmedItems.filter((item) => !isBundleItem(item));
  },
  confirmedItems(state, getters) {
    return getters.confirmedOrder.items || [];
  },
  confirmedOrder(state) {
    if (!state.order) return {};
    return state.order;
  },
  confirmedTotalMonthlyPrice(state, getters) {
    return getters.confirmedItems.reduce(
      (accumulator, item) => accumulator + item.monthlyPrice * item.quantity,
      0,
    );
  },
  confirmedTotalPrice(state, getters) {
    return getters.confirmedItems.reduce(
      (accumulator, item) => accumulator + item.price * item.quantity,
      0,
    );
  },
  confirmedTotalPriceWithShipping(state, getters) {
    return getters.confirmedTotalPrice + getters.confirmedShippingCosts - getters.voucherReductionAmount;
  },
  confirmedShippingCosts(state, getters) {
    if (
      getters.confirmedHasInstallationServiceInCart &&
      getters.confirmedItems.length === 1
    ) {
      return 0;
    }
    return state.order.shippingCostsTvpp + state.order.shippingCostsBrodos;
  },
  confirmedHasInstallationServiceInCart(state, getters) {
    return getters.confirmedItems.some((item) => item.contractNumber === '89859700');
  },
  onlyTariffOrdered(state, getters) {
    return getters.confirmedItems.every((item) => item.category === 'Tariff') || null;
  },
  voucherReductionAmount(_state, getters) {
    return getters.confirmedOrder.voucherReductionAmount || 0;
  },
};

export const actions = {
  async submitOrder(context, orderParams) {
    const { data } = await ShopApi.submitOrder(orderParams);
    return data;
  },
  async setConfirmedOrder(context) {
    const { data } = await ShopApi.confirmOrder();
    context.commit('SET_ORDER', data.order);
    return data;
  },
};

<template>
  <div class="layout layout--checkout">
    <PageHeader
      theme="small"
    />
    <client-only v-if="showMaintenanceDialog">
      <Maintenance />
    </client-only>
    <nuxt v-else-if="isLoaded" />
    <PageFooter theme="standard" />
    <DialogBackdrop />
    <LoadingSpinner />
    <portal-target name="globals" multiple />
    <template v-if="showStatusToolbar">
      <client-only>
        <StatusToolbarWrapper />
      </client-only>
    </template>
  </div>
</template>

<script>
import { mode } from '@/lib/client-env';
import { mapActions, mapGetters } from 'vuex';
import DialogBackdrop from '@/components/dialog-backdrop';
import Layout from '@/mixins/layout';
import LoadingSpinner from '@/components/loading-spinner';
import PageHeader from '@/components/page-header';
import PageFooter from '@/components/page-footer';
import Maintenance from '@/components/maintenance';

const components = {
  DialogBackdrop,
  LoadingSpinner,
  Maintenance,
  PageHeader,
  PageFooter,
};

if (!mode.isProduction) {
  components.StatusToolbarWrapper = () => import('@/components/status-toolbar-wrapper');
}

export default {
  name: 'Checkout',
  components,
  mixins: [Layout],
  data() {
    return {
      showStatusToolbar: !mode.isProduction,
      isLoaded: false,
    };
  },
  computed: {
    ...mapGetters('cart', ['cartIsEmpty']),
  },
  async mounted() {
    await this.initCart();
    this.isLoaded = true;
    if (this.cartIsEmpty) {
      await this.$router.push('/shop/checkout/warenkorb');
    }
  },
  methods: {
    ...mapActions('cart', ['initCart']),
  },
};
</script>

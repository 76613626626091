v<template>
  <div
    class="nav-category"
    :class="dynamicClasses"
  >
    <nuxt-link
      :to="`${linkPath}`"
      :title="category.name"
      class="nav-category__category-link"
      :data-tealium-rel="tealiumLabel | slugify"
      @click.native="onClick"
    >
      <SvgIcon
        :image="category.icon ? category.icon : ''"
        class="nav-category__icon"
      />
      <span class="nav-category__category-title">{{ category.name }}</span>
    </nuxt-link>
  </div>
</template>

<script>

export default {
  name: 'NavCategory',
  props: {
    category: {
      type: Object,
      required: true,
    },
    mainCategory: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    dynamicClasses() {
      return {
        'nav-category--main-category': this.mainCategory,
        'nav-category--is-active': this.isActive,
      };
    },
    linkPath() {
      if (this.mainCategory) {
        return `/${this.category.path}`;
      }
      return `/shop/${this.category.path}`;
    },
    isActive() {
      if (this.mainCategory) {
        return this.$route.path === `/${this.category.path}`;
      }
      return this.$route.params.category === this.category.path;
    },
    tealiumLabel() {
      if (this.category.path === 'shop') {
        return 'navi-shop-category.alle-geraete';
      }
      if (this.category.path === 'shop-start') {
        return 'navi-shop-category.angebote-und-top-themen';
      }
      return `navi-shop-category.${this.category.path}`;
    },
  },
  methods: {
    onClick() {
      this.collapseMenuItem();
    },
    collapseMenuItem() {
      const shopNavBar = this.$parent;
      if (!shopNavBar) return;
      const menuItem = shopNavBar.$parent;
      if (menuItem) {
        if (menuItem.onCloseSmallScreenNav) {
          menuItem.onCloseSmallScreenNav();
        } else if (menuItem.collapse) {
          menuItem.collapse();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '../assets/base';

  .nav-category {
    width: 49%;
    border: 1px solid color(grey, 25000);
    border-radius: $grid-base;


    @include mq($mq-sm) {
      width: 32%;
      border-radius: 4px;
    }

    @include mq($mq-md) {
      width: 25%;
      border: none;

      &:hover {
        background: color(grey, 29000);
      }
    }

    @include mq(false, $mq-md + 1) {
      &--is-active {
        background: color(grey, 29000);
      }
    }

    @include mq($mq-xl) {
      width: 19%;
    }


    &__category-link {
      padding: $grid-base $grid-base * 0.5;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: $grid-base * 0.5;

      @include mq($mq-sm) {
        flex-direction: row;
        justify-content: flex-start;
      }

      @include mq($mq-md) {
        justify-content: flex-start;
        flex-direction: row;
        width: 100%;
        gap: $grid-base;
      }
    }

    &__icon {
      width: 28px;
      height: 28px;
    }

    &__category-title {
      color: $color-font;
      @include font-size(15px, 21px);
      @include mq($mq-md) {
        @include font-size(18px, 24px);
      }
    }

    &--main-category {
      width: 100%;

      .nav-category {

        &__category-link {
          flex-direction: row;
          padding: $grid-base * 2 $grid-base;
          font-weight: 700;

          @include mq($mq-sm) {
            padding: 28px $grid-base * 0.5;
          }

          @include mq($mq-md) {
            padding: $grid-base $grid-base * 0.5;
          }
        }

        &__category-title {
          @include font-size(18px, 24px);

          @include mq($mq-sm, $mq-md - 1) {
            @include font-size(15px, 21px);
          }
        }
      }
    }
}
</style>


// This module gives access to the client env as passed from the
// server environment via the pseudo "process.env" which has NOTHING
// to do with the actual server "process.env".
// This pseudo "process.env" is setup on
// the server-side in @/nuxt.client-env-config.js

// Please DO NOT try to optimize the following statements.
// This is currently the only way that transpiling this code works
// without leaving a literal process.env in the transpiled code.
/* eslint-disable prefer-destructuring */

/** @type {Date} */
export const buildDate = process.env.buildDate;
/** @type {import("@/server/mode")} */
export const mode = process.env.mode;
export const baseUrl = process.server ? process.env.baseUrl : window.location.origin;
export const hostingAuth = process.env.hostingAuth;
export const shopBackend = process.env.shopBackend;
export const shopConfirmationUrl = process.env.shopConfirmationUrl;
export const shopErrorUrl = process.env.shopErrorUrl;
export const telekomLoginSuccessUrl = process.env.telekomLoginSuccessUrl;
export const telekomLoginFailureUrl = process.env.telekomLoginFailureUrl;
export const productImagePath = process.env.productImagePath;
export const goliathImagePath = process.env.goliathImagePath;
/** @type {import("@/server/config/sentry")} */
export const sentry = process.env.sentry;
/** @type {import("@/server/config/cloud-front")} */
export const cloudFront = process.env.cloudFront;
/** @type {import("@/current_revision.json")} */
export const currentRevision = process.env.currentRevision;
/** @type {import("@/server/config/search")} */
export const search = process.env.search;
/** @type {import("@/server/config/novalnet")} */
export const novalnet = process.env.novalnet;

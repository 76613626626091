<template>
  <GetApp
    :link-apple="linkApple"
    :link-google="linkGoogle"
    :link-amazon="linkAmazon"
    :tealium-suffix="tealiumSuffix"
  />
</template>

<script>
import { GetApp } from '@i22-td-smarthome/component-library';

export default {
  name: 'GetAppContainer',
  components: {
    GetApp,
  },
  props: {
    linkApple: {
      type: String,
      default: 'https://apps.apple.com/de/app/magentazuhause/id1596295335',
    },
    linkGoogle: {
      type: String,
      default: 'https://play.google.com/store/apps/details?id=de.telekom.magentazuhause',
    },
    linkAmazon: {
      type: String,
      default: '',
    },
    tealiumSuffix: {
      type: String,
      default: '',
    },
  },
};
</script>

import { mapActions, mapGetters } from 'vuex';
import throttle from 'lodash/throttle';

const WIDTH_THROTTLE_MS = 200;

function hasWindow() {
  return typeof window !== 'undefined';
}

export default {
  data() {
    return {
      scrollHandler: null,
      resizeHandler: null,
    };
  },
  computed: {
    ...mapGetters('backdrop', { backdropIsActive: 'isActive', backdropIsGloballyActive: 'isGloballyActive' }),
    ...mapGetters('viewport', ['hasVerticalScrollbar']),
    ...mapGetters('downtimes', ['downtimesIntervalTimeUp', 'maintenanceDowntime']),
    isPageWithDowntime() {
      return [
        'shop-checkout-warenkorb',
        'shop-checkout-adresse',
        'shop-checkout-zahlungsart',
        'shop-checkout-zusammenfassung',
        'shop-list',
        'shop-start',
        'geraete-slug',
      ].includes(this.$route?.name);
    },
    showMaintenanceDialog() {
      // The server has to render the full page, because no deployment will be triggered on downtime end.
      if (process.server) return false;
      if (!this.isPageWithDowntime) return false;
      return !!this.maintenanceDowntime;
    },
  },
  created() {
    if (!hasWindow()) return;
    this.scrollHandler = () => this.setViewportScroll();
    this.resizeHandler = throttle(
      () => { this.setViewportSize(); },
      WIDTH_THROTTLE_MS,
    );
    this.setViewportSize();
    this.setViewportScroll();
    window.addEventListener('scroll', this.scrollHandler);
    window.addEventListener('resize', this.resizeHandler);
  },
  destroyed() {
    if (!hasWindow()) return;
    window.removeEventListener('scroll', this.scrollHandler);
    window.removeEventListener('resize', this.resizeHandler);
    this.scrollHandler = null;
    this.resizeHandler = null;
  },
  async mounted() {
    await this.checkForDowntime();
  },
  watch: {
    async $route() {
      await this.checkForDowntime();
    },
  },
  methods: {
    ...mapActions('downtimes', ['fetchDowntimes']),
    ...mapActions('viewport', ['setScroll', 'setSize']),
    setViewportScroll() {
      if (!hasWindow()) return;
      this.setScroll(window.pageYOffset);
    },
    setViewportSize() {
      if (!hasWindow()) return;
      this.setSize({
        width: window.innerWidth,
        height: window.innerHeight,
        docWidth: document.documentElement.clientWidth,
      });
    },
    async checkForDowntime() {
      if (!this.isPageWithDowntime) return; // When we are not in shop, we do not need to fetch downtimes.
      await this.fetchDowntimes();
    },
  },
  head() {
    const htmlClasses = ['app'];
    if (this.backdropIsActive) {
      htmlClasses.push('app--has-backdrop');
    }
    if (this.backdropIsGloballyActive) {
      htmlClasses.push('app--has-global-backdrop');
    }
    if (this.hasVerticalScrollbar) {
      htmlClasses.push('app--has-vertical-scrollbar');
    }
    return { htmlAttrs: { class: htmlClasses.join(' ') } };
  },
};


export default {
  namespaced: true,
  state() {
    return {
      month: null,
      day: null,
      hours: null,
      minutes: null,
      seconds: null,
    };
  },
  mutations: {
    SET_SERVER_DATE(state) {
      const date = new Date();
      state.year = date.getFullYear();
      state.month = date.getMonth() + 1;
      state.day = date.getDate();
      state.hours = date.getHours();
      state.minutes = date.getMinutes();
      state.seconds = date.getSeconds();
    },
  },
};

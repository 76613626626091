import cloneDeep from 'lodash/cloneDeep';

// We export this function to be able to test this in isolation.
export function initHelper() {
  return {
    component: null,
    contentComponents: [],
    register(component) {
      this.component = component;
    },
    unregister() {
      this.component = null;
    },
    show(component, options) {
      if (!this.component) throw new Error('No dialog registered.');
      return this.component.show(component, options);
    },
    hide() {
      if (!this.component) throw new Error('No dialog registered.');
      return this.component.hide();
    },
    info(message, options = {}) {
      const componentOptions = cloneDeep(options);
      componentOptions.message = message;
      delete componentOptions.type;
      return this.show('InfoDialogContent', {
        type: options.type,
        componentOptions,
        isDismissable: true,
        defaultResult: true,
      });
    },
    confirm(message, options = {}) {
      const componentOptions = cloneDeep(options);
      componentOptions.message = message;
      return this.show('ConfirmationDialogContent', {
        type: 'warning',
        componentOptions,
        isDismissable: false,
        defaultResult: false,
      });
    },
  };
}

export default initHelper();

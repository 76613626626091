import { clientWithLoadingIndicator, plainClient } from '@/lib/axios-client/shop-api';

export default ({ store }) => {
  function sendCsrfToken(request) {
    const { csrfToken } = store.state.cart;
    if (csrfToken) {
      request.headers['X-CSRF-Token'] = csrfToken;
    }
    return request;
  }

  function setCsrfTokenFromHeader(response) {
    if (!response || !response.headers) return;
    const csrfToken = response.headers['x-csrf-token'];
    if (csrfToken) {
      store.commit('cart/SET_CSRF_TOKEN', csrfToken);
    }
  }

  function storeCsrfToken(response) {
    setCsrfTokenFromHeader(response);
    return response;
  }

  function storeCsrfTokenOnError(error) {
    setCsrfTokenFromHeader(error.response);
    throw error;
  }

  function setupCsrfInterceptors(client) {
    client.interceptors.request.use(sendCsrfToken);
    client.interceptors.response.use(
      storeCsrfToken,
      storeCsrfTokenOnError,
    );
  }

  setupCsrfInterceptors(plainClient);
  setupCsrfInterceptors(clientWithLoadingIndicator);
};

import { goliathImagePath, productImagePath } from '@/lib/client-env';

const GOLIATH_PATH_PREFIX_REGEXP = RegExp(`.*${goliathImagePath}`);

function replacePathPrefix(path) {
  if (!path) return undefined;
  return path.replace(GOLIATH_PATH_PREFIX_REGEXP, productImagePath);
}

function url(attachment) {
  if (!attachment) return undefined;
  if (attachment.remote) return attachment.url;
  return replacePathPrefix(attachment.path);
}

function replaceGoliathPublicPath(path) {
  // goliath public files are proxied through brontend under different path
  return path?.startsWith('/public')
    ? path.replace('/public', productImagePath)
    : '';
}

/**
 * @param {*} attachment LocalAttachment or RemoteAttachment from Goliath
 * @returns {string=}
 */
function thumbImageUrl(attachment) {
  if (!attachment) return undefined;
  if (attachment.remote) return attachment.url;
  return replacePathPrefix(attachment.thumbImagePath);
}

export { url as attachmentUrl, thumbImageUrl as attachmentThumbUrl, replaceGoliathPublicPath };

export default { url, thumbImageUrl };

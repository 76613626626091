<template>
  <div class="secondary-navigation">
    <div
      v-if="nexusSearch"
      ref="nexusSearchForm"
      class="secondary-navigation__search-form"
    />
    <SearchForm
      v-else
      ref="searchForm"
      v-on-clickaway="closeSearch"
      :term="searchTerm"
      @submit="search"
      @close="closeSearch"
    />

    <ul class="secondary-navigation__list">
      <li class="secondary-navigation__item">
        <SearchButton
          ref="searchButton"
          icon-class="secondary-navigation__icon"
          data-tealium-rel="navi-top.button.oeffne-suchformular"
          class="secondary-navigation__link"
          @click="openSearch"
        />
      </li>
      <li class="secondary-navigation__item">
        <CartIndicator
          icon-class="secondary-navigation__icon"
          class="secondary-navigation__link"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import trim from 'lodash/trim';
import { mixin as Clickaway } from 'vue-clickaway2';
import CartIndicator from '@/components/cart-indicator';
import SearchButton from '@/components/search-button';
import { search } from '@/lib/client-env';

const nexusSearch = search.searchType === 'nexus';
const SCRIPT_TAG_ID = 'nexusScriptTag';

export default {
  name: 'SecondaryNavigation',
  components: {
    CartIndicator,
    SearchButton,
    SearchForm: () => import('@/components/search-form'),
  },
  mixins: [Clickaway],
  data() {
    return {
      searchIsOpened: false,
      nexusSearch,
    };
  },
  computed: {
    searchTerm() {
      const term = this.$route.query.search;
      if (!term) return undefined;
      const sanitizedTerm = trim(term);
      if (!sanitizedTerm) return undefined;
      return sanitizedTerm;
    },
  },
  mounted() {
    if (/^#suche/.test(document.location.hash)) {
      this.openSearch();
    }
  },
  methods: {
    loadNexusSearch() {
      if (document.getElementById(SCRIPT_TAG_ID)) {
        return;
      }
      const nexusScript = document.createElement('script');
      nexusScript.setAttribute('src', search.nexusScriptUrl);
      nexusScript.setAttribute('id', SCRIPT_TAG_ID);
      document.head.append(nexusScript);
      if (!document.location.hash) {
        try {
          const savedSearchTerm = JSON.parse(decodeURIComponent(document.cookie.match(/(^| )nexus-pk-coin=([^;]+)/)[2])).q;
          document.location.hash = `suche?${encodeURIComponent(savedSearchTerm)}`;
        } catch (e) {
          document.location.hash = 'suche';
        }
      }
    },
    openSearch() {
      if (nexusSearch) {
        this.loadNexusSearch();

        window?.scrollTo({ top: 0, left: 0 });

        return;
      }

      this.$refs.searchForm.openAndFocus();
    },
    closeSearch() {
      if (nexusSearch) return;
      this.$refs.searchForm.close();
    },
    onCloseSearch() {
      if (nexusSearch) return;
      this.$refs.searchButton.focus();
    },
    search({ term }) {
      this.$emit('search', term);
    },
  },
};

</script>

<style lang="scss">
@import '../assets/base';

.secondary-navigation {
  display: flex;
  align-items: center;
  box-sizing: content-box;
  margin-left: auto;

  @include mq($mq-large) {
    margin-left: auto;
    padding-right: $grid-base;
  }

  &__list {
    list-style: none;
    display: flex;

    @include mq(false, $mq-md - 1) {
      position: absolute;
      top: calc(var(--branding-height) + 4px);
      right: 10px;
      height: 60px;
    }
  }

  &__item {
    flex-basis: 60px;
    height: 100%;
  }

  &__link {
    display: flex;
    height: 56px;
    color: $color-font;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 0;

    &--active {
      color: $color-primary;
    }

    @include mq($mq-large) {
      border-radius: 4px;
      height: 100%;
    }
  }

  &__icon {
    width: 26px;
    height: 24px;
  }
}

</style>

<template>
  <div class="layout layout--applogin">
    <nuxt />
    <DialogBackdrop />
    <LoadingSpinner />
    <portal-target name="globals" />
  </div>
</template>

<script>
import DialogBackdrop from '@/components/dialog-backdrop';
import Layout from '@/mixins/layout';
import LoadingSpinner from '@/components/loading-spinner';

export default {
  name: 'AppLogin',
  components: {
    DialogBackdrop,
    LoadingSpinner,
  },
  mixins: [Layout],
};
</script>

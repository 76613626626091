import contentClient from '@/lib/axios-client/content';
import goliathClient from '@/lib/axios-client/goliath';
import shopApiClient from '@/lib/axios-client/shop-api';

function setupLoader(client, store) {
  function startLoading(original) {
    // console .log(`startLoading: ${original.method} ${original.url}`); // eslint-disable-line no-console
    store.commit('loader/START_LOADING');
    return original; // This is important as it returns the modified request
  }

  function stopLoading(original) {
    store.commit('loader/STOP_LOADING');
    return original; // This is important as it returns the modified response
  }

  function stopLoadingOnError(error) {
    store.commit('loader/STOP_LOADING');
    throw error;
  }

  client.interceptors.request.use(startLoading);
  client.interceptors.response.use(stopLoading, stopLoadingOnError);
}

export default ({ store }) => {
  setupLoader(contentClient, store);
  setupLoader(goliathClient, store);
  setupLoader(shopApiClient, store);
};

import { mode } from '@/lib/client-env';


function dateToString(date) {
  return `${(new Date(date)).toISOString()}ZZ`;
}

const tardisStore = {};
if (!mode.production) {
  tardisStore.state = () => ({
    timeCoordinates: dateToString(Date.now()),
  });
  tardisStore.mutations = {
    SET_TIME_COORDINATES(state, newTimeCoordinates) {
      try {
        const isoDate = dateToString(newTimeCoordinates);
        state.timeCoordinates = isoDate;
      } catch (e) {
        console.warn('could not travel, ', e); // eslint-disable-line no-console
        // just dont travel, it's ok
      }
    },
  };
  tardisStore.getters = {
    getTimeCoordinates(state) {
      return state.timeCoordinates;
    },
  };
}

export default tardisStore;

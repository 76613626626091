import { camelCase, filter, fromPairs, groupBy, isEmpty } from 'lodash';
import Cookies from 'js-cookie';
import ShopApi from '@/lib/shop-api';
import { fetchOfferBySlugs } from '@/lib/goliath/offer-by-slugs';
import { hasError } from '@/helpers/shop-api-errors';

function isRegularBundle(item, state) {
  return Object.keys(state.bundlesByArticleNumber).includes(item.articleNumber);
}

const shippingVariantNameMap = {
  standard: 'Standard',
  express: 'Express',
  preferred_day: 'Wunschtag',
};

const CHECKOUT_INIT_URLS = {
  upgrade: 'kundenformular/upgrade-identifikation',
  newCustomer: 'checkout/adresse',
  existingCustomer: 'kundenformular',
};

const DEFAULT_CART_STATE = { items: [] };

export default {
  namespaced: true,
  state() {
    return {
      // The order token can be used to overwrite the current session
      // by setting the X-Order-Token Header in requests.
      orderToken: undefined,
      isOrderTokenInvalid: undefined,
      cart: DEFAULT_CART_STATE,
      initialized: false,
      csrfToken: null,
      couponCode: null,
      bundlesByArticleNumber: {},
    };
  },
  mutations: {
    ADD_COUPON_CODE_TO_STORE(state, couponCode) {
      state.couponCode = couponCode;
    },
    SET_CART(state, cart) {
      state.cart = cart;
      state.initialized = true;
    },
    SET_CSRF_TOKEN(state, csrfToken) {
      state.csrfToken = csrfToken;
    },
    SET_SHIPPING_STRATEGY(state, shippingStrategy) {
      state.cart.shippingStrategy = shippingStrategy;
    },
    SET_PAYMENT_TYPE(state, paymentType) {
      if (!paymentType.dataType) {
        state.cart.paymentType = paymentType;
        Cookies.set('SH_PAYMENT_TYPE', JSON.stringify(state.cart.paymentType));
      } else {
        state.cart.paymentType = paymentType.data;
      }
    },
    ADD_PRODUCT_BUNDLES(state, productBundles) {
      Object.keys(productBundles).forEach((articleNumber) => {
        state.bundlesByArticleNumber[articleNumber] = productBundles[articleNumber];
      });
    },
    SET_ORDER_TOKEN(state, orderToken) {
      state.orderToken = orderToken;
    },
    SET_ORDER_TOKEN_INVALID(state, isInvalid) {
      state.isOrderTokenInvalid = isInvalid;
    },
  },
  getters: {
    orderToken(state) {
      return state.orderToken;
    },
    isOrderTokenInvalid(state) {
      return state.isOrderTokenInvalid;
    },
    customerData(state) {
      const { customerNumber, customerPhoneArea, customerPhoneNumber } = state.cart;
      return { customerNumber, customerPhoneArea, customerPhoneNumber };
    },
    isTariffUpgradeFlow(state) {
      // isTariffUpgradeFlow is set to true if we are in mza-upgrade flow and only want to order one tariff and nothing else
      return state.cart.identificationType === 'upgrade';
    },
    cartInitialized(state) {
      return state.initialized;
    },
    cartBundleItems(state, getters) {
      return getters.cartItems.filter((item) => isRegularBundle(item, state));
    },
    cartItemsWithoutBundle(state, getters) {
      return getters.cartItems.filter((item) => !isRegularBundle(item, state));
    },
    shippingVariant(state) {
      if (Cookies.get('SH_SHIPPING_STRATEGY')) {
        const strategy = Cookies.get(
          'SH_SHIPPING_STRATEGY',
          JSON.stringify(state.cart.shippingStrategy),
        ).replace(/"/g, '');
        return shippingVariantNameMap[strategy];
      }
      return shippingVariantNameMap[state.cart.shippingStrategy];
    },
    totalMonthlyPrice(_state, getters) {
      return getters.cartItems
        .filter((item) => item.monthlyPrice > 0)
        .reduce((accumulator, item) => accumulator + item.monthlyPrice * item.quantity, 0);
    },
    totalBrodosPrice(_state, getters) {
      return getters.getBrodosProducts
        .filter((item) => item.price > 0)
        .reduce((accumulator, item) => accumulator + item.price * item.quantity, 0);
    },
    totalTvppPrice(_state, getters) {
      return getters.getTvppProducts
        .filter((item) => item.price > 0)
        .reduce((accumulator, item) => accumulator + item.price * item.quantity, 0);
    },
    totalTvppRecurringPrice(_state, getters) {
      return getters.getTvppProducts
        .filter((item) => item.monthlyPrice > 0)
        .reduce((accumulator, item) => accumulator + item.monthlyPrice * item.quantity, 0);
    },
    totalPriceWithShipping(_state, getters) {
      return getters.cartAmount + getters.shippingCosts - getters.voucherReductionAmount;
    },
    shippingCosts(state, getters) {
      if (getters.hasInstallationServiceInCart && getters.cartItems.length === 1) {
        return 0;
      }
      return getters.shippingCostsTvpp + getters.shippingCostsBrodos;
    },
    shippingCostsTvpp(state) {
      return state.cart?.shippingCostsTvpp || 0;
    },
    shippingCostsBrodos(state) {
      return state.cart?.shippingCostsBrodos || 0;
    },
    shippingCostsBrodosDetails(state) {
      if (!state.cart) return {};
      return state.cart.shippingCostsBrodosDetails;
    },
    getBrodosProducts(_state, getters) {
      return getters.cartItems.filter((item) => item.provider === 'BRODOS');
    },
    getTvppProducts(_state, getters) {
      return getters.cartItems.filter((item) => item.provider === 'TVPP');
    },
    hasTelekomContractInCart(_state, getters) {
      return getters.cartItems.some((i) => i.contractNumber !== null);
    },
    hasOnlyEgspPlusProducts(_state, getters) {
      return getters.hasTvppProductsInCart && !getters.hasTelekomContractInCart;
    },
    hasProductWithOnetimePaymentInCart(state, getters) {
      return getters.hasBrodosProductsInCart;
    },
    hasProductWithRecurringPaymentInCart(state, getters) {
      return getters.hasTvppProductsInCart || getters.hasTelekomContractInCart;
    },
    hasBrodosProductsInCart(_state, getters) {
      return getters.getBrodosProducts.some((i) => i.articleNumber !== null);
    },
    hasInstallationServiceInCart(_state, getters) {
      return getters.cartItems.some((i) => i.contractNumber === '89859700');
    },
    cartQuantity(_state, getters) {
      return getters.cartItems.reduce((totalQuantity, item) => totalQuantity + item.quantity, 0);
    },
    hasOnlyTarifInCart(_state, getters) {
      return getters.hasTelekomContractInCart && getters.totalMonthlyPrice > 0;
    },
    cartIsEmpty(_state, getters) {
      return getters.cartQuantity === 0;
    },
    /** @typedef {import("./cart-types").CartData} CartData */
    /** @typedef {import("./cart-types").CartItem} CartItem */
    /** @return {CartData | {}} */
    cartData: (state) => state.cart || {},
    /** @return {CartItem[]} */
    cartItems(state) {
      if (!state.cart || !state.cart.items) return [];
      return state.cart.items;
    },
    cartAmount(state) {
      if (!state.cart) return 0;
      return state.cart.amount;
    },
    hasBrodosFreeShipping(state, getters) {
      if (!state.cart?.shippingCostsBrodosDetails) return false;
      return getters.getPriceUntilBrodosFreeShipping === 0;
    },
    getPriceUntilBrodosFreeShipping(state, getters) {
      if (getters.shippingCostsBrodosDetails?.calculatedStandardCosts === 0) return 0;
      return Math.max((getters.shippingCostsBrodosDetails?.standardFreeThreshold || 0) - getters.totalBrodosPrice, 0);
    },
    identTypes(state) {
      if (!state.cart || !state.cart.possibleIdentificationTypes) return [];
      return state.cart.possibleIdentificationTypes.map((identType) => camelCase(identType));
    },
    isKnownOrIdentified(state) {
      if (!state.cart) return false;
      // in case of newCustomer identified is not true
      // if bankIban != undefined the user is "identified"
      return !!(state.cart.tvppIdentifiedCustomer || state.cart.bankIban);
    },
    isKnownCustomer(state) {
      if (!state.cart) return false;
      return state.cart.tvppIdentifiedCustomer;
    },
    checkoutInitUrlSuffix(state, getters) {
      if (!state.cart) return null;
      const { identTypes } = getters;

      if (getters.hasTvppProductsInCart) {
        return CHECKOUT_INIT_URLS.existingCustomer; // @todo: skip when customer is already logged in
      }

      if (!state.cart.tvppDowntime && identTypes) {
        if (identTypes.includes('telekomLogin')) {
          return CHECKOUT_INIT_URLS.existingCustomer;
        } else if (identTypes.includes('upgrade')) {
          return CHECKOUT_INIT_URLS.upgrade;
        }
      }
      return CHECKOUT_INIT_URLS.newCustomer;
    },
    couponCampaignName(state) {
      if (!state.cart || !state.cart.redeemedCouponDescription) return '';
      return state.cart.redeemedCouponDescription;
    },
    couponRedeemed(state) {
      if (!state.cart || !state.cart.redeemedCoupon) return false;
      return state.cart.redeemedCoupon.length > 0;
    },
    couponCodeInStore(state) {
      if (!state.cart) return null;
      return state.couponCode;
    },
    voucherInitialSum(state) {
      if (!state.cart || !state.cart.voucherInitialSum) return 0;
      return state.cart.voucherInitialSum;
    },
    voucherRestSum(state) {
      if (!state.cart || !state.cart.voucherRestSum) return 0;
      return state.cart.voucherRestSum;
    },
    voucherReductionAmount(state) {
      if (!state.cart || !state.cart.voucherReductionAmount) return 0;
      return state.cart.voucherReductionAmount;
    },
    tvppOffline(state) {
      // undefined -> not true because (as long as API call is running)
      // the maintenance message would be already visible
      if (!state.cart) return undefined;
      return state.cart.tvppDowntime;
    },
    possiblePaymentTypes(state) {
      if (!state.cart) return [];
      return state.cart.possiblePaymentTypes || [];
    },
    possibleShippingStrategies(state) {
      if (!state.cart) return [];
      return state.cart.possibleShippingStrategies || [];
    },
    // Hardware Bundles: https://jira.i22.de/browse/LT-8
    hasRegularBundleInCart(state) {
      if (!state.cart) return false;
      return Object.keys(state.bundlesByArticleNumber).length > 0;
    },
    hasTvppProductsInCart(state, getters) {
      return getters.getTvppProducts.length > 0;
    },
    hasOnlyTvppProductsInCart(state, getters) {
      return getters.getTvppProducts.length === getters.cartItems.length;
    },
    getAnonymousCartData(_, getters) {
      const validKeys = [
        'id',
        'orderKey',
        'state',
        'paymentType',
        'shippingCostsTvpp',
        'shippingCostsBrodos',
        'amount',
        'possibleIdentificationTypes',
        'identificationType',
        'bookingRef',
        'qiviconSetupUrl',
        'qiviconActivationCode',
        'redeemedCoupon',
        'voucherRestSum',
        'redeemedCouponType',
        'voucherInitialSum',
        'tvppIdentifiedCustomer',
        'tvppDeletedTariff',
        'redeemedCouponDescription',
        'tvppDowntime',
        'qiviconDowntime',
        'activationCoupon',
        'possiblePaymentTypes',
        'shippingStrategy',
        'shippingCostsBrodosDetails',
        'possibleShippingStrategies',
        'items',
      ];
      return Object.fromEntries(Object.entries(getters.cartData).filter(([key]) => validKeys.includes(key)));
    },
  },
  actions: {
    unsetOrderTokenInvalid(context) {
      context.commit('SET_ORDER_TOKEN_INVALID', undefined);
    },
    setPaymentType(context, paymentType) {
      context.commit('SET_PAYMENT_TYPE', paymentType);
    },
    initCart(context) {
      if (context.state.initialized) return context.state.cart;
      return context.dispatch('fetchCart');
    },
    async clearCart(context) {
      context.commit('SET_CART', DEFAULT_CART_STATE);
      const { data } = await ShopApi.deleteOrder();
      return data;
    },
    async fetchCart(context) {
      const { orderToken } = context.state;

      try {
        /** @var {CartData} data.order */
        const { data } = await ShopApi.getCurrentCart(orderToken);
        const allBundleSlugs = data.order.items
          .filter((i) => i.bundleType === 'brodos')
          .map((i) => i.slug);

        await context.dispatch('queryBrodosBundleSlugs', allBundleSlugs);
        if (orderToken && !data.order.customerNumber) {
          await ShopApi.deleteOrder();
          context.commit('SET_ORDER_TOKEN_INVALID', true);
        }

        context.commit('SET_CART', data.order);
        context.commit('SET_ORDER_TOKEN', undefined);
        return data;
      } catch (_e) { /* lot loaded */ }

      return undefined;
    },
    async updateCartItem(context, { id, quantity }) {
      const { data } = await ShopApi.updateItemInCart({ id, quantity });
      await context.dispatch('fetchCart');
      return data;
    },
    async deleteCartItem(context, { id }) {
      const { data } = await ShopApi.removeItemFromCart({ id });
      await context.dispatch('fetchCart');
      return data;
    },
    addCouponCodeToStore(context, couponCode) {
      context.commit('ADD_COUPON_CODE_TO_STORE', couponCode);
    },
    async addCartItem(context, payload) {
      let quantity = payload.quantity || 1;
      const articleNumber = payload.articleNumber || null;
      const contractNumber = payload.contractNumber || null;
      const existingItem = context.getters.cartItems.find(
        (cartItem) =>
          cartItem.articleNumber === articleNumber && cartItem.contractNumber === contractNumber,
      );
      try {
        let response;
        if (existingItem) {
          quantity += existingItem.quantity;
          if (quantity <= 0) {
            response = await ShopApi.removeItemFromCart({ id: existingItem.id });
          } else {
            response = await ShopApi.updateItemInCart({
              id: existingItem.id,
              quantity,
            });
          }
        } else {
          response = await ShopApi.addItemToCart(
            {
              articleNumber,
              contractNumber,
              quantity,
            },
            payload.salesOrganizationID,
          );
        }

        await context.dispatch('fetchCart');
        return response.data;
      } catch (error) {
        // handle mixed_cart_not_allowed error
        if (context.getters.isTariffUpgradeFlow && hasError(error, 'base', 'mixed_cart_not_allowed')) {
          await ShopApi.deleteOrder();
          return context.dispatch('addCartItem', payload);
        } else {
          throw error;
        }
      }
    },
    async addItemsToCart(context, { items, salesOrganizationID }) {
      items = items.map((item) => ({ quantity: 1, ...item }));
      const response = await ShopApi.addItemsToCart(items, salesOrganizationID);
      await context.dispatch('fetchCart');
      // As we don't get a 422 status code here when a part of the cart
      // manipulation went wrong, we have to determine if there were any
      // errors on our own.
      const { errors } = response.data;
      if (errors && !isEmpty(errors)) {
        if (context.getters.isTariffUpgradeFlow && hasError({ response }, 'base', 'mixed_cart_not_allowed')) {
          await ShopApi.deleteOrder();
          return context.dispatch('addItemsToCart', { items, salesOrganizationID });
        }
        const error = new Error('Unable to add all requested items to the cart');
        error.response = response;
        error.errors = errors;
        throw error;
      }
      return response.data;
    },
    async cartAddCoupon(context, { redeemedCoupon }) {
      const { data } = await ShopApi.addCouponToCart({ redeemedCoupon });
      await context.dispatch('fetchCart');
      return data;
    },
    async updateCustomerData(context, order) {
      const { data } = await ShopApi.updateCustomerData(order);
      await context.dispatch('fetchCart');
      return data;
    },
    identifyCustomer(context, order) {
      return ShopApi.identifyCustomer(order);
    },
    resetCustomer() {
      return ShopApi.resetCustomer();
    },
    setShippingStrategy(context, shippingStrategy) {
      context.commit('SET_SHIPPING_STRATEGY', shippingStrategy);
    },
    async cartAddCouponAndItem(context, { redeemedCoupon, contractNumber, salesOrganizationID }) {
      const response = await ShopApi.addCouponAndItem(
        redeemedCoupon,
        contractNumber,
        salesOrganizationID,
      );
      await context.dispatch('fetchCart');
      return response;
    },
    async deleteCoupon(context) {
      try {
        const response = await ShopApi.deleteCoupon();
        context.commit('SET_CART', response.data.order);
      } catch (_e) { /* no-op */ }
    },
    async queryBrodosBundleSlugs(context, bundleSlugs) {
      // Read all Products from bundle
      const bundleUpdatesList = await Promise.all(
        bundleSlugs.map(async (bundleSlug) => {
          if (context.state.bundlesByArticleNumber[bundleSlug]) return [];

          try {
            const productBundle = await fetchOfferBySlugs({ productSlug: bundleSlug });
            if (!productBundle.product) return [];

            const { articleNumber, bundledProducts } = productBundle.product;
            if (!bundledProducts) return [];

            const itemsGroupedByAN = groupBy(bundledProducts, 'articleNumber');
            const countedItems = Object.values(itemsGroupedByAN).map((group) => ({
              ...group[0],
              quantity: group.length,
            }));

            return [articleNumber, countedItems];
          } catch (_e) {
            return [];
          }
        }),
      );

      // [[], ['some', 'entry'] => {'some': 'entry'}
      const bundleUpdates = fromPairs(filter(bundleUpdatesList, (l) => !isEmpty(l)));

      if (isEmpty(bundleUpdates)) return;
      context.commit('ADD_PRODUCT_BUNDLES', bundleUpdates);
    },
  },
};

import cloneDeep from 'lodash/cloneDeep';
import { mode } from '@/lib/client-env';
import { slugify } from '@i22-td-smarthome/component-library';

let utagTimeout = null;
let iteration = 0;

export const findUtag = (resolve, reject) => {
  // server-side
  if (typeof window === 'undefined') {
    reject();
    return;
  }
  if (utagTimeout) {
    clearTimeout(utagTimeout);
  }
  if (window.utag) {
    resolve(window.utag);
    return;
  }
  if (window.utagError) {
    reject(new Error('Utag has been blocked'));
    return;
  }
  if (iteration > 10) {
    reject(new Error('Utag has not been loaded in 10 seconds'));
    return;
  }

  iteration += 1;
  utagTimeout = setTimeout(() => {
    resolve(new Promise(findUtag));
  }, 1000);
};

export async function track(action, data) {
  try {
    const utag = await new Promise(findUtag);
    if (!utag) return undefined;
    // We need to clone data as Tealium manipulates the object and therefore may
    // be able to change data in our components!
    const clonedData = cloneDeep(data);
    ['page_content_id', 'wt_link_id'].forEach((key) => {
      if (!clonedData[key]) return;
      clonedData[key] = slugify(clonedData[key], true);
    });

    if (mode.isDevelopment) {
      // eslint-disable-next-line no-console
      console.warn('Tealium', action, clonedData);
    }

    return utag[action](clonedData);
  } catch (error) {
    if (mode.isDevelopment) {
      // eslint-disable-next-line no-console
      console.info(error.message);
    }
    return undefined;
  }
}

<template>
  <div class="footer-awards">
    <div class="grid">
      <div class="grid__item grid__item--12">
        <heading class="footer-awards__title" tag="span" size="sm" theme="light" text-uppercase>
          Wir sind ausgezeichnet
        </heading>
        <ul class="footer-awards__list">
          <li v-for="(item, index) in awards" :key="index" class="footer-awards__item">
            <a v-if="item.link" :href="item.link" :target="item.target" rel="noopener">
              <CdnImageElement
                class="footer-awards__figure"
                :style="item.style"
                :alt="item.alt"
                :src="item.image"
                render-without-figure
                lazy
              />
            </a>
            <CdnImageElement
              v-else
              class="footer-awards__figure"
              :style="item.style"
              :alt="item.alt"
              :src="item.image"
              render-without-figure
              lazy
            />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import CdnImageElement from '@/components/cdn-image-element';
import { Heading } from '@i22-td-smarthome/component-library';

const smarthomeAward = {
  alt: 'SmartHome Award',
  image:
    '/api/content/images/award/2d9978262f1ef1e9408518c54247b502/world-smart-home-awards-winner@2x.png',
  link: null,
};
const awards = [smarthomeAward];

export default {
  name: 'FooterAward',
  components: {
    CdnImageElement,
    Heading,
  },
  data() {
    return {
      awards,
    };
  },
};
</script>
<style lang="scss" scoped>
@import 'assets/base';

.footer-awards {
  padding: $grid-base * 2 $grid-base;
  @include mq($mq-large) {
    padding-top: $grid-base * 3;
    padding-bottom: $grid-base * 7;
  }

  &__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    list-style: none;
    align-items: center;
    margin: 0 auto;
    gap: $grid-base * 1.5;
    @include mq($mq-sm) {
      justify-content: center;
      flex-wrap: nowrap;
      gap: $grid-base * 2.5;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 6px;
    width: calc(50% - #{$grid-base * 2.5});
    justify-content: center;
    @include mq($mq-sm) {
      width: auto;
    }
  }

  &__title {
    text-align: left;
    padding-bottom: $grid-base * 1.5;
    @include mq($mq-medium) {
      text-align: center;
    }
  }

  &__figure {
    height: 100px;
    width: 100px;
    object-fit: contain;
  }
}
</style>

<template>
  <span
    v-if="hasMultipleVariants"
    class="alternative-offers-count"
  >
    + {{ additionalCount }} weitere {{ pluralOrSingularNoun }}
  </span>
</template>

<script>
import { flatMap } from 'lodash';

export default {
  name: 'AlternativeOffersCount',
  props: {
    productAlternatives: {
      type: Array,
      required: true,
    },
  },
  computed: {
    pluralOrSingularNoun() {
      return this.additionalCount === 1 ? 'Variante' : 'Varianten';
    },
    hasMultipleVariants() {
      return this.additionalCount > 0;
    },
    additionalCount() {
      const alternativeCount = flatMap(
        this.productAlternatives,
        (productAlternative) => productAlternative.properties,
      )
        .map((property) => property.alternatives.length)
        .reduce((a, b) => a * b);

      const activeItemCount = 1;

      return alternativeCount - activeItemCount;
    },
  },
};
</script>

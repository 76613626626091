import cookie from 'cookie';

function simpleDate(date) {
  return date.toISOString().replace(/T.*/, '');
}

function currentTardisCoordinates(request) {
  let cookieString = '';
  if (process.client) {
    cookieString = document.cookie || '';
  } else if (request) {
    cookieString = request.headers.cookie || '';
  }
  const { tardisCoordinates } = cookie.parse(cookieString);
  return tardisCoordinates || simpleDate(new Date());
}

export {
  simpleDate,
  currentTardisCoordinates,
};

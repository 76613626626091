import parameterize from '@/lib/parameterize';
import dasherize from '@/lib/dasherize';

export default {
  filters: {
    parameterize(str) {
      return parameterize(str);
    },
    dasherize(str) {
      return dasherize(str);
    },
  },
};

import axios from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import { baseUrl, hostingAuth } from '@/lib/client-env';

export function buildPlainClient(options = {}) {
  const newOptions = cloneDeep(options);
  if (baseUrl) {
    newOptions.baseURL = baseUrl;
  }
  if (hostingAuth) {
    newOptions.auth = hostingAuth;
  }
  return axios.create(newOptions);
}

export function buildJsonClient() {
  return buildPlainClient({
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
}

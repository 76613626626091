const JUBELMEDIA_PIXEL_ID = '454676535324099';

/**
 * FB Tracker with queue.
 * https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking
 * List of all Events: https://developers.facebook.com/docs/facebook-pixel/reference
 *
 * To use this FB event:
 * fbq('track', 'Purchase', {currency: "USD", value: 30.00})
 * call this:
 * this.trackFBEvent('Purchase', { currency: 'EUR', value: 0.00 });
 */

function hasWindow() {
  return typeof window !== 'undefined';
}

let fbTrackingTimeout = null;
let trackingQueue = [];
let fbInitialized = false;

const consumeQueue = (iteration = 0) => {
  if (!fbInitialized) return; // wait until 'init' event was send
  if (fbTrackingTimeout) {
    clearTimeout(fbTrackingTimeout);
  }
  if (iteration > 10) return;

  const isConsentSet = window.utag?.gdpr?.getConsentState() > 0; // -1 = declined, 0 = pending, 1 = accepted
  const isConsentGiven = window.utag?.gdpr?.getCategories().includes('social');
  const isFbqAvailable = typeof window.fbq === 'function';
  // no consent given or fb not loaded, wait for 1s and try again.
  if (!isConsentSet || !isConsentGiven || !isFbqAvailable) {
    fbTrackingTimeout = setTimeout(() => {
      consumeQueue(iteration + 1);
    }, 1000);
    return;
  }
  trackingQueue.forEach(({ event, payload }) => {
    if (event === 'init') {
      window.fbq(event, JUBELMEDIA_PIXEL_ID);
      return;
    }

    window.fbq('trackSingle', JUBELMEDIA_PIXEL_ID, event, payload);
  });
  trackingQueue = [];
};

export default {
  created() {
    if (!hasWindow()) return;
    window.addEventListener('consentChanged', this.initializeFBTracking);
    window.addEventListener('load', this.initializeFBTracking);
  },
  destroyed() {
    if (!hasWindow()) return;
    window.removeEventListener('consentChanged', this.initializeFBTracking);
    window.removeEventListener('load', this.initializeFBTracking);
  },
  methods: {
    trackFBEvent(event, payload = null) {
      if (!hasWindow()) return;
      trackingQueue.push({
        event,
        payload,
      });
      consumeQueue();
    },
    initializeFBTracking() {
      // run initialize only once
      if (fbInitialized) return;
      fbInitialized = true;
      // fire init at first.
      trackingQueue.unshift({ event: 'init', payload: null }, { event: 'PageView', payload: null });
      consumeQueue();
    },
  },
};

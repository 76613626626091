import { cloudFront } from '@/lib/client-env';

// This library exports a single function that
// 1. takes an absolute path to a resource
// and depending on cloudfront.enables returns
// 2a. either the same path
// 2b. or an url (without protocol) constructed
//     out of cloudfront.host and the given path
//
// How it works:
//  1. Browser requests HTML from www.xyz.de
//  2. Our server returns HTML that uses images from
//     cdn.xyz.de
//  3. Browser requests images from cdn.xyz.de,
//     which is owned by cloudfront.
//  4. Cloudfront either has the image cached (6) or
//  5. Cloudfront requests image from our server
//  6. Cloudfront returns image to browser.
//
//  ┌────────────┐
//  │            ╞══════════════╗
//  │   Browser  │              ║
//  │            │<═══════╗     ║
//  └───╥────────┘        ║     ║
//      ║    ^            ║     ║
//      ║    ║            ║     ║
//     (1)  (2)          (6)   (3)
//      ║    ║            ║     ║
//      V    ║            ║     V
//  ┌────────╨───┐     ┌──╨──────────┐
//  │            ╞════>│     (4)      │
//  │ www.xyz.de │ (5) │  cdn.xyz.de  │
//  │       @i22 │<════╡  @cloudfront │
//  └────────────┘     └──────────────┘

let assetBaseUrl = '';

if (cloudFront && cloudFront.enabled && cloudFront.host) {
  assetBaseUrl = `//${cloudFront.host}`;
}

export const cdnUrl = assetBaseUrl;

// "path" can pass a string and an array for multiple sources (example: webp & png)
export default function assetUrl(path) {
  const sources = [];
  const getAllImageFiles = /(\/.*?\.(\w+))(,|$|\s)/gi;

  const replaceSourcePath = (item) => item.replace(getAllImageFiles, (imagePath) => `${assetBaseUrl}${imagePath}`);

  if (!path) {
    return null;
  }
  if (typeof path === 'string') {
    return replaceSourcePath(path);
  }
  path.forEach((src) => {
    sources.push(replaceSourcePath(src));
  });
  return sources;
}

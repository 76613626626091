<template>
  <footer class="footer-depth-fix">
    <div class="page-footer">
      <div
        v-if="standardTheme"
        class="page-footer__wrapper grid"
      >
        <FooterAccordion
          headline="Wir helfen dir weiter"
        >
          <ul class="footer-accordion__list list">
            <li class="list__element list__element--icon">
              <a
                href="tel:08003301000"
                class="link--secondary"
              >
                <SvgIcon
                  :image="IconPhone"
                  class="icon list__icon"
                />
                0800 330 1000 (kostenlos)
              </a>
            </li>
            <li class="list__element list__element--icon">
              <a
                href="https://www.facebook.com/telekomhilft/"
                target="_blank"
                rel="noopener"
                class="link--secondary"
              >
                <SvgIcon
                  :image="IconFacebook"
                  class="icon list__icon"
                />
                Telekom hilft auf Facebook
              </a>
            </li>
            <li class="list__element list__element--icon">
              <a
                href="https://twitter.com/telekom_hilft?lang=de"
                target="_blank"
                rel="noopener"
                class="link--secondary"
              >
                <SvgIcon
                  :image="IconTwitter"
                  class="icon list__icon"
                />
                Telekom hilft auf Twitter
              </a>
            </li>
            <li class="list__element list__element--icon">
              <a
                href="https://telekomhilft.telekom.de/"
                target="_blank"
                rel="noopener"
                class="link--secondary"
              >
                <SvgIcon
                  :image="IconTelekomHilft"
                  class="icon list__icon"
                />
                Telekom hilft Community
              </a>
            </li>
          </ul>
        </FooterAccordion>
        <FooterAccordion
          headline="Newsletter"
        >
          <SvgIcon
            :image="IconNewsletter"
            class="icon footer-accordion__icon"
          />
          <ul class="footer-accordion__list list">
            <li class="list__element list__element--dashed">
              Praktische Tipps
            </li>
            <li class="list__element list__element--dashed">
              Aktuelle Angebote
            </li>
            <li class="list__element list__element--dashed">
              Exklusive Informationen
            </li>
          </ul>
          <p class="footer-accordion__text">
            Jetzt
            <a
              href="/newsletter"
              title="anmelden"
              data-tealium-rel="content.text.anmelden"
              class="footer-accordion__text--underline"
            >anmelden</a> und kostenlos informiert werden!
          </p>
        </FooterAccordion>
        <!-- add all link list from data -->
        <FooterAccordion
          v-for="list in linklists"
          :key="list.id"
          :headline="list.name"
        >
          <FooterLinklist
            :links="list.links"
          />
        </FooterAccordion>

        <FooterAccordion
          headline="Zahlungsarten"
        >
          <FooterPayment />
        </FooterAccordion>
      </div>
      <FooterAdvantage />
      <FooterAward />
      <FooterCorporate />
    </div>
  </footer>
</template>

<script>
import FooterAccordion from '@/components/footer-accordion';
import FooterCorporate from '@/components/footer-corporate';
import FooterLinkData from '@/assets/01-settings/js/footer-link-data';
import FooterLinklist from '@/components/footer-linklist';
import FooterAdvantage from '@/components/footer-advantage';
import FooterPayment from '@/components/footer-payment';
import FooterAward from '@/components/footer-award';

// SVG ICON
import IconAvTest from '@/assets/images/av-test.svg';
import IconFacebook from '@/assets/images/facebook.svg';
import IconNewsletter from '@/assets/images/newsletter.svg';
import IconPhone from '@/assets/images/phone.svg';
import IconShop from '@/assets/images/telekom-shop.svg';
import IconTelekomHilft from '@/assets/images/telekom-hilft.svg';
import IconTwitter from '@/assets/images/twitter.svg';

const POSSIBLE_THEMES = ['standard', 'small'];

export default {
  name: 'PageFooter',
  components: {
    FooterAccordion,
    FooterCorporate,
    FooterLinklist,
    FooterAdvantage,
    FooterPayment,
    FooterAward,
  },
  props: {
    theme: {
      type: String,
      default: 'standard',
      validator: (value) => (POSSIBLE_THEMES.indexOf(value) > -1),
    },
  },
  data() {
    return {
      IconShop,
      IconNewsletter,
      IconPhone,
      IconFacebook,
      IconTwitter,
      IconTelekomHilft,
      IconAvTest,
    };
  },
  computed: {
    linklists() {
      return FooterLinkData.categories;
    },
    smallTheme() {
      return this.theme === 'small';
    },
    standardTheme() {
      return this.theme === 'standard';
    },
  },
};


</script>

<style lang="scss">
  @import '../assets/base';

  .footer-depth-fix {
    z-index: 1;
    position: relative;
  }

  .page-footer {
    background-color: color(grey, 10000);
    @include mq($mq-large) {
      padding-top: 5 * $grid-base;
    }

    .shop-finder {
      margin-top: $grid-medium-gutter;
    }
    .footer-accordion__image{
      &--av-logo {
        height: 65px;
      }
      &--left {
        width: 141px;
      }
    }
    &__header {
      background: linear-gradient(0deg, #FFFFFF 0%, #FBFBFB 51.99%, #F6F6F6 100%);
      padding: $grid-base*3 0;
      @include mq($mq-medium) {
        padding: $grid-base*5 0;
      }
      @include mq($mq-large) {
        padding: $grid-base*7 0;
      }
    }
    &__wrapper {
      padding: $grid-base*6 $grid-base 0 $grid-base;
      @include mq($mq-large) {
        padding: 0;
      }
    }
  }

</style>

<template>
  <div>
    <div class="dialog-content__header" :style="{'text-align': isTariffUpgradeFlow && 'center'}">
      <SvgIcon
        :image="successIcon"
        class="icon dialog-container__icon dialog-container__icon--success"
      />
      <strong>{{ item.name }}</strong>
      wurde in den Warenkorb gelegt.
    </div>
    <div
      v-if="contractNumber && isTariffUpgradeFlow"
      class="dialog-container-tariff-note"
      data-testid="tariff-note"
    >
      <span class="dialog-container-tariff-note__text">Schließe nun die Bestellung ab.</span>
      <span class="dialog-container-tariff-note__text">
        <SvgIcon :image="infoIcon" class="icon dialog-container-tariff-note__icon" />
        Smarte Geräte kannst du nach der erfolgreichen Bestellung von
        <span class="dialog-container-tariff-note__tariff-name">MagentaZuhause App Pro</span>
        kaufen.
      </span>
    </div>
    <div v-if="additionalNote" class="dialog-content__add-to-cart-message">
      <span v-html="additionalNote" />
    </div>
    <div
      v-if="!hasBrodosFreeShipping && !(isTariffUpgradeFlow && hasTelekomContractInCart)"
      class="grid grid--center"
      data-testid="shipping-info"
    >
      <ShippingCostsContainer
        :text-variant="'dynamic'"
        class="dialog-content__shipping-infobox grid__item grid__item--12 grid__item--m-8 grid__item--l-6 grid__item--xl-4"
      />
    </div>
    <div class="dialog-content__buttons grid grid--center">
      <div class="grid__item grid__item--12 grid__item--m-4 grid__item--l-3 grid__item--xl-2">
        <button
          v-if="isTariffUpgradeFlow && hasTelekomContractInCart"
          ref="continueLink"
          class="btn btn--full btn--primary dialog-content__button"
          @click="closeAndNavigateTo('/shop/kundenformular/upgrade-identifikation')"
        >
          Zur Kasse
        </button>
        <BlockLink
          v-else
          button-style="-transparent dialog-content__button"
          full-width
          label="Warenkorb ansehen"
          page-to="/shop/checkout/warenkorb"
        />
      </div>
      <div
        v-if="!(isTariffUpgradeFlow && hasTelekomContractInCart)"
        class="grid__item grid__item--12 grid__item--m-4 grid__item--l-3 grid__item--xl-2"
      >
        <button
          ref="continueLink"
          class="btn btn--full btn--primary dialog-content__button"
          @click="closeFlyout"
        >
          Weiter einkaufen
        </button>
      </div>
    </div>

    <div class="dialog-content__buttons grid grid--center">
      <div
        :class="{ 'grid__item--spacing-2': hasRecommendations }"
        class="grid__item grid__item--12"
      ></div>
    </div>
    <template v-if="hasRecommendations">
      <h3 class="text--h3 text--default-color">
        Vielleicht interessierst du dich auch für…
      </h3>
      <OfferTiles :offers="truncatedRecommendedOffers" class="dialog-container__recommendations" />
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DialogContent from '@/mixins/dialog-content';
import { itemAddedToCartContent } from '@/lib/content-data';
import successIcon from '@/assets/images/check.svg';
import infoIcon from '@/assets/images/info-icon.svg';

import OfferTiles from '@/components/offer-tiles';
import BlockLink from '@/components/block-link';
import ShippingCostsContainer from '@/components/shipping-costs/shipping-costs-container';

export default {
  name: 'AddToCartDialogContent',
  components: {
    OfferTiles,
    BlockLink,
    ShippingCostsContainer,
  },
  mixins: [
    DialogContent({
      item: null,
      recommendedOffers: [],
    }),
  ],
  data() {
    return {
      successIcon,
      infoIcon,
      additionalNote: null,
    };
  },
  computed: {
    ...mapGetters('cart', [
      'checkoutInitUrlSuffix',
      'cartData',
      'hasBrodosFreeShipping',
      'hasTelekomContractInCart',
      'isTariffUpgradeFlow',
    ]),
    articleNumber() {
      return this.item.articleNumber;
    },
    contractNumber() {
      return this.item.contractNumber;
    },
    hasRecommendations() {
      return this.recommendedOffers.length > 0;
    },
    truncatedRecommendedOffers() {
      return this.recommendedOffers.slice(0, 4);
    },
  },
  async mounted() {
    this.$nextTick(() => {
      this.$refs.continueLink?.focus();
    });
    await this.getAdditionalNote();
  },
  methods: {
    async closeAndNavigateTo(path) {
      await this.$dialog.hide();
      return this.$router.push(path);
    },
    closeFlyout() {
      if (this.$route.path !== '/shop') {
        this.$router.push('/shop');
      }
      this.$dialog.hide();
    },
    async getAdditionalNote() {
      if (this.$config.minimalMode) return; // do not load notes when minimal mode is active
      let content;

      try {
        content = await itemAddedToCartContent(this.item);
        if (!content || !content.additionalNote || this.hasTelekomContractInCart) {
          this.additionalNote = null;
          return;
        }
        this.additionalNote = content.additionalNote.join('');
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.dialog-container-tariff-note {
  display: flex;
  flex-direction: column;

  &__tariff-name {
    white-space: nowrap;
  }

  &__text {
    color: #262626;
    padding-bottom: 12px;
  }

  &__icon {
    border: 1px solid #000;
    padding: 2px;
    margin-right: 5px;
    width: 16px;
    height: 16px;
    transform: translateY(2px);
  }
}
</style>

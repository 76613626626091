import merge from 'lodash/merge';
import Url from 'url';
import { plainClient, clientWithLoadingIndicator as axios } from '@/lib/axios-client/shop-api';
import {
  mode,
  baseUrl,
  shopBackend,
  shopConfirmationUrl,
  shopErrorUrl,
  telekomLoginSuccessUrl,
  telekomLoginFailureUrl,
} from '@/lib/client-env';

// See: https://wiki.i22.de/display/TDSMARTHOME/Shop+Api+Calls
function endpoints() {
  const backendApiUrl = baseUrl + shopBackend.apiLocalPath;
  return {
    order: `${backendApiUrl}/order`,
    bundleItems: `${backendApiUrl}/bundle_items`,
    confirmation: `${backendApiUrl}/confirmation`,
    downtimes: `${backendApiUrl}/downtimes`,
    coupon: `${backendApiUrl}/coupon`,
    couponItems: `${backendApiUrl}/coupon_items`, // we should rename this oO
    identification: `${backendApiUrl}/identification`,
    items: `${backendApiUrl}/items`,
    orderItems: `${backendApiUrl}/order/items`,
    item: (id) => `${backendApiUrl}/items/${Number(id)}`,
    homebaseUpgrade: `${backendApiUrl}/homebase_upgrade`,
    multipleItems: `${backendApiUrl}/multiple_items`,
    customerReset: `${backendApiUrl}/reset_customer`,
    faqCategory: `${backendApiUrl}/faq/category`,
    faqSearch: `${backendApiUrl}/faq/search`,
    tvppParameter: `${backendApiUrl}/tvpp_parameter`,
    shacUpgrade: `${backendApiUrl}/shac_validate_upgrade`,
    entrenceData: `${backendApiUrl}/entrance`,
    availabilitySubscriptions: `${backendApiUrl}/availability_subscriptions`,
    availabilitySubscriptionsConfirmation: (confirmationToken) =>
      `${backendApiUrl}/availability_subscriptions/${String(confirmationToken)}/confirm`,
    // FIXME: move away
    searchBySection: `${baseUrl}/api/search_by_section`,
    payPalExpressCheckout: `${baseUrl}/api/paypal_checkouts/checkout`,
    payPalExpressCheckoutDetails: `${baseUrl}/api/paypal_checkouts/checkout_details`,
    walletPaymentsCheckout: `${baseUrl}/api/wallet_payments/checkout`,
    validate3DSecure: `${baseUrl}/api/wallet_payments/validate_3d_secure`,
  };
}

// We explicitly list the callbacks that the ShopApi Backend will pass to Brodos
// and which in turn will be called by the browser after the payment process has
// has reached certain checkpoints
const brodosAfterPaymentUrls = {
  brodosSuccessUrl: Url.resolve(baseUrl, shopBackend.brodosResponseSuccessLocalPath),
  brodosFailureUrl: Url.resolve(baseUrl, shopBackend.brodosResponseFailureLocalPath),
  confirmationUrl: Url.resolve(baseUrl, shopConfirmationUrl),
  failureUrl: Url.resolve(baseUrl, shopErrorUrl),
};

function addAfterPaymentUrls(orderParams) {
  // TODO: still needed?!
  if (mode.isProduction) {
    return orderParams;
  }
  return merge({}, orderParams, brodosAfterPaymentUrls);
}

// We explicitly list the callbacks that the ShopApi Backend will pass to Telekom Login
// and which in turn will be called by the browser after the login process has
// has reached certain checkpoints
const telekomLoginUrls = {
  confirmationUrl: Url.resolve(baseUrl, telekomLoginSuccessUrl),
  failureUrl: Url.resolve(baseUrl, telekomLoginFailureUrl),
};

function addTelekomLoginUrls(orderParams) {
  if (mode.isProduction) {
    return orderParams;
  }
  return merge({}, orderParams, telekomLoginUrls);
}

const apiCalls = {
  downtimes() {
    return axios.get(endpoints().downtimes);
  },
  availabilitySubscriptions(subscription) {
    return axios.post(endpoints().availabilitySubscriptions, { subscription });
  },
  async availabilitySubscriptionsConfirmation(confirmationToken) {
    return axios.patch(endpoints().availabilitySubscriptionsConfirmation(confirmationToken), {});
  },
  submitOrder(orderParams) {
    const paramsWithAfterPaymentUrls = addAfterPaymentUrls(orderParams);
    return axios.post(endpoints().order, { order: paramsWithAfterPaymentUrls });
  },
  confirmOrder() {
    return axios.get(endpoints().confirmation);
  },
  deleteOrder() {
    return axios.delete(endpoints().order);
  },
  deleteCoupon() {
    return axios.delete(endpoints().coupon);
  },
  homebaseUpgrade(serialNumber, emailAddress) {
    return axios.post(endpoints().homebaseUpgrade, { serialNumber, emailAddress });
  },
  getCurrentCart(oderToken = undefined) {
    return plainClient.get(
      endpoints().order,
      oderToken ? { headers: { 'X-Order-Token': oderToken } } : undefined,
    );
  },
  async addBundleToCart(bundle) {
    let response;
    try {
      response = await axios.post(endpoints().bundleItems, {
        item: bundle,
      });
    } catch (error) {
      console.error('Error while putting bundleItem in cart: ', error.response);
    }
    return response;
  },
  addItemToCart({ quantity, articleNumber, contractNumber }, salesOrganizationID) {
    return axios.post(endpoints().items, {
      item: { quantity, articleNumber, contractNumber },
      order: { vo: salesOrganizationID },
    });
  },
  updateItemInCart({ id, quantity }) {
    return axios.patch(endpoints().item(id), { quantity });
  },
  clearCart() {
    // Removes all items from cart without destroying the order
    return axios.delete(endpoints().orderItems);
  },
  removeItemFromCart({ id }) {
    return axios.delete(endpoints().item(id));
  },
  addItemsToCart(items, salesOrganizationID) {
    return axios.post(endpoints().multipleItems, { items, order: { vo: salesOrganizationID } });
  },
  addCouponToCart({ redeemedCoupon }) {
    return axios.patch(endpoints().coupon, { order: { redeemedCoupon } });
  },
  updateCustomerData(order) {
    return axios.patch(endpoints().order, { order });
  },
  identifyCustomer(orderParams) {
    const paramsWithTelekomLoginUrls = addTelekomLoginUrls(orderParams);
    return axios.patch(endpoints().identification, { order: paramsWithTelekomLoginUrls });
  },
  resetCustomer() {
    return axios.patch(endpoints().customerReset, { order: { reset_tvpp_customer: true } });
  },
  addCouponAndItem(redeemedCoupon, contractNumber, salesOrganizationID) {
    return axios.post(endpoints().couponItems, {
      item: { contractNumber, quantity: 1 },
      order: { redeemedCoupon, vo: salesOrganizationID },
    });
  },
  fetchFaqForCategory(category) {
    return axios.post(endpoints().faqCategory, { q: category });
  },
  fetchFaqForSearchTerm(searchTerm) {
    return axios.post(endpoints().faqSearch, { q: searchTerm });
  },
  getTvppParameter() {
    return axios.get(endpoints().tvppParameter);
  },
  getShacUpgradeParameter(buchungsref, signature) {
    return axios.patch(endpoints().shacUpgrade, { buchungsref, signature });
  },
  /**
   * @param {import("@/helpers/apple-pay-types").Response} payload
   * @return Promise<{*}>
   */
  postWalletPaymentInformation(payload) {
    const failureUrl = new URL(
      `/shop/checkout/warenkorb?expressCheckoutFailure=${payload.payment_type || 'apple_pay'}`,
      baseUrl,
    ).href;
    return axios.post(endpoints().walletPaymentsCheckout, {
      payment_shop_provider_return_url: new URL('/shop/checkout/zusammenfassung', baseUrl).href,
      payment_shop_provider_cancel_url: failureUrl,
      ...payload,
    });
  },
  requestExpressCheckoutPayPal({ paymentType = 'paypal_express' }) {
    const failureUrl = new URL(
      `/shop/checkout/warenkorb?expressCheckoutFailure=${paymentType}`,
      baseUrl,
    ).href;
    return axios.post(endpoints().payPalExpressCheckout, {
      payment_type: paymentType,
      payment_shop_success_url: new URL('/shop/checkout/zusammenfassung', baseUrl).href,
      payment_shop_failure_url: failureUrl,
      payment_shop_provider_return_url: new URL('/api/paypal_checkouts/checkout_details', baseUrl)
        .href,
      payment_shop_provider_cancel_url: failureUrl,
    });
  },
  validate3DSecure(params) {
    return axios.post(endpoints().validate3DSecure, params);
  },
};

export default apiCalls;
export { endpoints };

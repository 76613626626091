<template>
  <span class="shipping-costs-info shipping-costs-info__wrapper">
    <span v-if="isCurrentVariant('dynamic')">
      <span v-if="!hasBrodosFreeShipping">
        Für einen kostenlosen Standardversand weitere Produkte im Wert vom
        <span class="shipping-costs-info__rest-price">
          {{ getPriceUntilBrodosFreeShipping | toHumanPriceFromCents }}
        </span>
        <span class="shipping-costs-info__group-nowrap">hinzufügen.
          <slot />
        </span>
      </span>
    </span>
    <span v-else-if="isCurrentVariant('footer')">
      Versandkostenfrei ab {{ standardFreeThreshold | toHumanPriceFromCents }}
      <slot />
    </span>
    <span v-else-if="isCurrentVariant('emptyCart')">
      Jetzt ab {{ standardFreeThreshold | toHumanPriceFromCents }} Bestellwert versandkostenfrei
      bestellen!
      <slot />
    </span>
    <span v-else-if="isCurrentVariant('warenkorb') && showShippingCosts">
      Versand ab {{ standardFreeThreshold | toHumanPriceFromCents }} Bestellwert kostenfrei
      <slot />
    </span>
    <span v-else-if="isCurrentVariant('tooltip')">
      <span v-if="hasBrodosFreeShipping"> Kostenloser Versand </span>
      <span v-else>
        Noch
        <span class="shipping-costs-info__rest-price">
          {{ getPriceUntilBrodosFreeShipping | toHumanPriceFromCents }}
        </span>
        bis zum kostenlosen Versand.
      </span>
    </span>
    <span v-else-if="isCurrentVariant('paymentOptions')">
      Versandkostenfrei ab {{ standardFreeThreshold | toHumanPriceFromCents }}
      <slot />
    </span>
    <span v-else-if="isCurrentVariant('generic')">
      Bei einem Bestellwert unter {{ standardFreeThreshold | toHumanPriceFromCents }} fallen
      Versandkosten in Höhe von {{ standardCosts | toHumanPriceFromCents }} an.
    </span>
  </span>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ShippingConstsInfo',
  props: {
    textVariant: {
      type: String,
      required: false,
      default: 'generic',
    },
    prefferedDay: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters('cart', [
      'shippingCostsBrodosDetails',
      'hasBrodosFreeShipping',
      'hasBrodosProductsInCart',
      'cartIsEmpty',
      'getPriceUntilBrodosFreeShipping',
    ]),
    isCurrentVariant() {
      return (mode) => this.textVariant === mode;
    },
    standardFreeThreshold() {
      if (!this.shippingCostsBrodosDetails) return 0;
      return this.shippingCostsBrodosDetails.standardFreeThreshold || 0;
    },
    standardCosts() {
      return this.shippingCostsBrodosDetails?.standardCosts || 0;
    },
    preferredDayCosts() {
      return this.shippingCostsBrodosDetails?.preferredDayCosts || 0;
    },
    showShippingCosts() {
      return this.cartIsEmpty || this.hasBrodosProductsInCart;
    },
  },
};
</script>
<style lang="scss">
@import 'assets/base';

.shipping-costs-info {
  display: inline-flex;

  &__group-nowrap {
    white-space: nowrap;
  }

  &__rest-price {
    color: $color-primary;
    font-weight: 700;
  }

  &__preffered-day {
    padding-top: $grid-base;
    padding-bottom: $grid-base;
  }
}
</style>

<template>
  <div
    :class="`branding branding--${theme}`"
  >
    <div class="branding__wrapper">
      <nuxt-link
        :to="indexPage"
        title="Zur Startseite"
        data-tealium-rel="navi-top.image.logo"
        class="branding__logo"
      >
        <svg-icon
          :image="logo"
          class="icon icon--logo"
        />
      </nuxt-link>
    </div>
  </div>
</template>

<script>
import logo from '@/assets/images/logo.svg';

export default {
  name: 'Branding',
  props: {
    theme: {
      type: String,
      default: 'header',
      validator(value) {
        return ['footer', 'header'].includes(value);
      },
    },
  },
  data() {
    return {
      logo,
      indexPage: {
        name: 'index',
      },
    };
  },
};
</script>

<style lang="scss">
  @import '../assets/base';

  .branding {
    background-color: $color-primary;
    height: var(--branding-height);
    padding: 0 12px;

    @include mq($mq-md) {
      padding: #{calc($grid-medium-gutter / 2)} $grid-medium-gutter;

      &--spaceless {
        padding: 0;
      }
    }

    &--footer {
      position: relative;
      top: auto;
      left: auto;
      right: auto;
      height: auto;
      border-top: 1px solid color(grey, 17000);
      max-width: $grid-max;
      margin: 0 auto;
      padding: 24px 0;
      background: none;
    }

    .app--has-vertical-scrollbar.app--has-global-backdrop & {
      right: $scrollbar-size;
    }

    &--dark {
      background-color: color(grey, 10000);
      position: static;
      margin-bottom: $grid-base;
    }

    &--spaceless {
      padding: 0;
    }

    &--header {
      height: var(--branding-height);
    }

    &__wrapper {
      align-items: center;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      margin: 0 auto;
      max-width: $grid-max;
    }
    &__logo {
      &:link,
      &:visited,
      &:hover,
      &:active {
        color: color(white);
        text-decoration: none;
      }
    }

    &__logo {
      color: $color-secondary;
      margin-top: 14px;
      @include mq($mq-medium) {
        margin-top: 10px;
      }
    }
  }
</style>

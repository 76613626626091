export const state = () => ({
  loading: 0,
});

export const mutations = {
  START_LOADING(state) {
    state.loading += 1;
  },
  STOP_LOADING(state) {
    if (state.loading > 0) {
      state.loading -= 1;
    }
  },
};

export const getters = {
  isLoaded(state) {
    return state.loading === 0;
  },
  isLoading(_state, getters) {
    return !getters.isLoaded;
  },
};

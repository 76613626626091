import Vue from 'vue';
import {
  formatPrice,
  formatPriceFromCents,
  humanizePrice,
  humanizePriceFromCents,
} from '@i22/rocket';

Vue.filter('toPrice', (value) => formatPrice(value));
Vue.filter('toPriceFromCents', (value) => formatPriceFromCents(value));
Vue.filter('toHumanPrice', (value) => humanizePrice(value));
Vue.filter('toHumanPriceFromCents', (value) => humanizePriceFromCents(value, { hideTrailingZeros: true }));

<template>
  <div
    class="grid grid--gutter-none grid--m-gutter-small grid--l-gutter-default offer-tiles"
    :class="wrapperClass"
  >
    <slot name="beforeOfferTiles" />
    <div
      v-for="(offer, index) in offers"
      :key="index"
      class="grid__item offer-tiles__item"
      :class="size"
    >
      <OfferTileContainer
        :offer="offer"
        :tag-title="tagTitle"
      />
    </div>
    <slot name="afterOfferTiles" />
  </div>
</template>

<script>
import Responsive from '@/mixins/responsive';
import OfferTileContainer from '@/components/offer-tile-container';

export default {
  name: 'OfferTiles',
  components: {
    OfferTileContainer,
  },
  mixins: [
    Responsive,
  ],
  props: {
    offers: {
      type: Array,
      required: true,
    },
    itemOffset: {
      type: String,
      default: null,
    },
    itemSize: {
      default: 'grid__item--6 grid__item--l-3',
      type: String,
    },
    spaces: {
      type: Boolean,
      default: false,
    },
    mobileCentered: {
      type: Boolean,
      default: false,
    },
    isCentered: {
      type: Boolean,
      default: false,
    },
    tagTitle: {
      type: String,
      default: 'h2',
    },
    buttonLabel: {
      type: String,
      default: '<span class="show-from-large">Zu den </span>Details',
    },
    hasRoundedBorder: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    size() {
      return this.itemSize ? this.itemSize : 'grid__item--6 grid__item--l-3';
    },
    wrapperClass() {
      return {
        'offer-tiles--spaces': this.spaces,
        'grid--center': (!this.isMobileScreen && this.isCentered) || (this.mobileCentered && this.isMobileScreen),
      };
    },
  },
};

</script>

<style lang="scss">
  @import 'assets/base';

  .offer-tiles {
    .grid__item {
      overflow: visible;
      padding: 8px;
    }
    &--spaces {
      margin-bottom: 1.5 * $grid-base;
      @include mq($mq-md) {
        margin-bottom: 3 * $grid-base;
      }
    }

    @include mq(false, $mq-sm - 1) {
      padding: 0;
    }
    &__item {
      @include mq($mq-sm) {
        padding-bottom: 1.5 * $grid-base;
      }
      @include mq($mq-md) {
        padding-bottom: 2 * $grid-base;
      }
    }
  }

</style>

<template>
  <div class="multiple-providers-dialog">
    <div class="dialog-content__header">
      Das Produkt kann nicht hinzugefügt werden, da sich bereits ein Produkt von einem anderen Verkäufer im Warenkorb
      befindet.<br>
      Was möchtest du tun?
    </div>

    <div class="dialog-content__buttons multiple-providers-dialog__buttons">
      <LinkButton
        ref="continueLink"
        class="btn btn--transparent multiple-providers-dialog__btn"
        tag="button"
        type="button"
        @click="close"
      >
        Vorherige Auswahl beibehalten
      </LinkButton>
      <LinkButton
        class="btn btn--transparent multiple-providers-dialog__btn"
        tag="button"
        type="button"
        @click="changeAction"
      >
        Neue Auswahl übernehmen
      </LinkButton>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Cookies from 'js-cookie';
import DialogContent from '@/mixins/dialog-content';
import Dialog from '@/helpers/dialog';
import ShopApi from '@/lib/shop-api';
import { LinkButton } from '@i22-td-smarthome/component-library';

export default {
  name: 'MultipleProviderInCartDialogContent',
  components: {
    LinkButton,
  },
  mixins: [
    DialogContent({
      newArticleNumber: null,
    }),
  ],
  computed: {
    ...mapGetters('cart', ['cartItems']),
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs?.continueLink?.$el?.focus();
    });
  },
  methods: {
    ...mapActions('cart', ['addCartItem']),
    async changeAction() {
      try {
        const salesOrganizationID = Cookies.get('TK_VO');

        await ShopApi.clearCart();

        // in case of activation, we have to send data to another url
        await this.addCartItem({
          articleNumber: this.newArticleNumber,
          quantity: 1,
          salesOrganizationID,
        });

        const item = this.cartItems.find((i) => i.articleNumber === this.newArticleNumber);
        await Dialog.show('AddToCartDialogContent', {
          type: 'success',
          componentOptions: { item },
        });
      } catch (e) {
        console.error(e);
        await Dialog.info(
          'Ein kritischer Fehler ist aufgetreten. Bitte versuche es erneut.',
          { type: 'error' },
        );
      }
    },
  },
};

</script>

<style lang="scss" scoped>
@import 'assets/base';

.multiple-providers-dialog {
  &__buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }

  &__btn {
    margin: 6px;
  }
}
</style>

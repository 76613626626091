<template>
  <div
    class="loading-spinner"
    :class="{ 'loading-spinner--active': showSpinner }"
  >
    <!-- @TODO: check if it is necessary to add / remove class on icon. I think there was a rendering issue if
         we do not handle the classes, but we have to validate it
     -->
    <span
      class="icon loading-spinner__icon"
      :class="{ 'loading-spinner__icon--active': showSpinner }"
    >
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('loader', ['isLoading']),
    ...mapGetters('backdrop', { hasActiveBackdrop: 'isActive' }),
    showSpinner() {
      return this.isLoading && !this.hasActiveBackdrop;
    },
  },
};

</script>

<style lang="scss">
  @import '../assets/base';

  @keyframes loading {
    100% {
      transform: rotate(360deg) translateZ(0);
    }
  }

  .loading-spinner {
    background-color: rgba(color(white), .5);
    display: block;
    height: 100%;
    left: 0;
    overflow: hidden;
    position: fixed;
    top: 0;
    transition: opacity .1s ease-in-out;
    width: 100vw;
    z-index: 1;
    opacity: 0;
    pointer-events: none;

    &--active {
      opacity: 1;
      pointer-events: auto;
    }

    &__icon {
      &--active {
        animation: loading 1.25s linear infinite;
      }
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAMAAACfWMssAAAAbFBMVEUAAAB4eHiHh4ekpKSjo6Ojo6OkpKShoaGjo6OhoaGkpKSjo6Ojo6OioqKkpKSjo6Ojo6Ojo6Ojo6Ojo6OkpKSioqKdnZ2jo6OioqKjo6OkpKSjo6OioqKkpKSjo6Ojo6OkpKSkpKSkpKSkpKSDwKVKAAAAI3RSTlMAAwfx7OX5DNcURZh6OW1bJMGnZ+CGILVSsq2Lc2FOuszJLNXgmcMAAAIWSURBVEjHjFLtloIgFAQyEVTyKzXTauP933HnSqbumjE/PJ4Dw3zcy7bA0z7OqyAMgyqP+5QzL4i2O9gVDl0rvtJkEtgNBIncpfHy6DQKo2slhMq0KZz+sdxxfIvGK7EWK+86Hp+Lbp/kriNt4BtHw0i9borKhjwm4kNlCTluNpJmZPOi2EeoC9nN/vGozDPfbe5M9f5hSuiFp8XzZZFHh0N0j8uFiVMIzZVb3oCXvvOYHzsjbOYHUzCbpS3qczrmp8quUSw0qdvF/Cgfc6gfo8zd6EzKpza5tU82g3Le3kYj9MlfZgJqwMhF3HYV6oKYk9kS81Nz/PC8t5cK8yxfjWIrkjl8kLJdJNgu+f4TLl8AHzXbh5h0BG4Pzv8DeuB9wYBbJNRCkE9dY5hfwSFJhXXWxs5DRUPxQGxtBz5a0oxgYEH6EDWmwFmKr6sGe2aYDwS0UtZPK6WQUDIvFNb2ZNhMa3BnfjBUSz5FLDydupA5Q5VudO4FL9TWVgzjd3uKTc88iQr9M2ynKxVVSU+iQI+/ndcxDoAwEAPBL1Dx/6dCN4Jq4+0jRRef7TiIdNBVka5qOAdc73AME+k5CABJACSHJDkiRxK5tUJaK4uMssisA8k6mBWSWbFHNHtkyIiGLAJQIkDoJISOmAuIuW+wAhCsEOUARPmvPABQHqa6shaktZKtJXCtnb3o3q3o7tV6L/P9+/AA0NJJ+bTz65IAAAAASUVORK5CYII=");
      background-position: center;
      background-size: cover;
      display: block;
      height: 49px;
      left: 50%;
      position: absolute;
      top: 50%;
      width: 49px;
    }
  }

</style>

const FooterLinkData = {
  categories: [
    {
      name: 'SmartHome Hilfe',
      links: [
        {
          name: 'Fragen und Antworten',
          // The route property will be passed to a <nuxt-link :to="route">...</nuxt-link>
          // The route property can have a value of a string like '/shop'
          // or a value of a route object like { name: 'my-named-route' } (see Vue-Router's Named Routes)
          route: '/hilfe',
        },
        {
          name: 'Kompatible Geräte',
          route: {
            name: 'hilfe-kompatible-geraete',
          },
        },
      ],
    },
    {
      name: 'Weitere Informationen',
      links: [
        {
          name: 'Über Brodos',
          route: {
            name: 'weitere-informationen-ueber-brodos',
          },
        },
        {
          name: 'Unsere Partner',
          route: {
            name: 'partner',
          },
        },
        {
          name: 'Sitemap',
          route: {
            name: 'weitere-informationen-sitemap',
          },
        },
      ],
    },
  ],
};

export default FooterLinkData;

<template>
  <ul class="footer-linklist">
    <li
      v-for="(link, i) in links"
      :key="i"
      class="footer-linklist__item"
    >
      <div>
        <nuxt-link
          :to="link.route"
          class="footer-linklist__link"
        >
          {{ link.name }}
        </nuxt-link>
      </div>
    </li>
  </ul>
</template>

<script>

export default {
  name: 'FooterLinklist',
  props: {
    links: {
      type: Array,
      default: () => [],
    },
  },
};

</script>

<style lang="scss">
  @import 'assets/base';

  .footer-linklist {
    &__item {
      @include font-size(16px, 28px);
      padding-bottom: 15px;
      list-style: none;
      &--hidden {
        display: none;
      }
    }

    &__link,
    &__link:active,
    &__link:visited {
      text-decoration: none;
      color: $color-secondary;
      &:hover {
        color: $color-secondary;
      }
    }

    &__link:hover,
    &__link:focus {
      text-decoration: underline;
    }
  }

</style>
